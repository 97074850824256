import React, { createContext } from 'react';
import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

// Declare the socket variable outside of the function to retain its state across function calls
let socket = null;

const getSocket = () => {
  // Initialize socket if it hasn't been initialized yet
  if (!socket) socket = io(SOCKET_URL, {
    transports: ['websocket'], // Only use WebSocket, no fallbac
  });
  return socket;
};

// Initialize the socket instance once
const socketInstance = getSocket();

const SocketContext = createContext(socketInstance);

const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={socketInstance}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };