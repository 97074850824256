import React, { useState, useEffect } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { toast } from 'react-toastify';

const allowedMimeTypes = process.env.REACT_APP_ALLOWED_MIME_TYPES.split(',');
const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
const maxLength = 255;

const CustomFileUploader = ({
  filesValue,
  onValueChanged,
  disabled,
  multiple = true,
  selectButtonText = null,
  isRequired = false
}) => {
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const handleValueChanged = (e) => {
    const files = e.value;
    let isValidLocal = true;
    let errorMessageLocal = '';

    for (const file of files) {
      if (!allowedMimeTypes.includes(file.type)) {
        e.value = e?.previousValue ?? null;
        isValidLocal = false;
        errorMessageLocal =
          'Invalid file type. Please upload files of type: ' + allowedMimeTypes.join(', ');
        toast.error(errorMessageLocal);
        break;
      }

      if (file.size > maxFileSize) {
        e.value = e?.previousValue ?? null;
        onValueChanged(e);
        isValidLocal = false;
        errorMessageLocal = 'File size exceeds limit (10 MB).';
        toast.error(errorMessageLocal);
        break;
      }

      if (file.name.length > maxLength) {
        e.value = e?.previousValue ?? null;
        isValidLocal = false;
        errorMessageLocal = 'File name exceeds maximum length (' + maxLength + ' characters).';
        toast.error(errorMessageLocal);
        break;
      }
    }

    setIsValid(isValidLocal);
    setErrorMessage(errorMessageLocal);

    if (isValidLocal) {
      onValueChanged(e); // Only call the original onValueChanged if all files are valid
    }
  };

  useEffect(() => {
    // Check for initial validity (useful when files are pre-populated)
    const initialFiles = filesValue || [];
    let isValidInitial = true;
    let errorMessageInitial = '';

    for (const file of initialFiles) {
      if (!allowedMimeTypes.includes(file.type)) {
        isValidInitial = false;
        errorMessageInitial = 'Invalid file type in initial files.';
        break;
      }

      if (file.size > maxFileSize) {
        isValidInitial = false;
        errorMessageInitial = 'File size exceeds limit (10 MB) in initial files.';
        break;
      }

      if (file.name.length > maxLength) {
        isValidInitial = false;
        errorMessageInitial =
          'File name exceeds maximum length (' + maxLength + ' characters) in initial files.';
        break;
      }
    }

    setIsValid(isValidInitial);
    setErrorMessage(errorMessageInitial);
  }, [filesValue]);

  return (
    <FileUploader
      value={filesValue}
      multiple={multiple}
      readOnly={disabled}
      accept={allowedMimeTypes.join(',')}
      uploadMode={'useButton'}
      onValueChanged={handleValueChanged}
      isValid={isValid}
      validationError={errorMessage}
      selectButtonText={selectButtonText}
    >
      {isRequired ? (
        <Validator>
          <RequiredRule message="" />
        </Validator>
      ) : null}
    </FileUploader>
  );
};

export default CustomFileUploader;
