import React, { useContext, useEffect } from "react"
import { Context } from "utils/context/store/Store";
import useMyTasks from 'utils/hooks/useMyTasks';
import usePagePermissions from "utils/hooks/usePagePermissions";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import { useLocation, useParams } from "react-router-dom";
import { isObjectEmpty, getUserInfo, encryptObject } from "utils/services/Helpers";
import useDataAccess from "utils/hooks/useDataAccess";
import HomeAndMyTaskDataGrid from "views/home/Datatable/HomeAndMyTaskDataGrid";

function MyTasks({ routeKey, pageName }) {

  const { getMyTasks, isLoading } = useMyTasks(routeKey, false)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const { getUserDataAccessByModule } = useDataAccess(routeKey)
  const [{ tasks, dataAccess }, dispatch] = useContext(Context)
  const location = useLocation()
  const { masterModuleId } = useParams()

  useEffect( () => {
    if (location && location.state && !isObjectEmpty(location.state) && Number(masterModuleId) === 1)
    {
      let cb = async () => await getUserDataAccessByModule(getUserInfo()?.id, location.state.masterModuleId)
      cb()
    }
    // TODO: commented for performance testing
    let gmt = async () => await getMyTasks()
    gmt()

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, [])

  const handleBeforeUnload = (event) => {
    // Do something here, such as showing a confirmation message
    // to the user before they leave the page.
    localStorage.removeItem('tasks')
    localStorage.removeItem('moduleId')
    if (localStorage.getItem('moduleId') === null)
    {
      localStorage.setItem("tasks", encryptObject(JSON.stringify(tasks)))
      localStorage.setItem("moduleId", encryptObject(masterModuleId))
    }


  }

  return (
    <BaseLayOut pageTitle={pageName ?? "My Tasks"}>
      <HomeAndMyTaskDataGrid isLoading={isLoading}
        columns={tasks.columns ?? []}
        rows={tasks.rows && tasks.rows.length ? tasks.rows.filter(mt => mt.masterModuleId === Number(masterModuleId)) : []}
        permissions={getPermissionsForPage(routeKey)} allowAdding={false}
        allowDeleting={false} allowUpdating={false} />
    </BaseLayOut>
  );
}

export default MyTasks;
