import React, { useContext, useEffect, useState, memo, Fragment, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import { CheckBox } from 'devextreme-react/check-box';
import { HtmlEditor, Toolbar, Item } from 'devextreme-react/html-editor';
import { Context } from '../../utils/context/store/Store';
import { SET_IS_LOADING } from '../../utils/context/store/Constants';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import useJobs from '../../utils/hooks/useJobs';
import useDataAccess from '../../utils/hooks/useDataAccess';
import useJobDescriptionPage from '../../utils/hooks/useJobDescriptionPage';
import Skelton from '../../components/Skelton/defaultSkelton';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
const AccordionCustom = Loadable({
  loader: () => import('../../components/AccordionCustom'),
  loading: () => <Skelton />
});
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ToPrint from './ToPrint';
import {
  getUserInfo,
  isObjectEmpty,
  handleDeleteRequest,
  getLevels,
  DEButton,
  sanitizeInput
} from '../../utils/services/Helpers';
import {
  DATA_ACCESS,
  JD,
  COMMENTS,
  APPROVE_COLOR,
  REJECT_COLOR,
  ARE_YOU_SURE
} from '../../utils/constant';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'antd/dist/antd.css';
import 'draft-js/dist/Draft.css';
import 'assets/datatable-css/editorstyles.css';
import './styles.scss';

const JobDescription = memo(({ routeKey, pageName }) => {
  const navigate = useNavigate();
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const {
    getJobDescriptionPage,
    getJDByLevel,
    updateOrCreateJobDescriptionPage,
    deleteJobDescriptionPage,
    withdrawJob,
    getJDById,
    clearAllOnUnMount,
    jdStateValues,
    jdPage,
    jdPageLevels,
    jdVersions
  } = useJobDescriptionPage();
  const { getUserDataAccessByModule, dataAccess } = useDataAccess(routeKey);
  const [{ isLoading }, dispatch] = useContext(Context);
  const permissions = getPermissionsForPage(routeKey);
  const { deActivateJD } = useJobs(routeKey);

  const [formReadableValues, setFormReadableValues] = useState({});
  const [formSubmitValues, setFormSubmitValues] = useState({});
  const [jdData, setJdData] = useState([]);
  const [deletedValues, setDeletedValues] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jdSubmit, setJDSubmit] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isDataAccessValid, setIsDataAccessValid] = useState(true);
  const [routePath, setRoutePath] = useState(null);
  const [inActiveJD, setInActiveJD] = useState(null);
  const [canAction, setCanAction] = useState(true);
  const { transactionJDId, notificationId, version } = useParams();

  const hasJdVersionInformation = () => jdVersions?.data && !isObjectEmpty(jdVersions.data);
  const getUserId = () => getUserInfo()?.id;

  const defaultInit = {
    userId: getUserId(),
    id: hasJdVersionInformation() ? jdVersions.data.id : null,
    approvalId: hasJdVersionInformation() ? jdVersions.data.approvalId : null,
    jobName: hasJdVersionInformation() ? jdVersions.data.jobName : '',
    approved: hasJdVersionInformation() ? jdVersions.data.approved : false,
    status: hasJdVersionInformation() ? jdVersions.data.status : false,
    isLive: !!(hasJdVersionInformation() && !!jdVersions.data.isLive),
    transactionJdId: hasJdVersionInformation() ? jdVersions.data.transactionJdId : null,
    approverComments: hasJdVersionInformation() ? jdVersions.data.approverComments : '',
    isArchived: hasJdVersionInformation() ? jdVersions.data.isArchived : 0,
    isDeactive: hasJdVersionInformation() ? jdVersions.data.isDeactive : 0
  };

  const initialReadableValues = {
    ...defaultInit,
    masterLevelId: hasJdVersionInformation() ? jdVersions.data.masterLevelName : '',
    masterOrgDivisionId: hasJdVersionInformation() ? jdVersions.data.masterOrgDivisionName : '',
    masterOrgEntityId: hasJdVersionInformation() ? jdVersions.data.masterOrgEntityName : '',
    masterCountryId: hasJdVersionInformation() ? jdVersions.data.masterCountryName : null,
    masterOrgFunctionId: hasJdVersionInformation() ? jdVersions.data.masterOrgFunctionName : '',
    masterOrgVerticalId: hasJdVersionInformation() ? jdVersions.data.masterOrgVerticalName : ''
  };

  const initialSubmitValues = {
    ...defaultInit,
    masterLevelId: hasJdVersionInformation() ? jdVersions.data.masterLevelId : '',
    masterOrgDivisionId: hasJdVersionInformation() ? jdVersions.data.masterOrgDivisionId : '',
    masterOrgEntityId: hasJdVersionInformation() ? jdVersions.data.masterOrgEntityId : null,
    masterCountryId: hasJdVersionInformation() ? jdVersions.data.masterCountryId : null,
    masterOrgFunctionId: hasJdVersionInformation() ? jdVersions.data.masterOrgFunctionId : '',
    masterOrgVerticalId: hasJdVersionInformation() ? jdVersions.data.masterOrgVerticalId : '',
    restricted: hasJdVersionInformation() ? jdVersions.data.restricted : false,
    updated_by: hasJdVersionInformation() ? jdVersions.data.updated_by : null
  };

  // initial call to get the job description and data access
  useEffect(() => {
    const callback = async () => {
      dispatch({ type: SET_IS_LOADING, payload: true });

      if (dataAccess && isObjectEmpty(dataAccess)) {
        await getUserDataAccessByModule(getUserId(), 1, true);
      }
      if (transactionJDId && version) {
        await getJDById(transactionJDId, true, version, false);
      } else {
        await getJobDescriptionPage(false);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
    };
    callback();

    return () => {
      console.log('-------------unmount---component---job---description----------------');
      clearAllData();
    };
  }, []);

  useEffect(() => {
    if (jdPage.length > 0 || jdStateValues.length > 0 || Object.keys(formSubmitValues).length > 0) {
      setDataFromGenericAPI();
    }
  }, [jdPage, jdStateValues, formSubmitValues]);

  // set initial values for creating or updating JD
  useEffect(() => {
    setFormReadableValues(initialReadableValues);
    setFormSubmitValues(initialSubmitValues);
  }, [jdVersions]);

  useEffect(() => {
    const callback = async () => {
      if (Object.keys(formErrors).length === 0 && isSubmitting) {
        await submit();
      }
    };
    callback();
  }, [formErrors]);

  // when level gets changed then set new form accordions according to it
  useEffect(() => {
    if (formSubmitValues.masterLevelId !== '') {
      setDataFromLevelAPI(formSubmitValues.masterLevelId);
    }
  }, [jdPageLevels]);

  const clearAllData = () => {
    setJdData([]);
    setFormReadableValues({});
    setFormSubmitValues({});
    clearAllOnUnMount();
  };

  // handle form submit
  const submit = async () => {
    if (!formSubmitValues.values || formSubmitValues.id === 0) {
      formSubmitValues.values = jdData;
    }
    formSubmitValues.deletedVal = deletedValues;
    await updateOrCreateJobDescriptionPage(formSubmitValues, jdSubmit, routePath);
  };

  // handle form submit button
  const handleSubmit = useCallback(() => {
    formSubmitValues.updated_by = formSubmitValues.userId !== getUserId() ? getUserId() : null;
    formSubmitValues.values = jdData;
    setRoutePath('/views/manage-jobs');
    setFormErrors(validate(formSubmitValues, 'submit'));
    setIsSubmitting(true);
  }, [formSubmitValues, jdData]);

  // handle save as draft button
  const handleSaveDraft = useCallback(() => {
    setRoutePath('/views/manage-jobs');
    formSubmitValues.updated_by = formSubmitValues.userId !== getUserId() ? getUserId() : null;
    setFormErrors(validate(formSubmitValues, 'draft'));
    setIsSubmitting(true);
  }, [formSubmitValues, jdData]);

  // handle form validation
  const validate = useCallback(
    (values, type) => {
      let errors = {};
      let colorCheck = 0;
      let jobNameValid = true;

      if (!isDataAccessValid && values.id === 0) {
        errors.copyJob =
          'You do not have access to the data in Job Summary. Please re-select data from the dropdown!';
      }

      if (!values.masterOrgDivisionId) {
        errors.masterOrgDivisionId = 'Division cannot be blank';
      }

      if (!values.masterOrgVerticalId) {
        errors.masterOrgVerticalId = 'Vertical cannot be blank';
      }

      if (!values.masterOrgFunctionId) {
        errors.masterOrgFunctionId = 'Function cannot be blank';
      }

      if (!values.masterLevelId) {
        errors.masterLevelId = 'Level cannot be blank';
      }

      if (!values.jobName) {
        errors.jobName = 'Job Name cannot be blank';
      }

      if (!sanitizeInput(values.jobName)) {
        jobNameValid = false;
        errors.jobName = 'Invalid characters detected. Please remove any special characters.';
      }
      if (type === 'submit' || type === 'reject') {
        [...jdData].forEach((seg) => {
          seg.elements.forEach((ele) => {
            if (
              ele.mandatory &&
              (ele.value.value === null || ele.value.value === '' || ele.value.value === undefined)
            ) {
              errors[ele.id] = 'Value cannot be blank';
            }
          });
        });
      }

      if (Object.keys(errors).length !== 0) {
        window.scrollTo(0, 0);
        if (colorCheck === 1) {
          toast.error(
            'Main Tasks weightage bar should be 100% accross. Please divide accordingly!!'
          );
        } else if (!isDataAccessValid && values.id === 0) {
          toast.error(errors.copyJob);
        } else if (!jobNameValid) {
          toast.error(errors.jobName);
        } else {
          toast.error('Please enter data');
        }
      } else {
        setJDSubmit(type);
        setIsSubmitting(true);
      }

      return errors;
    },
    [formSubmitValues, jdData]
  );

  // handle upsert
  const upsert = (array, item, type = null) => {
    let i = null;

    if (type === 'deletedVal') {
      i = array.findIndex((_item) => _item.id === item.id);
    } else {
      i = array.findIndex((_item) => _item.masterJdElementId === item.masterJdElementId);
    }

    if (i > -1) array[i] = item;
    else array.push(item);

    return array;
  };

  // handle copy job button
  const copyJob = () => {
    if (formSubmitValues && !isObjectEmpty(formSubmitValues)) {
      const init = {
        id: 0,
        userId: getUserId(),
        isLive: false,
        isTemp: false,
        status: false,
        approved: false,
        transactionJdId: null,
        values: jdData
      };

      setFormReadableValues((prev) => ({
        ...prev,
        ...init
      }));
      setFormSubmitValues((prev) => ({
        ...prev,
        ...init
      }));
    }
  };

  // handle revise job / edit button clicked
  const reviseJob = () => {
    if (formSubmitValues && !isObjectEmpty(formSubmitValues)) {
      const init = {
        id: 0,
        transactionJdId: formSubmitValues.id,
        status: false,
        approved: false,
        isTemp: false,
        isLive: false,
        values: jdData,
        version: jdVersions.data.version + 1
      };
      setFormReadableValues((prev) => ({
        ...prev,
        ...init
      }));
      setFormSubmitValues((prev) => ({
        ...prev,
        ...init
      }));
    }
  };

  // get entities by division
  const getEntitiesByDivision = () => {
    const entities = [];
    dataAccess?.masterOrgDivisionId?.length && dataAccess?.masterOrgEntityId?.length
      ? dataAccess?.masterOrgEntityId?.map((entity) => {
        if (formSubmitValues.masterOrgDivisionId === entity.masterOrgDivisionId)
          entities.push(entity);
      })
      : [];

    return entities.length > 0
      ? entities.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
      : [];
  };

  // get country by entities or division
  const getCountryByEntitiesOrDivision = () => {
    let countries = [];
    const jdCountries = dataAccess?.masterCountryId?.length ? dataAccess.masterCountryId : [];
    if (formSubmitValues.masterOrgEntityId) {
      const filteredCountry =
        jdCountries.filter((b) => b.masterOrgEntityId === formSubmitValues.masterOrgEntityId) ?? [];
      countries = [...new Map(filteredCountry.map((item) => [item.id, item])).values()];
    } else {
      const filteredCountry =
        jdCountries.filter((b) => b.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId) ??
        [];
      countries = [...new Map(filteredCountry.map((item) => [item.id, item])).values()];
    }

    return countries.length > 0
      ? countries.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
      : [];
  };

  // get verticals by entity or country
  const getVerticalsByEntityOrCountry = () => {
    let verticals = [];
    let filteredVerticals = dataAccess?.masterOrgVerticalId?.length
      ? dataAccess.masterOrgVerticalId
      : [];
    if (formSubmitValues.masterCountryId !== null) {
      filteredVerticals =
        formSubmitValues.masterOrgEntityId !== null
          ? filteredVerticals.filter(
            (fv) =>
              fv.masterCountryId === formSubmitValues.masterCountryId &&
              fv.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId &&
              fv.masterOrgEntityId === formSubmitValues.masterOrgEntityId
          )
          : filteredVerticals.filter(
            (fv) =>
              fv.masterCountryId === formSubmitValues.masterCountryId &&
              fv.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId
          );
      verticals = [
        ...new Map(filteredVerticals.map((item) => [item['masterOrgVerticalId'], item])).values()
      ];
    } else if (formSubmitValues.masterOrgEntityId !== null) {
      filteredVerticals = filteredVerticals.filter(
        (obj) =>
          obj.masterOrgEntityId === formSubmitValues.masterOrgEntityId &&
          obj.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId
      );
      verticals = [
        ...new Map(filteredVerticals.map((item) => [item['masterOrgVerticalId'], item])).values()
      ];
    } else {
      filteredVerticals = filteredVerticals.filter(
        (fv) => fv.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId
      );
      verticals = [
        ...new Map(filteredVerticals.map((item) => [item['masterOrgVerticalId'], item])).values()
      ];
    }
    return verticals.length > 0
      ? verticals.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
      : [];
  };

  // get capabilites by verticals
  const getCapabilitesByVerticals = () => {
    let func = [];
    if (
      formSubmitValues.masterOrgDivisionId &&
      formSubmitValues.masterOrgEntityId &&
      formSubmitValues.masterOrgVerticalId
    ) {
      const filteredCap = dataAccess?.masterOrgFunctionId?.length
        ? dataAccess.masterOrgFunctionId.filter(
          (cap) =>
            cap.masterOrgVerticalId === formSubmitValues.masterOrgVerticalId &&
            cap.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId &&
            cap.masterOrgEntityId === formSubmitValues.masterOrgEntityId
        )
        : [];
      func = [...new Map(filteredCap.map((item) => [item['masterOrgFunctionId'], item])).values()];
    } else if (formSubmitValues.masterOrgVerticalId) {
      const filteredCap = dataAccess?.masterOrgFunctionId?.length
        ? dataAccess.masterOrgFunctionId.filter(
          (cap) =>
            cap.masterOrgVerticalId === formSubmitValues.masterOrgVerticalId &&
            cap.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId
        )
        : [];
      func = [...new Map(filteredCap.map((item) => [item['masterOrgFunctionId'], item])).values()];
    }

    return func.length > 0
      ? func.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
      : [];
  };

  // set data from generic API
  const setDataFromGenericAPI = () => {
    const jdGenericData = hasJdVersionInformation() ? jdStateValues : jdPage;
    jdGenericData.forEach((segment) => {
      segment.elements.forEach((element) => {
        if (element.elementScore === undefined) {
          element.elementScore = 0;
        }
        if (element.value === null) {
          element.value = {
            readOnly: false,
            value: ''
          };
        }
      });
    });
    setJdData(jdGenericData);
  };

  // handle insertAt
  const insertAt = (array, index, levId, ...elementsArray) => {
    let flag = 0;

    if (array && array.length > 0) {
      array.forEach((oldseg) => {
        elementsArray.forEach((newseg) => {
          if (oldseg.id === newseg.id) {
            oldseg.elements.forEach((oldele, oldelei) => {
              newseg.elements.forEach((newele) => {
                if (oldele.id === newele.id) {
                  if (newele.masterLevelId === levId) {
                    oldele.masterLevelId = newele.masterLevelId;
                    oldele.value.readOnly = newele.value.readOnly;
                    oldele.value.value = newele.value.value;
                  }
                }
                flag = 1;
              });
            });
          }
        });
      });

      if (flag !== 1) {
        array.splice(index, 0, ...elementsArray);
      }

      return array;
    }
  };

  // set data from level API
  const setDataFromLevelAPI = (levId) => {
    const jdLevelData = [...jdData] ?? [];

    if (jdPageLevels && jdPageLevels.length > 0) {
      jdPageLevels.forEach((segment) => {
        segment.elements.forEach((element) => {
          element.elementScore = 0;
          element.masterLevelId = levId;

          // TODO remove once confirmed the impact
          // if (formSubmitValues.isTemp) {
          //   let oldData =
          //     formSubmitValues && formSubmitValues.values ? [...formSubmitValues.values] : [];
          //   oldData.forEach((oldseg) => {
          //     oldseg.elements.forEach((oldele) => {
          //       if (element.id === oldele.id) {
          //         element.elementScore = oldele.elementScore;
          //       }
          //     });
          //   });
          // }

          const data = jdLevelData && jdLevelData.length > 0 ? [...jdLevelData] : [];
          if (element.value !== null) {
            insertAt(data, segment.sequence - 1, levId, segment);
          }
        });
      });

      setJdData(jdLevelData);
    } else {
      setJdData(jdLevelData);
    }
  };

  // handle job delete
  const manageDelete = () => {
    if (isDataAccessValid) {
      handleDeleteRequest(async () => await deleteJobDescriptionPage(jdVersions.data.id));
    } else {
      toast.error(DATA_ACCESS.JD.CANNOT_DELETE_DUE_TO_DATA_ACCESS);
    }
  };

  // handle job withdraw button
  const manageWithdraw = () => {
    if (isDataAccessValid) {
      handleDeleteRequest(
        async () => await withdrawJob(jdVersions.data.id),
        '',
        JD.WANT_WITHDRAW_JD,
        JD.WITHDRAW_JD
      );
    } else {
      toast.error(DATA_ACCESS.JD.CANNOT_WITHDRAW_DUE_TO_DATA_ACCESS);
    }
  };

  // handle job approve button
  const handleApprove = useCallback(() => {
    const errors = validate(formSubmitValues, 'approve');
    setIsSubmitting(false);
    setFormErrors(errors);
    if (isObjectEmpty(errors)) {
      Swal.fire({
        title: JD.APPROVE,
        text: COMMENTS,
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Approve',
        confirmButtonColor: APPROVE_COLOR,
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let jdApproved = {};
          jdApproved = {
            ...formSubmitValues,
            approverComments: result.value,
            updatedBy: isEditable ? getUserId() : null
          };
          if (!formSubmitValues.isDeactive) {
            jdApproved = {
              ...jdApproved,
              approved: true
            };
          }
          setFormSubmitValues(jdApproved);
          setRoutePath('/views/manage-jobs');
          setFormErrors(validate(formSubmitValues, 'approve'));
          setIsSubmitting(true);
        }
      });
    }
  },[formSubmitValues, jdData]);

  // handle job reject button
  const handleReject = useCallback(() => {
    const errors = validate(formSubmitValues, 'reject');
    setIsSubmitting(false);
    setFormErrors(errors);
    if (isObjectEmpty(errors)) {
      Swal.fire({
        title: JD.REJECT,
        text: COMMENTS,
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Reject',
        confirmButtonColor: 'red',
        inputValidator: (value) => {
          return !value && COMMENTS;
        },
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      }).then((result) => {
        let jdRejected = {};
        if (result.isConfirmed) {
          jdRejected = {
            ...formSubmitValues,
            rejected: true,
            approverComments: result.value,
            updatedBy: isEditable ? getUserId() : null
          };
          if (!formSubmitValues.isDeactive) {
            jdRejected = {
              ...jdRejected,
              status: false,
              approved: false
            };
          }
          setFormSubmitValues(jdRejected);
          setRoutePath('/views/manage-jobs');
          setFormErrors(validate(formSubmitValues, 'reject'));
          setIsSubmitting(true);
        }
      });
    }
  }, [formSubmitValues, jdData]);

  // handler for copy or edit JD
  const handleOnCopyOrEdit = (type) => {
    switch (type) {
      case 'copy':
        copyJob();
        navigate('/views/job-description');
        break;
      case 'edit':
        reviseJob();
        navigate('/views/job-description');
        break;
      default:
        break;
    }
  };

  // if user is allowed to draft jd then show button
  const canSaveAsDraft = () => {
    return !!(
      canCreateJd() ||
      (!!!formSubmitValues.status && !formSubmitValues.approved && !formSubmitValues.isArchived)
    );
  };

  // if user is allowed to create jd then show button
  const canCreateJd = () => {
    return !!(jdData?.length > 0 && permissions?.canCreate && !hasJdVersionInformation());
  };

  // if user is allowed to withdraw jd then show button
  const canWithdrawJd = () => {
    return !!(
      formSubmitValues &&
      !!formSubmitValues.status &&
      !formSubmitValues.approved &&
      !formSubmitValues.isArchived &&
      formSubmitValues.id !== 0 &&
      formSubmitValues.userId === getUserId()
    );
  };

  // if user is allowed to copy jd then show button
  const canCopyJd = () => {
    return !!(
      formSubmitValues &&
      jdData.length > 0 &&
      (permissions?.canCreate || permissions?.canView) &&
      formSubmitValues.approved &&
      !formSubmitValues.isArchived &&
      !formSubmitValues.isDeactive
    );
  };

  // if user is allowed to export jd then show button
  const canExportFile = () => {
    return canCopyJd();
  };

  // if user is allowed to edit jd then show button
  const canEditJd = () => {
    return !!(canCopyJd() && !dataAccess?.limitedDataAccess);
  };

  // if user is allowed to submit jd then show button
  const canSubmitJd = () => {
    return !!(
      canCreateJd() ||
      (!formSubmitValues.status && !formSubmitValues.approved && !formSubmitValues.isArchived)
    );
  };

  // if user is allowed to delete jd then show button
  const canDeleteJd = () => {
    return !!(canSubmitJd() && formSubmitValues.id !== 0 && formSubmitValues.id !== null);
  };

  // if user is allowed to edit jd then show button
  const canEditTextFields = (element) => {
    return !!(
      element.value &&
      !element.value.readOnly &&
      permissions &&
      permissions.canApprove &&
      !!formSubmitValues.status &&
      !formSubmitValues.approved &&
      jdVersions?.data.approvalId &&
      jdVersions?.data.approver === jdVersions?.data.workflowAccessId &&
      jdVersions?.data.can_approve
    );
  };

  // if user is allowed to approve or reject jd then show button
  const canApproveOrRejectJd = () => {
    return !!(
      jdData.length > 0 &&
      permissions &&
      permissions.canApprove &&
      !!formReadableValues?.status &&
      (!formSubmitValues?.approved || formSubmitValues?.restricted) &&
      jdVersions?.data?.approvalId &&
      jdVersions?.data?.approver === jdVersions?.data?.workflowAccessId
    );
  };

  const handlerForDropdownValueChange = useCallback(
    async (e, type) => {
      switch (type) {
        case 'division':
          if (e.value !== null) {
            const filteredObject = dataAccess.masterOrgDivisionId.filter(
              (val) => val.id === e.value
            );
            if (filteredObject.length > 0) {
              setFormSubmitValues((prev) => ({
                ...prev,
                masterOrgDivisionId: filteredObject[0].id,
                masterOrgEntityId: null,
                masterCountryId: null,
                masterOrgVerticalId: '',
                masterOrgFunctionId: '',
                masterLevelId: ''
              }));
              setFormReadableValues((prev) => ({
                ...prev,
                masterOrgDivisionId: filteredObject[0].label,
                masterOrgEntityId: null,
                masterCountryId: null,
                masterOrgVerticalId: '',
                masterOrgFunctionId: '',
                masterLevelId: ''
              }));
            }
          } else if (e.event !== undefined) {
            setFormSubmitValues((prev) => ({
              ...prev,
              masterOrgDivisionId: '',
              masterOrgEntityId: null,
              masterCountryId: null,
              masterOrgVerticalId: '',
              masterOrgFunctionId: '',
              masterLevelId: ''
            }));
            setFormReadableValues((prev) => ({
              ...prev,
              masterOrgDivisionId: '',
              masterOrgEntityId: null,
              masterCountryId: null,
              masterOrgVerticalId: '',
              masterOrgFunctionId: '',
              masterLevelId: ''
            }));
          }
          break;
        case 'entity':
          if (e.value !== null) {
            const filteredObject = getEntitiesByDivision().filter((val) => val.id === e.value);
            if (filteredObject.length > 0) {
              setFormSubmitValues((prev) => ({
                ...prev,
                masterOrgEntityId: filteredObject[0].id,
                masterCountryId: null,
                masterOrgVerticalId: '',
                masterOrgFunctionId: '',
                masterLevelId: ''
              }));
              setFormReadableValues((prev) => ({
                ...prev,
                masterOrgEntityId: filteredObject[0].label,
                masterCountryId: null,
                masterOrgVerticalId: '',
                masterOrgFunctionId: '',
                masterLevelId: ''
              }));
            }
          } else if (e.event !== undefined) {
            setFormSubmitValues((prev) => ({
              ...prev,
              masterOrgEntityId: null,
              masterCountryId: null,
              masterOrgVerticalId: '',
              masterOrgFunctionId: '',
              masterLevelId: ''
            }));
            setFormReadableValues((prev) => ({
              ...prev,
              masterOrgEntityId: null,
              masterCountryId: null,
              masterOrgVerticalId: '',
              masterOrgFunctionId: '',
              masterLevelId: ''
            }));
          }
          break;
        case 'country':
          if (e.value !== null) {
            const filteredObject = getCountryByEntitiesOrDivision().filter(
              (val) => val.id === e.value
            );
            if (filteredObject.length > 0) {
              setFormSubmitValues((prev) => ({
                ...prev,
                masterCountryId: filteredObject[0].id,
                masterOrgVerticalId: '',
                masterOrgFunctionId: '',
                masterLevelId: ''
              }));
              setFormReadableValues((prev) => ({
                ...prev,
                masterCountryId: filteredObject[0].label,
                masterOrgVerticalId: '',
                masterOrgFunctionId: '',
                masterLevelId: ''
              }));
            }
          } else if (e.event !== undefined) {
            setFormSubmitValues((prev) => ({
              ...prev,
              masterCountryId: null,
              masterOrgVerticalId: '',
              masterOrgFunctionId: '',
              masterLevelId: ''
            }));
            setFormReadableValues((prev) => ({
              ...prev,
              masterCountryId: null,
              masterOrgVerticalId: '',
              masterOrgFunctionId: '',
              masterLevelId: ''
            }));
          }
          break;
        case 'vertical':
          if (e.value !== null) {
            const filteredObject = getVerticalsByEntityOrCountry().filter(
              (val) => val.id === e.value
            );
            if (filteredObject.length > 0) {
              setFormSubmitValues((prev) => ({
                ...prev,
                masterOrgVerticalId: filteredObject[0].id,
                masterOrgFunctionId: '',
                masterLevelId: ''
              }));
              setFormReadableValues((prev) => ({
                ...prev,
                masterOrgVerticalId: filteredObject[0].label,
                masterOrgFunctionId: '',
                masterLevelId: ''
              }));
            }
          } else if (e.event !== undefined) {
            setFormSubmitValues((prev) => ({
              ...prev,
              masterOrgVerticalId: '',
              masterOrgFunctionId: '',
              masterLevelId: ''
            }));
            setFormReadableValues((prev) => ({
              ...prev,
              masterOrgVerticalId: '',
              masterOrgFunctionId: '',
              masterLevelId: ''
            }));
          }
          break;
        case 'function':
          if (e.value !== null) {
            const filteredObject = getCapabilitesByVerticals().filter((val) => val.id === e.value);
            if (filteredObject.length > 0) {
              setFormSubmitValues((prev) => ({
                ...prev,
                masterOrgFunctionId: filteredObject[0].id
              }));
              setFormReadableValues((prev) => ({
                ...prev,
                masterOrgFunctionId: filteredObject[0].label
              }));
            }
          } else if (e.event !== undefined) {
            setFormSubmitValues((prev) => ({
              ...prev,
              masterOrgFunctionId: ''
            }));
            setFormReadableValues((prev) => ({ ...prev, masterOrgFunctionId: '' }));
          }
          break;
        case 'level':
          if (e.value !== null) {
            const filteredObject = dataAccess.masterLevelId.filter((val) => val.id === e.value);
            if (filteredObject.length > 0) {
              setFormSubmitValues((prev) => ({
                ...prev,
                masterLevelId: filteredObject[0].id
              }));
              setFormReadableValues((prev) => ({
                ...prev,
                masterLevelId: filteredObject[0].label
              }));
              await getJDByLevel(filteredObject[0].id);
            }
          } else if (e.event !== undefined) {
            setFormSubmitValues((prev) => ({ ...prev, masterLevelId: '' }));
            setFormReadableValues((prev) => ({ ...prev, masterLevelId: '' }));
          }
          break;
        default:
          break;
      }
    },
    [formReadableValues, formSubmitValues, dataAccess]
  );

  const handleCheckboxActivate = useCallback(
    (e) => {
      Swal.fire({
        title: ARE_YOU_SURE,
        text: JD.DELETE_EXISTING_DRAFT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: APPROVE_COLOR,
        cancelButtonColor: REJECT_COLOR,
        confirmButtonText: JD.CONFIRM_DEACTIVATE_JD,
        heightAuto: false,
        height: '200px',
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          deActivateJD(jdVersions.data.id);
          // setInActiveJD(false);
          setFormSubmitValues((prev) => ({ ...prev, status: e.value }));
          setFormReadableValues((prev) => ({ ...prev, status: e.value }));
        }
      });
    },
    [formSubmitValues, formReadableValues]
  );

  const handleJobDescriptionTitle = useCallback(
    (e) => {
      setFormSubmitValues((prev) => ({
        ...prev,
        jobName: e
      }));
      setFormReadableValues((prev) => ({ ...prev, jobName: e }));
    },
    [formSubmitValues, formReadableValues]
  );

  // TODO remove console logs
  // console.log('jdData:: ', jdData);
  if (isLoading) {
    return null;
  }
  return (
    <div className="__body__section __job__description__ __jd__container__">
      <div className="__heading__section__">
        <h3>{formSubmitValues.jobName !== '' ? formSubmitValues.jobName : `Create Job`}</h3>
      </div>
      <div className="__job__content__section__">
        <ValidationGroup>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
            name="job__description"
          >
            <div className="__form__container__">
              <div className="__form__active__jd__section">
                {!!(
                  permissions &&
                  permissions.canCreate &&
                  !!formSubmitValues.status &&
                  formSubmitValues.approved &&
                  !formSubmitValues.isArchived &&
                  !formSubmitValues.isDeactive &&
                  !formSubmitValues.restricted &&
                  isDataAccessValid
                ) && (
                  <CheckBox
                    iconSize="15"
                    className="__font__family"
                    style={{ float: 'right' }}
                    disabled={!!formSubmitValues.status === false}
                    checked={inActiveJD ?? !!formSubmitValues.status}
                    value={inActiveJD ?? !!formSubmitValues.status}
                    text="Active"
                    onValueChanged={handleCheckboxActivate}
                  />
                )}
              </div>
              <div className="__form__boxes__section__">
                <div className="__form__select__boxes__">
                  <SelectBox
                    id="division"
                    placeholder={'Please Select Division...*'}
                    dataSource={
                      dataAccess?.masterOrgDivisionId?.length
                        ? dataAccess.masterOrgDivisionId.sort((a, b) =>
                          a.value.toLowerCase().localeCompare(b.value.toLowerCase())
                        )
                        : []
                    }
                    value={
                      formReadableValues.masterOrgDivisionId
                        ? formSubmitValues.masterOrgDivisionId
                        : null
                    }
                    searchEnabled={true}
                    showClearButton={true}
                    valueExpr="id"
                    displayExpr="label"
                    onValueChanged={(e) => handlerForDropdownValueChange(e, 'division')}
                    disabled={!!(!!formSubmitValues.status || formSubmitValues.transactionJdId)}
                    className={`${formErrors.masterOrgDivisionId && 'input-error'} __select__box__`}
                  >
                    <Validator>
                      <RequiredRule message="" />
                    </Validator>
                  </SelectBox>
                  {formErrors.masterOrgDivisionId && (
                    <span className="error">{formErrors.masterOrgDivisionId}</span>
                  )}
                </div>
                <div className="__form__select__boxes__">
                  <SelectBox
                    id="entity"
                    placeholder={'Please Select Entity...'}
                    dataSource={
                      formSubmitValues.masterOrgDivisionId !== '' ||
                      formSubmitValues.masterOrgDivisionId !== null
                        ? getEntitiesByDivision()
                        : []
                    }
                    value={
                      formReadableValues.masterOrgEntityId ? formSubmitValues.masterOrgEntityId : ''
                    }
                    searchEnabled={true}
                    showClearButton={true}
                    valueExpr="id"
                    displayExpr="label"
                    onValueChanged={(e) => handlerForDropdownValueChange(e, 'entity')}
                    disabled={
                      !!(
                        !!formSubmitValues.status ||
                        formSubmitValues.masterOrgDivisionId === '' ||
                        formSubmitValues.transactionJdId
                      )
                    }
                    className={`${formErrors.masterOrgEntityId && 'input-error'} __select__box__`}
                  />
                  {formErrors.masterOrgEntityId && (
                    <span className="error">{formErrors.masterOrgEntityId}</span>
                  )}
                </div>
                <div className="__form__select__boxes__">
                  <SelectBox
                    id="country"
                    placeholder={'Please Select Country...'}
                    dataSource={
                      formSubmitValues.masterOrgEntityId !== null ||
                      formSubmitValues.masterOrgEntityId !== '' ||
                      formSubmitValues.masterOrgDivisionId !== null ||
                      formSubmitValues.masterOrgDivisionId !== ''
                        ? getCountryByEntitiesOrDivision()
                        : []
                    }
                    value={
                      formReadableValues.masterCountryId ? formSubmitValues.masterCountryId : ''
                    }
                    searchEnabled={true}
                    showClearButton={true}
                    valueExpr="id"
                    displayExpr="label"
                    onValueChanged={(e) => handlerForDropdownValueChange(e, 'country')}
                    disabled={
                      !!(
                        !!formSubmitValues.status ||
                        (formSubmitValues.masterOrgEntityId === null &&
                          formSubmitValues.masterOrgDivisionId === '') ||
                        formSubmitValues.transactionJdId
                      )
                    }
                    className={`${formErrors.masterCountryId && 'input-error'} __select__box__`}
                  />
                  {formErrors.masterCountryId && (
                    <span className="error">{formErrors.masterCountryId}</span>
                  )}
                </div>
                <div className="__form__select__boxes__">
                  <SelectBox
                    id="vertical"
                    placeholder={'Please Select Vertical...*'}
                    dataSource={
                      formSubmitValues.masterOrgDivisionId !== '' ||
                      formSubmitValues.masterOrgDivisionId !== null
                        ? getVerticalsByEntityOrCountry()
                        : []
                    }
                    value={
                      formReadableValues.masterOrgVerticalId
                        ? formSubmitValues.masterOrgVerticalId
                        : ''
                    }
                    searchEnabled={true}
                    showClearButton={true}
                    valueExpr="id"
                    displayExpr="label"
                    onValueChanged={(e) => handlerForDropdownValueChange(e, 'vertical')}
                    disabled={
                      !!(
                        !!formSubmitValues.status ||
                        formSubmitValues.masterOrgDivisionId === '' ||
                        formSubmitValues.transactionJdId
                      )
                    }
                    className={`${formErrors.masterOrgVerticalId && 'input-error'} __select__box__`}
                  >
                    <Validator>
                      <RequiredRule message="" />
                    </Validator>
                  </SelectBox>
                  {formErrors.masterOrgVerticalId && (
                    <span className="error">{formErrors.masterOrgVerticalId}</span>
                  )}
                </div>
                <div className="__form__select__boxes__">
                  <SelectBox
                    id="function"
                    placeholder={'Please Select Function...*'}
                    dataSource={
                      formSubmitValues.masterOrgVerticalId !== '' ||
                      formSubmitValues.masterOrgVerticalId !== null
                        ? getCapabilitesByVerticals()
                        : []
                    }
                    value={
                      formReadableValues.masterOrgFunctionId
                        ? formSubmitValues.masterOrgFunctionId
                        : ''
                    }
                    searchEnabled={true}
                    showClearButton={true}
                    valueExpr="id"
                    displayExpr="label"
                    onValueChanged={(e) => handlerForDropdownValueChange(e, 'function')}
                    disabled={
                      !!(
                        (formSubmitValues.masterOrgVerticalId === '' &&
                          getCapabilitesByVerticals() &&
                          getCapabilitesByVerticals().length === 0) ||
                        !!formSubmitValues.status ||
                        formSubmitValues.transactionJdId
                      )
                    }
                    className={`${formErrors.masterOrgFunctionId && 'input-error'} __select__box__`}
                  >
                    <Validator>
                      <RequiredRule message="" />
                    </Validator>
                  </SelectBox>
                  {formErrors.masterOrgFunctionId && (
                    <span className="error">{formErrors.masterOrgFunctionId}</span>
                  )}
                </div>
                <div className="__form__select__boxes__">
                  <SelectBox
                    id="level"
                    placeholder={'Please Select Level...*'}
                    dataSource={getLevels(
                      dataAccess?.masterLevelId ?? [],
                      dataAccess?.masterOrgVerticalId ?? [],
                      formSubmitValues.masterOrgDivisionId !== ''
                        ? formSubmitValues.masterOrgDivisionId
                        : null,
                      formSubmitValues.masterOrgEntityId !== ''
                        ? formSubmitValues.masterOrgEntityId
                        : null,
                      formSubmitValues.masterCountryId !== ''
                        ? formSubmitValues.masterCountryId
                        : null,
                      formSubmitValues.masterOrgVerticalId !== ''
                        ? formSubmitValues.masterOrgVerticalId
                        : null,
                      [],
                      dataAccess.hasOwnProperty('LIds') ? dataAccess.LIds : []
                    )}
                    value={formReadableValues.masterLevelId ? formSubmitValues.masterLevelId : ''}
                    searchEnabled={true}
                    showClearButton={true}
                    valueExpr="id"
                    displayExpr="label"
                    onValueChanged={(e) => handlerForDropdownValueChange(e, 'level')}
                    disabled={
                      !!(
                        formSubmitValues.masterOrgDivisionId === null ||
                        (formSubmitValues.masterOrgDivisionId === '' &&
                          formSubmitValues.masterOrgFunctionId === null) ||
                        formSubmitValues.masterOrgFunctionId === '' ||
                        !!formSubmitValues.status ||
                        formSubmitValues.transactionJdId
                      )
                    }
                    className={`${formErrors.masterLevelId && 'input-error'} __select__box__`}
                  >
                    <Validator>
                      <RequiredRule message="" />
                    </Validator>
                  </SelectBox>
                  {formErrors.masterLevelId && (
                    <span className="error">{formErrors.masterLevelId}</span>
                  )}
                </div>
                <div className="__form__select__boxes__">
                  {/* <FormField */}
                  <TextBox
                    id="JobName"
                    showClearButton={true}
                    placeholder="Please Enter Job Name...*"
                    value={formReadableValues.jobName ? formReadableValues.jobName : ''}
                    disabled={!!formSubmitValues.status}
                    valueChangeEvent="input"
                    onValueChange={handleJobDescriptionTitle}
                    className={`custom-text-field ${formErrors.jobName ? ' input-error' : ''} ${
                      !!formSubmitValues.status ? ' disabled-input' : ''
                    } __input__box__`}
                  >
                    <Validator>
                      <RequiredRule message="" />
                    </Validator>
                  </TextBox>
                  {formErrors.jobName && <span className="error">{formErrors.jobName}</span>}
                </div>
              </div>
              <div className="__form__accordion__section">
                {jdData && jdData.length > 0 ? (
                  jdData.map((segment, index) => {
                    const requiredTitle = segment?.elements?.some(
                      (s) => s.summary && s.mandatory
                    ) ? (
                      <span style={{ color: 'var(--white-color)' }}>*</span>
                    ) : (
                      ''
                    );

                    return (
                      <AccordionCustom
                        title={
                          <>
                            {segment.name} {requiredTitle}
                          </>
                        }
                        uniqueId={`__job__description__form__sections__${index}`}
                        className="__custom__accordion"
                        fontSize="h5"
                        key={`__job__description__form__sections__${index}`}
                        isOpened={true}
                      >
                        <div className="__job__description__accordion__content__">
                          {segment.elements.map((element, index) => {
                            return (
                              <div
                                key={`__segments__container__${index}`}
                                className="__segments__container__"
                              >
                                <div className="__segment__btn__">
                                  {canEditTextFields(element) && !isEditable && (
                                    <DEButton
                                      hint="Edit"
                                      icon="edit"
                                      className="__default__button__layout __unset__border"
                                      stylingMode="contained"
                                      type="default"
                                      onClick={() => {
                                        setIsEditable((editable) => !editable);
                                      }}
                                      disabled={isEditable}
                                    />
                                  )}
                                </div>
                                {!element.summary && (
                                  <div className="__segment__summary__">
                                    <Fragment>
                                      <h5 style={{ color: element.color }}>
                                        {element.name}{' '}
                                        {element.mandatory ? (
                                          <span style={{ color: 'red' }}>*</span>
                                        ) : (
                                          ''
                                        )}{' '}
                                        <br />
                                      </h5>
                                      {element.description !== '' &&
                                        element.description !== null && (
                                          <h5>
                                            <i>{element.description}</i>
                                          </h5>
                                        )}
                                    </Fragment>
                                  </div>
                                )}
                                <div
                                  className={`__segment__status__ __font__family__regular 
                                    ${
                                    (!!!formSubmitValues.status && !element?.value?.readOnly) ||
                                    (isEditable && !element?.value?.readOnly)
                                      ? `__white__background__color`
                                      : ``
                                  }`}
                                >
                                  {!!formSubmitValues.status && !isEditable ? (
                                    <h5 dangerouslySetInnerHTML={{ __html: element.value.value }} />
                                  ) : element.value && element.value.readOnly ? (
                                    <h5 dangerouslySetInnerHTML={{ __html: element.value.value }} />
                                  ) : (
                                    <HtmlEditor
                                      key={`html-editor-${element.id}`}
                                      valueType="html"
                                      height={300}
                                      value={element.value.value}
                                      onValueChanged={(contentState) => {
                                        if (contentState.event) {
                                          const object = jdData.map((seg) => {
                                            if (seg.id === segment.id) {
                                              seg.elements.map((ele) => {
                                                if (ele.id === element.id) {
                                                  ele.value.value = contentState.value;
                                                }
                                              });
                                            }
                                            return seg;
                                          });
                                          setJdData(object);
                                        }
                                      }}
                                    >
                                      <Toolbar multiline={true}>
                                        <Item name="bold" />
                                        <Item name="italic" />
                                        <Item name="underline" />
                                        <Item name="separator" />
                                        <Item name="alignLeft" />
                                        <Item name="alignCenter" />
                                        <Item name="alignRight" />
                                        <Item name="alignJustify" />
                                        <Item name="separator" />
                                        <Item name="orderedList" />
                                        <Item name="bulletList" />
                                      </Toolbar>
                                    </HtmlEditor>
                                  )}
                                </div>
                                {formErrors[element.id] && (
                                  <span className="error">{formErrors[element.id]}</span>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </AccordionCustom>
                    );
                  })
                ) : (
                  <Skelton />
                )}
              </div>
              {/* // final action button section */}
              <div className="__form__action__section__">
                <div className="__form__versions__section__">
                  {jdVersions?.jdDropdownData?.length > 0 && (
                    <SelectBox
                      placeholder={'Select JD version'}
                      dataSource={jdVersions.jdDropdownData}
                      searchEnabled={true}
                      showClearButton={true}
                      displayExpr="label"
                      onValueChanged={(e) => {
                        if (e.value !== null) {
                          const { id, version, status, isLive, task_code } = e.value;
                          localStorage.setItem(`${id} - ${version}`, !!(status && isLive));
                          const currentHost =
                            window.location.origin + `/views/job/${task_code}/version/${version}`;
                          window.open(currentHost);
                        }
                      }}
                      className={`__select__box__`}
                    />
                  )}
                </div>
                <div className="__form__buttons__section__">
                  {canExportFile() && (
                    <PDFDownloadLink
                      document={
                        <ToPrint
                          jobName={formSubmitValues.jobName}
                          jdData={jdData}
                          division_name={formReadableValues.masterOrgDivisionId}
                        />
                      }
                      fileName={formSubmitValues.jobName}
                    >
                      {({ loading }) => (
                        <DEButton
                          text="Export to PDF"
                          className="__default__button__layout __unset__border"
                          disabled={loading}
                          stylingMode="contained"
                          type="normal"
                          style={{ marginRight: '10px' }}
                        />
                      )}
                    </PDFDownloadLink>
                  )}
                  {canSaveAsDraft() && (
                    <DEButton
                      text="Save As Draft"
                      className="__default__button__layout"
                      onClick={handleSaveDraft}
                      useSubmitBehavior={false}
                      stylingMode="contained"
                      type="default"
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  {canWithdrawJd() && (
                    <DEButton
                      text="Withdraw JD"
                      className="__default__button__layout __btn__red"
                      disabled={isLoading}
                      stylingMode="contained"
                      type="danger"
                      onClick={manageWithdraw}
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  {canCopyJd() && (
                    <DEButton
                      text="Copy Job"
                      className="__default__button__layout"
                      stylingMode="contained"
                      type="default"
                      handleOnClick={() => handleOnCopyOrEdit('copy')}
                      to="/views/job-description"
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  {canEditJd() && (
                    <DEButton
                      text="Edit Job"
                      className="__default__button__layout __btn__red"
                      stylingMode="contained"
                      type="danger"
                      handleOnClick={() => handleOnCopyOrEdit('edit')}
                      to="/views/job-description"
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  {canApproveOrRejectJd() && (
                    <DEButton
                      text="Approve"
                      className="__default__button__layout __btn__green"
                      disabled={isLoading}
                      stylingMode="contained"
                      type="success"
                      style={{ marginRight: '10px' }}
                      onClick={handleApprove}
                    />
                  )}
                  {canApproveOrRejectJd() && (
                    <DEButton
                      text="Reject"
                      className="__default__button__layout __btn__red"
                      disabled={isLoading}
                      stylingMode="contained"
                      type="danger"
                      style={{ marginRight: '10px' }}
                      onClick={handleReject}
                    />
                  )}
                  {canSubmitJd() && (
                    <DEButton
                      text="Submit"
                      className="__default__button__layout __btn__green"
                      onClick={handleSubmit}
                      useSubmitBehavior={false}
                      stylingMode="contained"
                      type="success"
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  {canDeleteJd() && (
                    <DEButton
                      text="Delete"
                      className="__default__button__layout __btn__red"
                      disabled={isLoading}
                      onClick={manageDelete}
                      stylingMode="contained"
                      type="danger"
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  <DEButton
                    text="Cancel"
                    className="__default__button__layout __unset__border"
                    stylingMode="contained"
                    type="normal"
                    to="/views/manage-jobs"
                  />
                </div>
              </div>
            </div>
          </form>
        </ValidationGroup>
      </div>
    </div>
  );
});

export default JobDescription;