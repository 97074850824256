import * as React from "react";
import DataGrid, { AsyncRule, Column, Editing, Lookup, RowDragging, Export, Paging, Pager, Scrolling, Selection, SearchPanel, HeaderFilter, RequiredRule, Button } from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { useContext, useEffect, useRef, useState, useMemo } from "react";
import { TagBox } from "devextreme-react/tag-box";
import { Context } from "utils/context/store/Store";
import { removeKeyAndIndexFromArrayOfObjects, DEButton, createSanitizeAsyncRule } from "utils/services/Helpers";
import MDAlert from "components/MDAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { SET_NEW_FA_VARIABLE } from "utils/context/store/Constants";
import Swal from "sweetalert2";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import { CustomDTag } from "components/CustomDataGridComponents";

export default function VariableDataGrid({ rows, columns, dropDownData, functionalAccessId, loading, hitApi, permissions, isApprovals, isLoading
  , handleDelete, masterModuleId }) {

  const [isDataChanged, setIsDataChanged] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [{ newFaVariable }, dispatch] = useContext(Context)
  const dataGridRef = useRef()
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");


  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => { setDataColumns(columns) }, [columns]);
  useEffect(() => { }, [isDataChanged]);

  /**
 * @param col
 * @param dropDownData
 * function use to handle rendering of fields
 **/
  function renderField(col, dropDownData) {

    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          setCellValue={function (rowData, value) {
            if (col.hasOwnProperty("bindedTo"))
              rowData[col.bindedTo] = null;
            this.defaultSetCellValue(rowData, value);
          }}>
          {
            col.required ? <RequiredRule /> : null
          }
          <Lookup allowClearing
            dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : []}
            displayExpr="label" valueExpr="id" />
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        return <Column dataField={col.dataIndex} caption={col.title}
          editorOptions={{ dropDownOptions: { width: "auto" } }}
          setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value);
            if (col.hasOwnProperty("bindedTo")) {
              rowData[col.bindedTo] = null;
            }
          }}>
          <Lookup allowClearing dataSource={(options) => {
            const data = {
              store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
              filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
            }
            return data;
          }} displayExpr="label" valueExpr="id" />
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>;
      }
    }
    else if (col.type === "multi-select") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTag}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === 'search' && typeof (filterValue) === 'string') {
            return [col.dataIndex, 'contains', filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
        <Lookup allowClearing dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null} displayExpr="label" valueExpr="id" />
      </Column>;
    }
    else if (col.type === "toggle") {
      return <Column dataType="boolean"
        
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "checkbox") {
      return <Column dataType="boolean"
        
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} type="buttons" dataField={col.dataIndex} caption={col.title}>
        <Button name="delete" icon={'trash'} />
      </Column>
    }
    else if (col.type === "date") {
      return <Column dataType={"date"} format={'dd-MM-yyyy'} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.type === "button") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} type={"buttons"} dataField={col.dataIndex} caption={col.title}></Column>
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} type={"buttons"} dataField={col.dataIndex} caption={col.title} editCellComponent={CustomFileInput} />
    }
    else if (col.type === "string") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
  }

  /**
 * @param e
 * initialize new row in the data-grid
 **/
  const onInitNewRow = (e) => {
    e.data.newRow = true
    e.data.editable = false
    e.data.required = false
    e.data.visible = false
    e.data.sortable = false
    e.data.searchable = false
    setIsDataChanged(false)
  }

  /**
* function use to call the api to post data
**/
  const pushData = async () => {
    await hitApi(functionalAccessId, removeKeyAndIndexFromArrayOfObjects(dataSource))
  }

  /**
 * @param e
 * function use to prepare toolbar
 **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: isDataChanged === false,
          onClick: function () { pushData() },
        }
      }
    );
  }

  /**
* @param e
* function to define condition to show the delete icon
**/
  const allowDeleting = (e) => {
    return !e.row.data.hasOwnProperty('id');
  }

  /**
 * @param selectedRowKeys
 * @param selectedRowsData
 * used to get selected rows detail of data-grid
 **/
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
 * get selected rows
 **/
  const hasSelected = selectedRowKeys.length > 0

  // bulk delete using row selection
  const manageDelete = () => {

    const result = selectedRowKeys.map(a => a.id);;
    const msg = "You won't be able to revert this!"

    Swal.fire({
      title: 'Are you sure?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, delete it!",
      heightAuto: false,
      height: '200px',
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      }
    }).then(async (res) => {
      const newRecords = dataSource.filter(a => !a.hasOwnProperty('id'))
      let newData = [...dataSource]
      if (res.isConfirmed) {
        const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id'))
        const deleteFromTable = selectedRowKeys.filter(a => !a.hasOwnProperty('id'))

        if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {

          dispatch({ type: SET_NEW_FA_VARIABLE, payload: {} })
          await handleDelete(result, functionalAccessId, masterModuleId)
        }
        else if (deleteFromApi && deleteFromApi.length > 0) {

          dispatch({ type: SET_NEW_FA_VARIABLE, payload: {} })
          newFaVariable.rows = newRecords
          newFaVariable.apiDelete = true

          dispatch({ type: SET_NEW_FA_VARIABLE, payload: newFaVariable })
          await handleDelete(result, functionalAccessId, masterModuleId)
        }
        else if (deleteFromTable && deleteFromTable.length > 0) {
          deleteFromTable.map(a => {
            newData = newData.filter((item) => item.key !== a.key);
          })
          setDataSource(newData)
          toast.success("Record Deleted!")
        }
        setSelectedRowKeys([]);
      }
      setSelectedRowKeys([]);
    })
  }

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const Comp = useMemo(() => {
    return (
      <>

        {selectedRowKeys.length > 0
          ?
          <>
            <br />
            <MDAlert color="light">

              <MDTypography variant="subtitle2">
                {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
              </MDTypography>

              <Divider orientation="vertical" color="dark" flexItem />

              <MDBox>
                <Grid container spacing={2}>
                  {
                    permissions && permissions.canDelete
                      ? <Grid item >
                        <DEButton stylingMode={"contained"} type={"danger"} icon="trash" onClick={() => manageDelete()} />
                      </Grid>
                      : null
                  }
                </Grid >
              </MDBox >
            </MDAlert >
          </>
          : ""
        }

        <div id="data-grid-demo">
          <DataGrid
            onSaved={(e) => {
              if (e && e.changes && e.changes.length && (e.changes[0].type === "insert" || e.changes[0].type === "update")) {
                e.changes[0]['data']['tableNameObj'] = dropDownData && dropDownData.tableName && dropDownData.tableName.filter(o => o.id === e.changes[0]['data']['tableName'])[0]
                e.changes[0]['data']['tableColumnObj'] = dropDownData && dropDownData.tableColumnName && dropDownData.tableColumnName.filter(o => o.id === e.changes[0]['data']['tableColumnName'])[0]
                e.changes[0]['data']['typeObj'] = dropDownData && dropDownData.type && dropDownData.type.filter(o => o.id === e.changes[0]['data']['type'])[0]
                const dd = e.changes[0].data;
                let finalData = []
                finalData.push(dd)

                let result = []

                if (dataSource && dataSource.length) {
                  result = _.unionBy(finalData, dataSource);
                }
                else result.push(dd)
                setIsDataChanged(true)
              }
            }}
            dataSource={dataSource}
            showColumnLines={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            ref={dataGridRef} onInitNewRow={onInitNewRow}
            onRowUpdated={(e) => {
              setIsDataChanged(true)
            }}
            onSelectionChanged={onSelectionChanged} allowColumnResizing={true} keyExpr="key"
            showBorders={true}
            onToolbarPreparing={onToolbarPreparing}>
            <Scrolling showScrollbar="always" mode="infinite" />
            <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
            <HeaderFilter visible={true} allowSearch={true} />
            <SearchPanel visible={true} />
            <Export enabled={true} allowExportSelectedData={true} />
            <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="cell" allowUpdating={permissions && permissions.canCreate} allowAdding={permissions && permissions.canCreate} allowDeleting={allowDeleting} />
            <Column allowEditing={true} visible={false} allowSearch={false} allowSorting={true} dataField={"test"} caption={"test"}></Column>
            {
              dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
            }
          </DataGrid>
        </div>
      </>
    );
  }, [dataSource, dataColumns, dropDownData, isDataChanged, isLoading, selectedRowKeys]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setIsDataChanged} isDataChanged={isDataChanged} />
      {Comp}
    </React.Fragment>
  );


}
