import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
  memo,
  useCallback
} from 'react';
import Loadable from 'react-loadable';
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Export,
  HeaderFilter,
  LoadPanel,
  Lookup,
  MasterDetail,
  Pager,
  Paging,
  RequiredRule,
  SearchPanel,
  Selection
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import { DEButton, getLevels, isObjectEmpty } from '../../../../utils/services/Helpers';
import { onRowExpanding, renderAttachment } from '../../../../utils/services/DatagridHelpers';
import { Context } from '../../../../utils/context/store/Store';
import {
  CustomDateBox,
  CustomDTag,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea,
  CustomTextBox,
  SelectJD
} from '../../../../components/CustomDataGridComponents';
import MDAlert from '../../../../components/MDAlert';
import MDTypography from '../../../../components/MDTypography';
import Divider from '@mui/material/Divider';
import MDBox from '../../../../components/MDBox';
import Grid from '@mui/material/Grid';
import useApprovals from '../../../../utils/hooks/useApprovals';
import useManageHeadCounts from '../../../../utils/hooks/useManageHeadCounts';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Skelton from '../../../../components/Skelton/defaultSkelton';
const HcDetailsView = Loadable({
  loader: () => import('../../HcDetails/HcDetailsView'),
  loading: () => <Skelton />
});
const CloneHeadCountPopup = Loadable({
  loader: () => import('../../components/CloneHeadcountPopup'),
  loading: () => <Skelton />
});
const ActionDropdown = Loadable({
  loader: () => import('../../components/ActionDropdown'),
  loading: () => <Skelton />
});
const _ = require('lodash');

const VersionSlider = memo(({
  data,
  columns,
  dropDownData,
  headcountDetails,
  routeKey,
  permissions,
  apiCallBack,
  onRowExpand,
  dataSource,
  setDataSource,
  dgInstance,
  canApproveReject,
  selectedRowKeys,
  setSelectedRowKeys,
  pagesAndPermissions,
  dataAccess
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataToPass, setDataToPass] = useState(data[0]);

  const detailTemplateStyle = {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    width: '100%'
  };

  const buttonContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const buttonStyle = {
    margin: '0 10px'
  };

  const detailContentStyle = {
    textAlign: 'center',
    padding: '20px',
    flexGrow: 1,
    width: 'calc(100% - 80px)' // Adjust width considering the buttons
  };

  const nextDetail = () => {
    const idx = (currentIndex + 1) % data.length;
    setDataToPass(data[idx]);
    setCurrentIndex(idx);
  };

  const prevDetail = () => {
    const idx = (currentIndex - 1 + data.length) % data.length;
    setDataToPass(data[idx]);
    setCurrentIndex(idx);
  };

  return (
    <div style={detailTemplateStyle}>
      <div style={buttonContainerStyle}>
        <DEButton icon="chevronleft" onClick={prevDetail} style={buttonStyle} />
      </div>
      <div style={{ width: '100%' }}>
        <HcDetailsView
          key={currentIndex}
          data={dataToPass}
          columns={columns}
          dropDownData={dropDownData}
          headcountDetails={headcountDetails}
          dataAccess={dataAccess}
          routeKey={routeKey}
          permissions={permissions}
          apiCallBack={apiCallBack}
          dataSource={dataSource}
          setDataSource={setDataSource}
          dgInstance={dgInstance}
          canApproveReject={dataToPass?.isLive ? canApproveReject : false}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          pagesAndPermissions={pagesAndPermissions}
        />
      </div>
      <div style={buttonContainerStyle}>
        <DEButton icon="chevronright" onClick={nextDetail} style={buttonStyle} />
      </div>
    </div>
  );
});
/**
 * custom component to display HC details
 **/
const CustomMasterDetail = memo(
  ({
    props,
    columns,
    dropDownData,
    headcountDetails,
    routeKey,
    permissions,
    apiCallBack,
    onRowExpand,
    dataSource,
    setDataSource,
    dgInstance,
    canApproveReject,
    selectedRowKeys,
    setSelectedRowKeys,
    pagesAndPermissions
  }) => {
    const [{ dataAccess }, dispatch] = useContext(Context);

    return (
      <>
        {onRowExpand && headcountDetails ? (
          headcountDetails?.hcVersions?.length ? (
            <VersionSlider
              data={headcountDetails?.hcVersions}
              columns={columns}
              dropDownData={dropDownData}
              headcountDetails={headcountDetails}
              dataAccess={dataAccess}
              routeKey={routeKey}
              permissions={permissions}
              apiCallBack={apiCallBack}
              dataSource={dataSource}
              setDataSource={setDataSource}
              dgInstance={dgInstance}
              canApproveReject={canApproveReject}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              pagesAndPermissions={pagesAndPermissions}
            />
          ) : (
            <HcDetailsView
              data={headcountDetails?.data ?? {}}
              columns={columns}
              dropDownData={dropDownData}
              headcountDetails={headcountDetails}
              dataAccess={dataAccess}
              routeKey={routeKey}
              permissions={permissions}
              apiCallBack={apiCallBack}
              dataSource={dataSource}
              setDataSource={setDataSource}
              dgInstance={dgInstance}
              canApproveReject={canApproveReject}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              pagesAndPermissions={pagesAndPermissions}
            />
          )
        ) : null}
      </>
    );
  }
);
CustomMasterDetail.displayName = 'CustomMasterDetail';

const AllHcDataGrid = memo(
  ({
    rows,
    columns,
    dropDownData = null,
    routeKey,
    transactionType = null,
    fields,
    permissions,
    apiCallBack = null,
    requestIdFilter,
    setrIdToFilter,
    dataAccess,
    isLoading
  }) => {
    const [dataSource, setDataSource] = useState([]);
    const [dataColumns, setDataColumns] = useState([]);
    const [onRowExpand, setOnRowExpand] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const dataGridRef = useRef();
    const {
      bulkApproveOrReject,
      handleBulkHoldOrReActivateRequest,
      bulkRollback,
      bulkCancelGroupRequest
    } = useApprovals();
    const { getHeadCountDetails, getAllHeadCounts, headcountDetails, pagesAndPermissions } =
      useManageHeadCounts(routeKey);
    const masterModuleId = 3;
    const navigate = useNavigate();

    useEffect(() => {
      // cleanup on unmount
      return () => {
        setDataSource([]);
        setDataColumns([]);
        setSelectedRowKeys([]);
      };
    }, []);

    useEffect(() => {
      setDataSource(rows);
    }, [rows]);

    useEffect(() => {
      setDataColumns(columns);
    }, [columns]);

    /**
     * @param col
     * @param dropDownData
     * function use to handle rendering of fields
     **/
    function renderField(col, dropDownData) {
      if (col.type === 'select') {
        if (!col.hasOwnProperty('filtrationKey')) {
          return (
            <Column
              key={`manage-hc-render-field-${col.dataIndex}`}
              alignment={'left'}
              allowEditing={col.dataIndex === 'gig' ? true : col.editable}
              visible={col.is_visible}
              allowSearch={col.is_searchable}
              allowSorting={col.is_sortable}
              dataField={col.dataIndex}
              caption={col.title}
              setCellValue={function (rowData, value) {
                if (col.dataIndex === 'masterOrgDivisionId') {
                  rowData['masterOrgEntityId'] = null;
                  rowData['masterCountryId'] = null;
                  rowData['masterOrgVerticalId'] = null;
                  rowData['masterOrgFunctionId'] = null;
                  rowData['masterLevelId'] = [];
                }
                if (col.dataIndex === 'masterJobTypeId') {
                  let gridInstance = dataGridRef.current.instance;
                  let editRowKey = gridInstance.option('editing.editRowKey');
                  let index = gridInstance.getRowIndexByKey(editRowKey);
                  const jobType = gridInstance.cellValue(index, 'masterJobTypeId');
                  const status = gridInstance.cellValue(index, 'dStatus');

                  if (jobType && !status) {
                    if (jobType !== value) {
                      if (value === 2) {
                        rowData['hireDate'] = null;
                        rowData['masterManagementTypeId'] = null;
                        rowData['billable'] = false;
                        rowData['critical'] = false;
                        rowData['justification'] = null;
                      }

                      if (value === 1) {
                        rowData['masterGigId'] = null;
                        rowData['startDate'] = null;
                        rowData['endDate'] = null;
                        rowData['billable'] = false;
                        rowData['justification'] = null;
                      }
                    }
                  }

                  if (jobType && status) {
                    rowData['pToF'] = false;
                    rowData['wantsToConvertToFreelancer'] = jobType !== value;
                  }
                }

                if (col.dataIndex === 'masterRecruiterId') {
                  let gridInstance = dataGridRef.current.instance;
                  let editRowKey = gridInstance.option('editing.editRowKey');
                  let index = gridInstance.getRowIndexByKey(editRowKey);
                  const msId = gridInstance.cellValue(index, 'masterRecruiterId');
                  const status = gridInstance.cellValue(index, 'dStatus');

                  if (msId && status) {
                    if (msId !== value) rowData['recruiterChanged'] = true;
                  }
                }

                if (col.dataIndex === 'masterLevelId') {
                  rowData['masterLevelId'] = null;
                }

                if (col.hasOwnProperty('bindedTo')) rowData[col.bindedTo] = null;
                this.defaultSetCellValue(rowData, value);
              }}
            >
              {col.required ? <RequiredRule /> : null}
              {col.dataIndex === 'masterLevelId' && col.required ? (
                <AsyncRule
                  message={'No JD found with the combination'}
                  validationCallback={async (e) => {
                    if (e && e.data && e.data.hasOwnProperty('jd_transaction')) {
                      return true;
                    } else {
                      let gridInstance = dataGridRef.current.instance;
                      let editRowKey = gridInstance.option('editing.editRowKey');
                      let index = gridInstance.getRowIndexByKey(editRowKey);
                      const masterOrgDivisionId = gridInstance.cellValue(
                        index,
                        'masterOrgDivisionId'
                      );
                      const masterOrgEntityId = gridInstance.cellValue(index, 'masterOrgEntityId');
                      const masterCountryId = gridInstance.cellValue(index, 'masterCountryId');
                      const masterOrgVerticalId = gridInstance.cellValue(
                        index,
                        'masterOrgVerticalId'
                      );
                      const masterOrgFunctionId = gridInstance.cellValue(
                        index,
                        'masterOrgFunctionId'
                      );
                      const status = gridInstance.cellValue(index, 'dStatus');
                      if (masterOrgDivisionId && masterOrgVerticalId && masterOrgFunctionId) {
                        const combination = {
                          masterOrgDivisionId,
                          masterOrgFunctionId,
                          masterOrgVerticalId,
                          masterLevelId: e.value
                        };

                        if (masterOrgEntityId) {
                          combination['masterOrgEntityId'] = masterOrgEntityId;
                        }
                        if (masterCountryId) {
                          combination['masterCountryId'] = masterCountryId;
                        }
                        if (hcJdView && isObjectEmpty(hcJdView)) {
                        } else {
                        }

                        if (
                          (typeof hasJd === 'object' && Object.keys(hasJd).length) ||
                          (typeof hcJdView === 'object' && Object.keys(hcJdView).length)
                        ) {
                          e.data['minRange'] = hasJd?.level?.minRange;
                          e.data['transactionJdId'] = hasJd.transactionJdId;
                          e.data['hasJd'] = true;
                          return true;
                        } else {
                          delete e.data['minRange'];
                          delete e.data['transactionJdId'];
                          e.data['hasJd'] = false;
                          return false;
                        }
                      } else {
                        return false;
                      }
                    }
                  }}
                />
              ) : null}
              {col.dataIndex === 'masterLevelId' ? (
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getLevelsLocal(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
              ) : (
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    const data = {
                      store:
                        dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                          ? dropDownData[col.dataIndex]
                          : [],
                      paginate: true,
                      pageSize: 10
                    };
                    return data;
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
              )}
            </Column>
          );
        } else if (col.hasOwnProperty('filtrationKey')) {
          if (col.dataIndex === 'masterOrgVerticalId') {
            return (
              <Column
                key={`manage-hc-render-field-${col.dataIndex}`}
                alignment={'left'}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  rowData['masterOrgVerticalId'] = null;
                  rowData['masterOrgFunctionId'] = null;
                  rowData['masterLevelId'] = [];
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                  dispatch({ type: SET_HC_JD_VIEW, payload: [] });
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getVerticalsByEntityAndDivision(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterOrgFunctionId') {
            return (
              <Column
                key={`manage-hc-render-field-${col.dataIndex}`}
                alignment={'left'}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  rowData['masterOrgFunctionId'] = null;
                  rowData['masterLevelId'] = [];
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getFunctionsByVerticals(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterCountryId') {
            col.required = true;
            return (
              <Column
                key={`manage-hc-render-field-${col.dataIndex}`}
                alignment={'left'}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.dataIndex === 'masterCountryId') {
                    rowData['masterLevelId'] = [];
                    rowData['masterOrgVerticalId'] = null;
                    rowData['masterOrgFunctionId'] = null;
                  }
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getCountriesByDivisionOrEntity(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterOrgEntityId') {
            col.required = true;
            return (
              <Column
                key={`manage-hc-render-field-${col.dataIndex}`}
                alignment={'left'}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.dataIndex === 'masterOrgEntityId') {
                    rowData['masterLevelId'] = [];
                    rowData['masterCountryId'] = null;
                    rowData['masterOrgVerticalId'] = null;
                    rowData['masterOrgFunctionId'] = null;
                  }
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getEntitiesByDivision(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else {
            return (
              <Column
                key={`manage-hc-render-field-${col.dataIndex}`}
                alignment={'left'}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return {
                      store:
                        dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                          ? dropDownData[col.dataIndex]
                          : [],
                      filter: options.data
                        ? [col.filtrationKey, '=', options.data[col.filtrationKey]]
                        : null
                    };
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          }
        }
      } else if (col.type === 'multi-select') {
        return (
          <Column
            key={`manage-hc-render-field-${col.dataIndex}`}
            alignment={'left'}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={CustomDTag}
            cellTemplate={(container, options) => {
              const noBreakSpace = '\u00A0';
              const text = (options.value || [])
                .map((element) => options.column.lookup.calculateCellValue(element))
                .join(', ');
              container.textContent = text || noBreakSpace;
              container.title = text;
            }}
            calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
              if (target === 'search' && typeof filterValue === 'string') {
                return [col.dataIndex, 'contains', filterValue];
              }
              return function (data) {
                return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
              };
            }}
          >
            {col.required ? <RequiredRule /> : null}
            <Lookup
              allowClearing
              dataSource={
                dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                  ? dropDownData[col.dataIndex]
                  : null
              }
              displayExpr="label"
              valueExpr="id"
            />
          </Column>
        );
      } else if (col.type === 'checkbox') {
        return (
          <Column
            key={`manage-hc-render-field-${col.dataIndex}`}
            alignment={'center'}
            dataType="boolean"
            showEditorAlways={true}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
            }}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'actions') {
        return (
          <Column
            key={`manage-hc-render-field-${col.dataIndex}`}
            type="buttons"
            dataField={col.dataIndex}
            caption={col.title}
            width={'auto'}
          ></Column>
        );
      } else if (col.type === 'date') {
        return (
          <Column
            key={`manage-hc-render-field-${col.dataIndex}`}
            alignment={'left'}
            editCellComponent={CustomDateBox}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            format={'dd-MM-yyyy'}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            {col.dataIndex === 'endDate' ? (
              <AsyncRule
                message="end date cannot be less than start date"
                validationCallback={async (e) => {
                  if (e && e.data) {
                    if (e.data.startDate && e.data.endDate) {
                      return e.data.endDate >= e.data.startDate;
                    } else return e.value >= e.data.startDate;
                  }
                }}
              />
            ) : null}
          </Column>
        );
      } else if (col.type === 'int') {
        return (
          <Column
            key={`manage-hc-render-field-${col.dataIndex}`}
            alignment={'left'}
            dataType={col.type}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={(props) => (
              <CustomNumberBox props={props.data} canEdit={col.editable} isForHc={true} />
            )}
          >
            {col.required ? <RequiredRule /> : null}
            {col.dataIndex !== 'id' ? (
              <AsyncRule
                message={'Value should not exceed more than 15 digits'}
                validationCallback={async (e) => {
                  return e && e.value && e.value.toString().length <= 15;
                }}
              />
            ) : null}
          </Column>
        );
      } else if (col.type === 'button') {
        return (
          <Column
            key={`manage-hc-render-field-${col.dataIndex}`}
            alignment={'left'}
            allowEditing={true}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={(props) => <SelectJD props={props.data} canEdit={col.editable} />}
          >
            <RequiredRule />
          </Column>
        );
      } else if (col.type === 'file') {
        return (
          <Column
            key={`manage-hc-render-field-${col.dataIndex}`}
            alignment={'left'}
            showInColumnChooser={false}
            allowEditing={col.editable}
            visible={col.visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            fixed={false}
            dataField={col.dataIndex}
            caption={col.title}
            cellRender={renderAttachment}
            editCellComponent={CustomFileInput}
          />
        );
        {
          col.required ? <RequiredRule /> : null;
        }
      } else if (col.type === 'textarea') {
        return (
          <Column
            key={`manage-hc-render-field-${col.dataIndex}`}
            alignment={'left'}
            editCellComponent={CustomTextArea}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'text') {
        return (
          <Column
            key={`manage-hc-render-field-${col.dataIndex}`}
            alignment={'left'}
            editCellComponent={CustomTextBox}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            cssClass={'WrappedColumnClass'}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else {
        if (col.dataIndex === 'jobName') {
          return (
            <Column
              key={`manage-hc-render-field-${col.dataIndex}`}
              alignment={'left'}
              allowEditing={col.editable}
              visible={col.is_visible}
              allowSearch={col.is_searchable}
              allowSorting={col.is_sortable}
              type={onRowExpand ? 'buttons' : 'buttons'}
              dataField={col.dataIndex}
              caption={col.title}
              fixed={false}
            >
              <Button
                name="Job Title"
                component={(props) => {
                  return (
                    <>
                      {
                        props.data.data.jobName
                        /*props?.data?.data?.transactionType === "hc_transaction" ? <a href={`/views/jd/Job-${props?.data?.data?.jobCode ?? props?.data?.data?.jd_transaction?.jobCode}/${props?.data?.data?.version ?? props?.data?.data?.jd_transaction?.version}`} target={"_blank"}>
                    <u>{props.data.data.jobName}</u></a>
                    : props.data.data.jobName*/
                      }
                    </>
                  );
                }}
              />
              {col.required ? <RequiredRule /> : null}
            </Column>
          );
        } else {
          return (
            <Column
              key={`manage-hc-render-field-${col.dataIndex}`}
              alignment={'left'}
              allowEditing={col.editable}
              visible={col.is_visible}
              allowSearch={col.is_searchable}
              allowSorting={col.is_sortable}
              dataField={col.dataIndex}
              caption={col.title}
            >
              {col.required ? <RequiredRule /> : null}
            </Column>
          );
        }
      }
    }

    /**
     * @param options
     * function use get verticals based on division and entity
     **/
    function getVerticalsByEntityAndDivision(options) {
      let uniqueVerticals = [];
      if (
        options?.data?.hasOwnProperty('masterOrgDivisionId') &&
        options?.data?.masterOrgDivisionId !== null &&
        options?.data?.hasOwnProperty('masterOrgEntityId') &&
        options?.data?.masterOrgEntityId !== null &&
        options?.data?.hasOwnProperty('masterCountryId') &&
        options?.data?.masterCountryId !== null
      ) {
        const filteredVerticals =
          dropDownData['masterOrgVerticalId'].filter(
            (obj) =>
              obj.masterCountryId === options.data?.masterCountryId &&
              obj.masterOrgEntityId === options.data?.masterOrgEntityId &&
              obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['masterOrgVerticalId'], item])).values()
        ];
      } else if (
        options?.data?.hasOwnProperty('masterOrgDivisionId') &&
        options?.data?.masterOrgDivisionId !== null &&
        options?.data?.hasOwnProperty('masterOrgEntityId') &&
        options?.data?.masterOrgEntityId === null &&
        options?.data?.hasOwnProperty('masterCountryId') &&
        options?.data?.masterCountryId !== null
      ) {
        const filteredVerticals =
          dropDownData['masterOrgVerticalId'].filter(
            (obj) =>
              obj.masterCountryId === options.data?.masterCountryId &&
              obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['masterOrgVerticalId'], item])).values()
        ];
      } else if (
        options?.data?.hasOwnProperty('masterOrgDivisionId') &&
        options?.data?.masterOrgDivisionId !== null &&
        options?.data?.hasOwnProperty('masterOrgEntityId') &&
        options?.data?.masterOrgEntityId !== null
      ) {
        const filteredVerticals =
          dropDownData['masterOrgVerticalId'].filter(
            (obj) =>
              obj.masterOrgEntityId === options.data?.masterOrgEntityId &&
              obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['masterOrgVerticalId'], item])).values()
        ];
      } else if (isObjectEmpty(options)) {
        const filteredVerticals = dropDownData?.hasOwnProperty('masterOrgVerticalId')
          ? dropDownData['masterOrgVerticalId']
          : [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['masterOrgVerticalId'], item])).values()
        ];
      } else {
        const filteredVerticals =
          dropDownData['masterOrgVerticalId'].filter(
            (obj) => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['masterOrgVerticalId'], item])).values()
        ];
      }

      return uniqueVerticals.length > 0
        ? uniqueVerticals.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
        : [];
    }

    /**
     * @param options
     * function use get functions based on verticals
     **/
    function getFunctionsByVerticals(options) {
      let allUniqueValues = [];
      if (isObjectEmpty(options)) {
        const filteredCap =
          dropDownData && dropDownData.hasOwnProperty('masterOrgFunctionId')
            ? dropDownData['masterOrgFunctionId']
            : [];
        allUniqueValues = [
          ...new Map(filteredCap?.map((item) => [item['masterOrgFunctionId'], item])).values()
        ];
      } else {
        const filteredCap =
          dropDownData['masterOrgFunctionId'].filter(
            (cap) =>
              cap.masterOrgVerticalId === options?.data?.masterOrgVerticalId &&
              cap.masterOrgDivisionId === options?.data?.masterOrgDivisionId &&
              cap.masterOrgEntityId === options?.data?.masterOrgEntityId &&
              cap.masterCountryId === options?.data?.masterCountryId
          ) ?? [];
        allUniqueValues = [
          ...new Map(filteredCap?.map((item) => [item['masterOrgFunctionId'], item])).values()
        ];
      }
      return allUniqueValues.length > 0
        ? allUniqueValues.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
        : [];
    }

    /**
     * @param options
     * function use get countries by division or entity
     **/
    function getCountriesByDivisionOrEntity(options) {
      let uniqueCountries = [];
      if (
        options?.data?.hasOwnProperty('masterOrgDivisionId') &&
        options?.data?.masterOrgDivisionId !== null &&
        options?.data?.hasOwnProperty('masterOrgEntityId') &&
        options?.data?.masterOrgEntityId !== null
      ) {
        const filteredVerticals =
          dropDownData['masterCountryId'].filter(
            (obj) =>
              obj.masterOrgDivisionId === options.data?.masterOrgDivisionId &&
              obj.masterOrgEntityId === options.data?.masterOrgEntityId
          ) ?? [];
        uniqueCountries = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      } else if (
        (options?.data?.hasOwnProperty('masterOrgDivisionId') &&
          options?.data?.masterOrgDivisionId !== null &&
          !options?.data?.hasOwnProperty('masterOrgEntityId')) ||
        options?.data?.masterOrgEntityId === null
      ) {
        const filteredVerticals =
          dropDownData['masterCountryId'].filter(
            (obj) => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueCountries = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      } else {
        const filteredVerticals =
          dropDownData && dropDownData.hasOwnProperty('masterCountryId')
            ? dropDownData['masterCountryId']
            : [];
        uniqueCountries = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      }

      return uniqueCountries.length > 0
        ? uniqueCountries.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
        : [];
    }

    /**
     * @param options
     * function use get Entities by division
     **/
    function getEntitiesByDivision(options) {
      let uniqueEntities = [];
      if (
        options?.data?.hasOwnProperty('masterOrgDivisionId') &&
        options?.data?.masterOrgDivisionId !== null
      ) {
        const filteredEntities =
          dropDownData['masterOrgEntityId']?.filter(
            (d) => d.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueEntities = [...new Map(filteredEntities?.map((item) => [item['id'], item])).values()];
      } else {
        const filteredEntities =
          dropDownData && dropDownData.hasOwnProperty('masterOrgEntityId')
            ? dropDownData['masterOrgEntityId']
            : [];
        uniqueEntities = [...new Map(filteredEntities?.map((item) => [item['id'], item])).values()];
      }

      return uniqueEntities.length > 0
        ? uniqueEntities.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
        : [];
    }

    /**
     * @param options
     * get levels by division entity country and vertical
     **/
    function getLevelsLocal(options) {
      let uniqueEntities = [];
      const allLevels = dropDownData?.hasOwnProperty('masterLevelId')
        ? dropDownData['masterLevelId']
        : [];
      if (options?.data?.masterOrgDivisionId !== null) {
        const vet =
          dropDownData?.hasOwnProperty('masterOrgVerticalId') &&
          dropDownData['masterOrgVerticalId']?.length
            ? dropDownData['masterOrgVerticalId']
            : [];
        uniqueEntities = getLevels(
          allLevels,
          vet,
          options.data.masterOrgDivisionId,
          options.data.masterOrgEntityId,
          options.data.masterCountryId,
          options.data.masterOrgVerticalId,
          dropDownData.hasOwnProperty('userLevels') ? dropDownData['userLevels'] : [],
          dropDownData.hasOwnProperty('LIds') ? dropDownData['LIds'] : []
        );
      } else {
        uniqueEntities = [...new Map(allLevels?.map((item) => [item['id'], item])).values()];
      }

      return uniqueEntities.length > 0
        ? uniqueEntities.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
        : [];
    }

    const onRowPrepared = useCallback((e) => {
      if (
        (e?.data?.hasOwnProperty('cancelled') && e?.data?.cancelled === true) ||
        e?.data?.cStatus === 'Closed'
      ) {
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        e.rowElement.className += ' cancelled';
      }

      if (e?.data?.hasOwnProperty('needApproval') && e?.data?.needApproval) {
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        e.rowElement.className += ' needApproval';
      } else if (
        e?.data?.hasOwnProperty('status') &&
        e?.data?.status &&
        !e?.data?.cancelled &&
        e?.data?.cStatus !== 'On Hold'
      ) {
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        e.rowElement.className += ' approved';
      } else if (
        e?.data?.hasOwnProperty('status') &&
        e?.data?.status === false &&
        !e?.data?.cancelled &&
        !e?.data?.isDraft
      ) {
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        e.rowElement.className += ' rejected';
      }

      // add approved class on leaver when all it's replacements is approved
      // if (e.data?.transactionType === "transaction_leaver" && e.data?.headcounts.length) {
      //   if (!e.data.headcounts.some(e => !e.status)) {
      //     e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      //     e.rowElement.className += " approved";
      //   }
      // }

      // if (e && e.rowType === "data" && e.data && !e.data.hasOwnProperty('leavers')) {
      //   e.rowElement.querySelector(".dx-command-expand")?.firstChild.classList.remove("dx-datagrid-group-closed");
      // }
    }, []);

    const processCondition = async (
      approveReject,
      data,
      masterModuleId,
      setSelectedRowKeys,
      apiCallBack,
      hasBoth = false
    ) => {
      if (data?.length) {
        if (hasBoth) {
          await bulkApproveOrReject(
            approveReject,
            data,
            'hc_transaction',
            masterModuleId,
            setSelectedRowKeys,
            apiCallBack,
            'needApproval',
            hasBoth
          );
        } else {
          for (const d of data) {
            if (d.hc_transaction?.length)
              setTimeout(() => {
                const f = async () =>
                  await bulkApproveOrReject(
                    approveReject,
                    d.hc_transaction,
                    'hc_transaction',
                    masterModuleId,
                    setSelectedRowKeys,
                    apiCallBack
                  );
                f();
              }, 200);
            if (d.transaction_leaver?.length)
              setTimeout(() => {
                const f = async () =>
                  await bulkApproveOrReject(
                    approveReject,
                    d.transaction_leaver,
                    'transaction_leaver',
                    masterModuleId,
                    setSelectedRowKeys,
                    apiCallBack
                  );
                f();
              }, 200);
          }
        }
      }
    };

    /**
     * Handle Bulk Approve Requests
     **/
    const handleBulkApprove = async () => {
      const hcRequiredFields = fields?.hc_transaction?.length
        ? fields?.hc_transaction?.filter((f) => f.required && f.is_visible)
        : [];
      const leaverRequiredFields = fields?.transaction_leaver?.length
        ? fields?.hc_transaction?.filter((f) => f.required && f.is_visible)
        : [];

      if (transactionType === 'hc_transaction') {
        const needApprovalHcData =
          selectedRowKeys?.filter(
            (obj) => obj.needApproval && obj.transactionType === 'hc_transaction'
          ) ?? [];
        if (hcRequiredFields.length) {
          if (needApprovalHcData?.every((obj) => obj?.isRequiredFieldsFilledFromApi)) {
            await bulkApproveOrReject(
              true,
              _.map(needApprovalHcData, 'id'),
              'hc_transaction',
              masterModuleId,
              setSelectedRowKeys,
              apiCallBack,
              'needApproval',
              false,
              selectedRowKeys
            );
          } else if (needApprovalHcData?.every((obj) => obj?.data?.isRequiredFieldsFilled)) {
            await bulkApproveOrReject(
              true,
              _.map(needApprovalHcData.flatMap((d) => d.data.data || [])),
              'hc_transaction',
              masterModuleId,
              setSelectedRowKeys,
              apiCallBack
            );
          } else toast.error('There are some missing values. Please fill them before proceeding!');
        } else if (needApprovalHcData?.length) {
          await bulkApproveOrReject(
            true,
            _.map(needApprovalHcData, 'id'),
            'hc_transaction',
            masterModuleId,
            setSelectedRowKeys,
            apiCallBack,
            'needApproval',
            false,
            selectedRowKeys
          );
        }
      }

      if (transactionType === 'transaction_leaver') {
        const canProceed = [];
        const replacements = selectedRowKeys.filter(
          (sr) => sr.noOfReplacements > 0 && sr.needApproval
        );
        // console.log('replacements', replacements)
        const noReplacements = selectedRowKeys.filter(
          (sr) => sr.noOfReplacements <= 0 && sr.needApproval
        );
        // console.log('noReplacements', noReplacements)
        let replacementsData = [];
        let noReplacementsData = [];
        let replacementsIDs = [];
        let noReplacementsIDs = [];

        if (replacements.length) {
          if (replacements?.every((r) => r.status && r.isRequiredFieldsFilledFromApi)) {
            noReplacementsIDs = _.map(replacements, 'id');
            canProceed.push(true);
          } else if (hcRequiredFields?.length) {
            if (replacements?.every((e) => e.isRequiredFieldsFilledFromApi)) {
              const d = replacements.filter(
                (s) => s.isRequiredFieldsFilledFromApi && s?.hcIds?.length
              );
              replacementsIDs = d.flatMap((a) => a.hcIds || []);
              if (replacementsIDs?.length) canProceed.push(true);
              else canProceed.push(false);
            } else {
              const d = replacements.filter(
                (s) =>
                  s?.data?.data?.headcounts.filter((d) => d.needApproval) || isObjectEmpty(s?.data)
              );
              // console.log('d', d)
              // console.log('d?.every(s => s.data.data.headcounts.every(i => i.isRequiredFieldsFilled))', d?.every(s => s?.data?.data?.headcounts?.every(i => i.isRequiredFieldsFilled)))
              if (
                d?.length &&
                d?.every((s) => s?.data?.data?.headcounts?.every((i) => i.isRequiredFieldsFilled))
              ) {
                canProceed.push(true);
                replacementsData = d.flatMap((a) => a.data.data.headcounts || []);
              } else canProceed.push(false);
            }
          } else {
            const d = replacements.filter(
              (s) => s.needApproval && s.noOfReplacements > 0 && s?.hcIds?.length && !s.isForCancel
            );
            const forCancel = replacements.filter(
              (s) => s.needApproval && s.noOfReplacements > 0 && !s?.hcIds?.length && s.isForCancel
            );
            replacementsIDs = d.flatMap((a) => a.hcIds || []);
            noReplacementsIDs = forCancel.flatMap((a) => a.id || []);
            if (replacementsIDs?.length) canProceed.push(true);
            else if (noReplacementsIDs?.length) canProceed.push(true);
            else canProceed.push(false);
          }
        }

        if (noReplacements.length) {
          if (leaverRequiredFields?.length) {
            if (noReplacements?.every((obj) => obj?.isRequiredFieldsFilled)) {
              canProceed.push(true);
              noReplacementsData = noReplacements.flatMap((a) => a.data.data || []);
            } else canProceed.push(false);
          } else {
            canProceed.push(true);
            noReplacementsIDs = _.map(noReplacements, 'id');
          }
        }

        if (canProceed?.length && canProceed.every((cp) => cp === true)) {
          const hasBoth =
            (replacementsData.length || replacementsIDs.length) &&
            (noReplacementsIDs.length || noReplacementsData.length);
          const combinedData = [
            {
              hc_transaction: replacementsData.length ? replacementsData : replacementsIDs,
              filterData: replacementsIDs?.length
                ? replacements?.filter((d) => d?.hcIds?.filter((s) => replacementsIDs.includes(s)))
                : []
            },
            {
              transaction_leaver: noReplacementsIDs.length ? noReplacementsIDs : noReplacementsData,
              filterData: noReplacementsIDs?.length
                ? noReplacements?.filter((d) => noReplacementsIDs.includes(d.id))
                : []
            }
          ];
          await processCondition(
            true,
            combinedData,
            masterModuleId,
            setSelectedRowKeys,
            apiCallBack,
            hasBoth
          );
        } else toast.error('There are some missing values. Please fill them before proceeding!');
      }
    };

    /**
     * Handle Bulk Reject Requests
     **/
    const handleBulkReject = async () => {
      if (transactionType === 'transaction_leaver') {
        const replacements = selectedRowKeys.filter(
          (sr) => sr.noOfReplacements > 0 && sr.needApproval && sr.hcIds?.length
        );
        const noReplacements = selectedRowKeys.filter(
          (sr) => sr.noOfReplacements <= 0 && sr.needApproval
        );
        const hasBoth = replacements.length && noReplacements.length;
        const combinedData = [
          {
            hc_transaction: replacements.flatMap((r) => r.hcIds || [])
          },
          {
            transaction_leaver: _.map(noReplacements, 'id')
          }
        ];
        await processCondition(false, combinedData, masterModuleId, null, apiCallBack, hasBoth);
      }

      if (transactionType === 'hc_transaction') {
        const needApprovalHcData =
          selectedRowKeys?.filter(
            (obj) => obj.needApproval && obj.transactionType === 'hc_transaction'
          ) ?? [];
        if (needApprovalHcData?.length)
          await bulkApproveOrReject(
            false,
            _.map(needApprovalHcData, 'id'),
            'hc_transaction',
            masterModuleId,
            setSelectedRowKeys,
            apiCallBack
          );
      }
    };

    /**
     * Handle Bulk Reject Requests
     **/
    const handleBulkRollback = async () => {
      if (transactionType === 'transaction_leaver') {
        const replacements = selectedRowKeys.filter(
          (sr) =>
            sr.noOfReplacements > 0 && sr.needApproval && sr.hcIds?.length && sr.status === null
        );
        const noReplacements = selectedRowKeys.filter(
          (sr) => sr.noOfReplacements <= 0 && sr.needApproval && sr.status === null
        );
        const hasBoth = replacements.length && noReplacements.length;

        if (replacements.length)
          await bulkRollback(
            replacements,
            'replacements',
            masterModuleId,
            setSelectedRowKeys,
            apiCallBack
          );

        if (noReplacements.length)
          await bulkRollback(
            noReplacements,
            'transaction_leaver',
            masterModuleId,
            setSelectedRowKeys,
            apiCallBack
          );
      }

      if (transactionType === 'hc_transaction') {
        const needApprovalHcData =
          selectedRowKeys?.filter(
            (obj) =>
              obj.needApproval && obj.transactionType === 'hc_transaction' && obj.status === null
          ) ?? [];
        if (needApprovalHcData?.length)
          await bulkRollback(
            needApprovalHcData,
            'hc_transaction',
            masterModuleId,
            setSelectedRowKeys,
            apiCallBack
          );
        else {
          toast.error('Request cannot be rollbacked!');
          setSelectedRowKeys([]);
        }
      }
    };

    /**
     * Handle Bulk Hold Requests
     **/
    const handleBulkHold = async (
      operation = true,
      isForApprover = false,
      key = 'canHoldForApprover'
    ) => {
      const data = isForApprover
        ? selectedRowKeys
            .filter((item) => item[key])
            .map((dataItem) => {
              return {
                transactionType: dataItem.transactionType,
                id: dataItem.id,
                hcIds:
                  dataItem.hasOwnProperty('hcIds') && dataItem?.hcIds?.length
                    ? dataItem?.hcIds
                    : dataItem.hasOwnProperty('repRApp') && dataItem?.repRApp?.length
                    ? dataItem?.repRApp
                    : []
              };
            })
        : selectedRowKeys
            .filter((item) => item.status && item[key] && item.cancelled !== true)
            .map((dataItem) => {
              if (dataItem?.initiatorHoldHCIDS?.length)
                return dataItem?.initiatorHoldHCIDS?.map((h) => ({
                  transactionType: 'hc_transaction',
                  id: h
                }));
              if (dataItem?.initiatorReActivateHCIDS?.length)
                return dataItem?.initiatorReActivateHCIDS?.map((h) => ({
                  transactionType: 'hc_transaction',
                  id: h
                }));
              else
                return {
                  transactionType: dataItem.transactionType,
                  id: dataItem.id
                };
            })
            ?.flat();

      if (data.length)
        await handleBulkHoldOrReActivateRequest(operation, data, isForApprover, apiCallBack);
      else toast.error(`Nothing to ${operation ? 'Hold' : 'Reactivate'}!`);
    };

    /**
     * @param selectedRowKeys
     * @param selectedRowsData
     * used to get selected rows detail of data-grid
     **/
    function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      setSelectedRowKeys(selectedRowsData);
    }

    /**
     * get selected rows
     **/
    const hasSelected = selectedRowKeys.length > 0;

    /**
     * @param e
     * function use to prepare toolbar
     * header of the table having buttons (e.g: add)
     **/
    function onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          visible: permissions && permissions.canCreate && requestIdFilter !== null,
          options: {
            text: 'VIEW ALL HEADCOUNTS',
            onClick: () => {
              localStorage.removeItem('rId');
              localStorage.removeItem('dgId');
              setrIdToFilter(null);
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          visible: permissions && permissions.canCreate,
          options: {
            icon: 'add',
            onClick: () => navigate('/views/manage-headcount')
          }
        }
      );
    }

    // Function to update a specific row in the data source
    const updateRowInDataSource = (updatedRow) => {
      // Find the index of the row to be updated
      const rowIndex = dataSource.findIndex((row) => row.id === updatedRow.id);

      if (rowIndex !== -1) {
        // Update the specific row in the data source
        const updatedDataSource = [...dataSource];
        updatedDataSource[rowIndex] = updatedRow;
        // Update the state
        setDataSource(updatedDataSource);
        setTimeout(() => {
          dataGridRef?.current?.instance?.expandRow(updatedRow?.id);
        }, 200);
      }
    };

    const handleRowExpandCollapse = (e) => {
      onRowExpanding(e, true, null, false);
      setOnRowExpand(true);
      dataGridRef.current.instance
        .getDataSource()
        .store()
        .byKey(e?.key)
        .done(async (rowData) => {
          if (rowData) {
            let transactionType = rowData?.transactionType ? rowData.transactionType : null;
            let transactionID = rowData?.id ? rowData.id : null;
            let delegatedUserId = rowData?.delegatedUserId ? rowData.delegatedUserId : null;
            let hasDataLoaded =
              rowData.hasOwnProperty('hasDataLoaded') && rowData?.hasDataLoaded === true;
            if (transactionType && transactionID && !hasDataLoaded) {
              rowData['hasDataLoaded'] = true;
              rowData['data'] = await getHeadCountDetails(
                transactionType,
                transactionID,
                null,
                true,
                delegatedUserId
              );
              updateRowInDataSource(rowData);
            } else updateRowInDataSource(rowData);
          }
        });
    };

    // when table each cell is ready then this
    // function will be called and apply custom classes to manage styling
    const onCellPrepared = useCallback((e) => {
      if (e.rowType === 'header') {
        onHeaderCellRender(e.cellElement);
      } else if (e.rowType === 'data') {
        onCellRender(e.cellElement);
      }
    }, []);

    // apply class to headers
    const onHeaderCellRender = useCallback((headerCell) => {
      headerCell.classList.add('__data__grid__table__header');
    }, []);

    // apply class to body
    const onCellRender = useCallback((cell) => {
      cell.classList.add('__data__grid__table__cell');
    }, []);

    return (
      <div
        id="all-hc-data-grid"
        className="__font__family__regular __base__layout__container __data__grid__container"
      >
        {hasSelected > 0 && (
          <MDAlert color="light" className="__data__grid__alert">
            <h4 className="__font__size__14 __font__family__regular __mb__0 __mr__15">
              {`Selected ${selectedRowKeys.length} ${
                selectedRowKeys.length === 1 ? 'item' : 'items'
              }`}
            </h4>
            <div style={{ height: '30px', borderLeft: '2px solid #999', margin: '0 10px' }} />
            <div className="__alert__button__container">
              {permissions?.canApprove && selectedRowKeys.some((e) => e.needApproval) && (
                <div className="__alert__button">
                  <DEButton
                    className="__font__family__regular __default__button__layout __font__size__12 __btn__green"
                    stylingMode={'contained'}
                    type={'success'}
                    text={'Approve'}
                    onClick={handleBulkApprove}
                  />
                </div>
              )}
              <div className="__alert__button">
                <ActionDropdown
                  permissions={permissions}
                  selectedRowKeys={selectedRowKeys}
                  transactionType={transactionType}
                  handleBulkReject={handleBulkReject}
                  handleBulkRollback={handleBulkRollback}
                  handleBulkHold={handleBulkHold}
                  handleBulkCancel={bulkCancelGroupRequest}
                  setPopupVisibility={setPopupVisibility}
                  callback={getAllHeadCounts}
                />
              </div>
            </div>
          </MDAlert>
        )}

        {isPopupVisible && (
          <CloneHeadCountPopup
            columns={columns}
            headcounts={selectedRowKeys}
            routeKey={routeKey}
            dropDownData={dropDownData}
            getHcByIdDetails={_.map(selectedRowKeys, 'id')}
            pagesAndPermissions={pagesAndPermissions}
            dataAccess={dataAccess}
            setPopupVisibility={setPopupVisibility}
            isPopupVisible={isPopupVisible}
          />
        )}
        {!isLoading ? (
          <DataGrid
            className="__font__family__regular __data__grid"
            onCellPrepared={onCellPrepared}
            // onToolbarPreparing={onToolbarPreparing}
            onRowPrepared={onRowPrepared}
            /* columnMinWidth={150} */
            allowColumnResizing={true}
            dataSource={dataSource}
            keyExpr="id"
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            //rowAlternationEnabled={true}
            // disabled={isLoading}
            /* columnAutoWidth={autoWidth} */
            onSelectionChanged={onSelectionChanged}
            ref={dataGridRef}
            onCellClick={(e) => {
              if (e?.column?.dataField === "cStatus")
              {
                const grid = e.component;
                const rowKey = e.key;
                if (grid.isRowExpanded(rowKey)) {
                  grid.collapseRow(rowKey);
                } else {
                  grid.expandRow(rowKey);
                }
              }
            }}
            onRowExpanding={(e) => handleRowExpandCollapse(e)}
            onRowCollapsing={(e) => {
              //e?.component?.collapseRow(e?.key);
              setOnRowExpand(false);
              // setAutoWidth(true)
            }}
          >
            <Selection
              allowSelectAll={true}
              mode="multiple"
              selectAllMode={'page'}
              showCheckBoxesMode={'always'}
            />
            <LoadPanel enabled={false} visible={false} />
            <HeaderFilter visible={true} allowSearch={true} />
            {/* <SearchPanel visible={true} /> */}
            <Paging defaultPageSize={25} />
            <Pager
              visible={true}
              showNavigationButtons={true}
              showInfo={true}
              displayMode={'full'}
            />
            {/* <Export enabled={true} allowExportSelectedData={true} /> */}
            <MasterDetail
              style={{ color: 'red' }}
              autoExpandAll={false}
              enabled={true}
              component={(props) => {
                try {
                  return (
                    <CustomMasterDetail
                      props={props}
                      columns={columns}
                      dropDownData={dropDownData}
                      headcountDetails={props?.data?.data?.data ?? headcountDetails}
                      routeKey={routeKey}
                      permissions={permissions}
                      apiCallBack={apiCallBack}
                      onRowExpand={onRowExpand}
                      dataSource={dataSource}
                      setDataSource={setDataSource}
                      dgInstance={dataGridRef?.current?.instance}
                      canApproveReject={
                        !selectedRowKeys.filter((sr) => sr.id === props.data.data.id).length
                      }
                      selectedRowKeys={selectedRowKeys}
                      setSelectedRowKeys={setSelectedRowKeys}
                      pagesAndPermissions={pagesAndPermissions}
                    />
                  );
                } catch (e) {}
              }}
            />
            {dataColumns?.length > 0 ? dataColumns.map((d) => renderField(d, dropDownData)) : null}
          </DataGrid>
        ) : (
          <Skelton />
        )}
      </div>
    );
  }
);
AllHcDataGrid.displayName = 'AllHcDataGrid';
export default AllHcDataGrid;
