import React from 'react';
import Loadable from 'react-loadable';
import Skelton from '../../components/Skelton/defaultSkelton';
const WelcomeUserInfo = Loadable({
  loader: () => import('../../components/WelcomeUserInfo'),
  loading: () => <Skelton />
});
const BlackSectionController = Loadable({
  loader: () => import('../../components/BlackSectionController'),
  loading: () => <Skelton />
});
const QuickLinks = Loadable({
  loader: () => import('../../components/QuickLinks'),
  loading: () => <></>
});
const MyTasks = Loadable({
  loader: () => import('../../components/MyTasks'),
  loading: () => <></>
});
const RecentActivity = Loadable({
  loader: () => import('../../components/RecentActivity'),
  loading: () => <></>
});
import './styles.scss';
import './responsive-styles.scss';

function Home({ routeKey, pageName }) {
  return (
    <div className="home__container __body__section">
      {/* --------it has two flex based columns--------- */}
      {/* black section */}
      <BlackSectionController>
        <WelcomeUserInfo routeKey={routeKey} />
      </BlackSectionController>
      {/* second section with having quick link and my tasks */}
      <div className="__home__body__div__">
        <QuickLinks />
        <span className="__straight__line__controller"></span>
        <div className="__content__div__">
          <MyTasks routeKey={routeKey} />
          <RecentActivity routeKey={routeKey} />
        </div>
      </div>
    </div>
  );
}

export default Home;
