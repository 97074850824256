import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {
  CustomColorPicker,
  CustomDateBox,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea,
} from "components/CustomDataGridComponents";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { SET_LIGHTS } from "utils/context/store/Constants";
import { Context } from "utils/context/store/Store";
import { cloneIconClick, onRowExpanding } from "utils/services/DatagridHelpers";
import { DEButton, createSanitizeAsyncRule, hasDuplicates } from "../../../utils/services/Helpers";
import BulkUploaderModal from "components/Modal/BulkUploader/BulkUploaderModal";

export default function ManageCVLightTypeDataGrid({
                                           rows,
                                           columns,
                                           dropDownData,
                                           defaultKey = "id",
                                           isLoading,
                                           permissions,
                                           allowAdding = true,
                                           allowUpdating = true,
                                           allowSelection = false,
                                           allowDeleting = true,
                                           postData,
                                           handleDelete,
                                           bulkUploadApi,
                                           uploadTemplateLink,
                                           orgStructureLink,
                                           apiCallback,
                                           tableName,
                                           allowBulkUploading = true, setTypeState,typeRecordSate

                                         }) {
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [autoWidth, setAutoWidth] = useState(true);
  const dataGridRef = useRef();
  const [addEditMode, setAddEditMode] = useState(false);
  const [hasValue, setHasValue] = useState(0);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false)
  const [{ lights }, dispatch] = useContext(Context);
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);
    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  useEffect[() => { }, [hasValue]]

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData);
    const final = selectedRowsData.filter((val) => val.count > 0);
    setHasValue(final.length);
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0;

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return (
          <Column
            editorOptions={{ dropDownOptions: { width: "auto" } }}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            setCellValue={function (rowData, value) {
              if (col.hasOwnProperty("bindedTo")) rowData[col.bindedTo] = null;
              this.defaultSetCellValue(rowData, value);
            }}
          >
            {col.required ? <RequiredRule /> : null}
            <Lookup
              allowClearing
              dataSource={
                dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                  ? dropDownData[col.dataIndex]
                  : []
              }
              displayExpr="label"
              valueExpr="id"
            />
          </Column>
        );
      } else if (col.hasOwnProperty("filtrationKey")) {
        return (
          <Column
            editorOptions={{ dropDownOptions: { width: "auto" } }}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}
          >
            <Lookup
              allowClearing
              dataSource={(options) => {
                return {
                  store:
                    dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                      ? dropDownData[col.dataIndex]
                      : [],
                  filter: options.data
                    ? [col.filtrationKey, "=", options.data[col.filtrationKey]]
                    : null,
                };
              }}
              displayExpr="label"
              valueExpr="id"
            />
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      }
    } else if (col.type === "multi-select") {
      return (
        <Column
          width={"250"}
          editorOptions={{ dropDownOptions: { width: "auto" } }}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          editCellComponent={CustomDTagThisComp}
          cellTemplate={(container, options) => {
            const noBreakSpace = "\u00A0";
            const text = (options.value || [])
              .map((element) => options.column.lookup.calculateCellValue(element))
              .join(", ");
            container.textContent = text || noBreakSpace;
            container.title = text;
          }}
          calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
            if (target === "search" && typeof filterValue === "string") {
              return [col.dataIndex, "contains", filterValue];
            }
            return function (data) {
              return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
            };
          }}
        >
          <Lookup
            allowClearing
            dataSource={
              dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                ? dropDownData[col.dataIndex]
                : null
            }
            displayExpr="label"
            valueExpr="id"
          />
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    } else if (col.type === "checkbox" || col.type === "toggle") {
      return (
        <Column
          dataType="boolean"         
          showEditorAlways
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value);
          }}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    } else if (col.type === "actions") {
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          type="buttons"
          dataField={col.dataIndex}
          caption={col.title}
          fixed={false}
          width={"auto"}
        >
          <Button
            name="delete"
            icon={"trash"}
            visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true}
          />
          <Button
            hint="Clone"
            icon="copy"
            visible={(e) => permissions && permissions.canCreate}
            onClick={(e) => cloneIconClick(e, dataSource, setDataSource)}
          />
        </Column>
      );
    } else if (col.type === "date") {
      return (
        <Column
          dataType={"date"}
          editCellComponent={CustomDateBox}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          format={'dd-MM-yyyy'}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    } else if (col.type === "int") {
      return (
        <Column
          dataType={col.type}
          /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          editCellComponent={(props) => (
            <CustomNumberBox props={props.data} canEdit={col.editable} />
          )}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    } else if (col.type === "file") {
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          type={"buttons"}
          fixed={false}
          dataField={col.dataIndex}
          caption={col.title}
          editCellComponent={CustomFileInput}
        />
      );
    } else if (col.type === "textarea") {
      return (
        <Column
          encodeHtml={col.encodeHtml}
          editCellComponent={CustomTextArea}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
          <AsyncRule {...sanitizeAsyncRule} />
        </Column>
      );
    } else if (col.type === "color-picker") {
      return (
        <Column
          showEditorAlways
          dataType={col.type}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          width={200}
          caption={col.title}
          editCellComponent={(props) => {
            return <CustomColorPicker data={props} canEdit={col.editable} />;
          }}
          //   editCellComponent={(props) => <CustomColorPicker props={props.data} setValue={setValue} canEdit={col.editable} />}
          //
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    } else if (col.type === "string"){
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
          <AsyncRule {...sanitizeAsyncRule} />
        </Column>
      );
    } else {
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    window.scrollTo(0, 0);
    e.data.newRow = true;
    e.data.isLightMandatory = true;
    e.data.status = true;
    setAutoWidth(false);
    setAddEditMode(true);
  };

  /**
   * function use to call the api to post data
   **/
  const pushData = async () => {
    let newData = [...dataSource];
    const hasCode = dataColumns.some((obj) => obj.dataIndex === "code");
    const hasName = dataColumns.some((obj) => obj.dataIndex === "name");
    const hasColor = dataColumns.some((obj) => obj.dataIndex === "color");

    if (hasCode && hasDuplicates(newData, "code")) {
      toast.error(`Duplicate entry found for CODE. It cannot be duplicated!`);
    } else if (hasName && hasDuplicates(newData, "name")) {
      toast.error(`Duplicate entry found for NAME. It cannot be duplicated!`);
    } else if (hasColor && hasDuplicates(newData, "color")) {
      toast.error(`Duplicate entry found for COLOR. It cannot be duplicated!`);
    } else {
      await postData(dataSource);
      setHasDataChanged(false);
    }
  };

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      // {
      //   location: "after",
      //   widget: "dxButton",
      //   options: {
      //     icon: "upload",
      //     text: "BULK UPLOAD",
      //     visible: permissions && permissions.canCreate && allowBulkUploading,
      //     onClick: function () { setBulkUploadModalVisible(true) },
      //   }
      // },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged,
          visible: permissions && permissions.canCreate && (allowAdding || showButton),
          onClick: function () {
            pushData();
          },
        },
      });
  }

  /**
   * @param e
   * Manage post api call to save data and validation for if any request is missing headcount while having replacement true
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      if (e.changes[0].type === 'remove') {
        const dsCopy = [...dataSource];
        const filteredDs = dsCopy.length ? dsCopy.filter((ds) => ds.id !== e.changes[0].key) : [];
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs);
          setHasDataChanged(true);
        } else {
          setDataSource([]);
          setHasDataChanged(false);
        }
      } else {
        if (e && e.changes[0].type === 'update') e.changes[0]['data']['rowEdited'] = true;
        const changedData = e.changes[0].data;
        let finalData = [];
        finalData.push(changedData);
        let result = [];
        if (dataSource && dataSource.length) {
          if (changedData.newRow) {
            result = _.unionBy(finalData, dataSource);
          } else {
            result = _.unionBy(dataSource, finalData);
          }
        } else result.push(changedData);

        setDataSource(result);
        setHasDataChanged(true);
      }
    }
    setAutoWidth(true);
    setAddEditMode(false);
  }
  /**
   * @param e
   * validate row before saving
   **/
  function onRowValidating(e) {
    if (e && e.isValid) {
      if (e.newData) {
        const { name } = e.newData;
        if (name) {
          const d = [...dataSource];
          if (name && d.length) {
            const c = d.some((r) => r.name === name);
            if (c) {
              e.isValid = false;
              e.errorText = "Duplicate Combination Found";
            }
          }
        }
      }
    }
  }

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    if (hasValue > 0) {
      toast.error(`${hasValue} records cannot be deleted as it exists in a relationship!`);
    } else {
      // const result = [...selectedRowKeys];
      const msg = "You won't be able to revert this!";

      Swal.fire({
        title: "Are you sure?",
        text: msg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        heightAuto: false,
        height: "200px",
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      }).then(async (res) => {
        const newRecords = dataSource.filter((ds) => ds.hasOwnProperty("newRow"));
        let newData = [...dataSource];
        if (res.isConfirmed) {
          const deleteFromApi = selectedRowKeys.filter(
            (a) => a.hasOwnProperty("id") && !a.hasOwnProperty("newRow")
          );
          const deleteFromTable = selectedRowKeys.filter((a) => a.hasOwnProperty("newRow"));
          const result = deleteFromApi.map((a) => a.id);

          if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
            if (selectedRowKeys.length === dataSource.length) {
              await handleDelete(result);
            } else {
              await handleDelete(result);
            }
          } else if (deleteFromApi && deleteFromApi.length > 0) {
            setTypeState({ type: SET_LIGHTS, payload: {} });
            typeRecordSate.rows = newRecords;
            typeRecordSate.apiDelete = true;
            setTypeState({ type: SET_LIGHTS, payload: typeRecordSate });

            if (selectedRowKeys.length === dataSource.length) {
              await handleDelete(result);
            } else {
              await handleDelete(result);
            }
          } else if (deleteFromTable && deleteFromTable.length > 0) {
            deleteFromTable.map((a) => {
              newData = newData.filter((item) => item.id !== a.id);
            });
            setDataSource(newData);
            setHasDataChanged(true);
          }
          setHasDataChanged(false);
          setSelectedRowKeys([]);
        }
        setHasDataChanged(false);
        setSelectedRowKeys([]);
      });
    }
  }

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="data-grid-demo">
          {hasSelected > 0 ? (
            <React.Fragment>
              <br />
              <MDAlert color="light">
                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"
                  }`}
                </MDTypography>

                <Divider orientation="vertical" color="dark" flexItem />

                <MDBox>
                  <Grid container spacing={2}>
                    {permissions && permissions.canDelete ? (
                      <Grid item>
                        <Button icon="trash" />
                        <DEButton
                          stylingMode={"contained"}
                          type={"danger"}
                          icon="trash"
                          onClick={() => manageDelete()}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </MDBox>
              </MDAlert>
            </React.Fragment>
          ) : null}
          <BulkUploaderModal title={"Traffic Lights - Bulk Upload"} isModalVisible={bulkUploadModalVisible} setIsModalVisible={setBulkUploadModalVisible} bulkUploadApi={bulkUploadApi} apiCallback={apiCallback} tableName={tableName} downloadLink={uploadTemplateLink} orgStructureLink={orgStructureLink} />
          <DataGrid
            id="grid"
            onToolbarPreparing={onToolbarPreparing}
            showBorders
            onRowExpanding={onRowExpanding}
            columnAutoWidth
            onSaved={onSave}
            showColumnLines
            showRowLines
            rowAlternationEnabled
            ref={dataGridRef}
            onInitNewRow={onInitNewRow}
            onSelectionChanged={onSelectionChanged}
            allowColumnResizing
            disabled={isLoading}
            dataSource={dataSource}
            key={defaultKey ?? "id"}
            keyExpr={defaultKey ?? "id"}
            onRowValidating={onRowValidating}
          >
            {addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />}

            {allowSelection ? (
              <Selection
                allowSelectAll
                mode="multiple"
                selectAllMode={"page"}
                showCheckBoxesMode={"always"}
              />
            ) : null}
            <HeaderFilter visible allowSearch />
            <SearchPanel visible />
            <Paging defaultPageSize={25} />
            <Pager
              visible
              showNavigationButtons
              showInfo
              displayMode={"full"}
            />
            <Export enabled allowExportSelectedData />
            <Editing
              newRowPosition={"first"}
              refreshMode={"repaint"}
              mode="cell"
              allowUpdating={permissions && permissions.canCreate && allowUpdating}
              allowAdding={permissions && permissions.canCreate && allowAdding}
              allowDeleting={allowDeleting}
            />
            {dataColumns && dataColumns.length
              ? dataColumns.map((d, i) => renderField(d, dropDownData))
              : null}
          </DataGrid>
        </div>
      );
    } catch (e) { }
  }, [
    dataSource,
    dataColumns,
    dropDownData,
    hasDataChanged,
    selectedRowKeys,
    isLoading,
    autoWidth,
    addEditMode,
    hasValue,
    bulkUploadModalVisible
  ]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker
        setIsDataChanged={setHasDataChanged}
        isDataChanged={hasDataChanged}
      />
      {Comp}
    </React.Fragment>
  );
}
