import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DataGrid, {
  Column,
  Button,
  Export,
  SearchPanel,
  HeaderFilter,
  Paging,
  Pager,
  LoadPanel,
  Scrolling
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import { convertToLocalDateTime, DEButton, getUserInfo } from '../../utils/services/Helpers';
import useApprovals from '../../utils/hooks/useApprovals';
import useManageHeadCounts from '../../utils/hooks/useManageHeadCounts';
import Skelton from '../../components/Skelton/defaultSkelton';

export default function GroupsDataGrid({
  rows,
  columns,
  isLoading,
  routeKey,
  permissions,
  hasMore,
  setTab,
  setrIdToFilter,
  setPage,
  transactionType = 'hc_transaction',
  fetchDataFromApi = null
}) {
  const navigate = useNavigate();

  const { withdrawGroup, cancelGroup } = useApprovals();
  const { getAllGroups } = useManageHeadCounts(routeKey);

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);

  useEffect(() => {
    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col) {
    if (col.type === 'actions') {
      return (
        <Column
          key={`manage-hc-group-render-field-${col.dataIndex}`}
          type="buttons"
          dataField={col.dataIndex}
          caption={col.title}
          width={'250'}
        >
          <Button
            disabled={(e) =>
              e && e.row && e.row.data.hasOwnProperty('cancelled') && e.row.data.cancelled === true
            }
            text={'View'}
            name={'view'}
            component={(props) =>
              props?.data?.data?.gStatus?.toLowerCase() === 'draft' ? (
                <Link
                  to={
                    transactionType === 'transaction_transfer'
                      ? `/views/group/${props.data.data.id}/manage-supervisor`
                      : `/views/group/${props.data.data.id}/request/${
                          props.data.data.masterRequestTypeId
                        }/type/${
                          props.data.data.masterHeadcountTypeId ?? 'leaver'
                        }/manage-headcount`
                  }
                >
                  <DEButton
                    className="__font__family__regular __default__button__layout"
                    stylingMode={'contained'}
                    type={'normal'}
                    text={'View'}
                  />
                </Link>
              ) : (
                <Link
                  to={'#'}
                  onContextMenu={(e) => {
                    localStorage.setItem('rId', props.data.data.id);
                    localStorage.setItem(
                      'transactionType',
                      transactionType === 'transaction_transfer'
                        ? 'transaction_transfer'
                        : props.data.data.masterHeadcountTypeId
                        ? 'hc_transaction'
                        : 'transaction_leaver'
                    );
                    if (props.data.data.delegatedUserId !== null)
                      localStorage.setItem('dgId', props.data.data.delegatedUserId);
                  }}
                  onClick={(e) => {
                    e.preventDefault();

                    setTab(
                      transactionType === 'transaction_transfer'
                        ? 0
                        : props.data.data.masterHeadcountTypeId
                        ? 0
                        : 1
                    );
                    setPage(false);
                    setrIdToFilter(props.data.data.id);
                  }}
                >
                  <DEButton
                    className="__font__family__regular __default__button__layout"
                    stylingMode={'contained'}
                    type={'normal'}
                    text={'View'}
                  />
                </Link>
              )
            }
          />

          <Button
            visible={(e) =>
              e &&
              e.row &&
              e.row.data &&
              e.row.data.userId === getUserInfo().id &&
              permissions &&
              permissions.canCreate &&
              e.row.data.canWithdraw
            }
            text={'withdraw'}
            name={'withdraw'}
            component={(props) => (
              <Link to={`#`}>
                <DEButton
                  className="__font__family__regular __default__button__layout"
                  stylingMode={'contained'}
                  type={'danger'}
                  text={`${
                    props.data.data.gStatus === 'draft' ? 'Delete Request' : 'Drop Request'
                  }`}
                  onClick={async () =>
                    await withdrawGroup(
                      props.data.data,
                      fetchDataFromApi,
                      props.data.data.gStatus === 'draft' ? 'delete' : 'drop',
                      transactionType
                    )
                  }
                />
              </Link>
            )}
          />
        </Column>
      );
    } else if (col.type === 'date') {
      return (
        <Column
          key={`manage-hc-group-render-field-${col.dataIndex}`}
          dataField={col.dataIndex}
          cellComponent={(props) => <>{convertToLocalDateTime(props.data.data.createdAt)}</>}
          caption={col.title}
          alignment={'left'}
        />
      );
    } else {
      return (
        <Column
          key={`manage-hc-group-render-field-${col.dataIndex}`}
          dataField={col.dataIndex}
          caption={col.title}
          encodeHtml={col.hasOwnProperty('encodeHtml') ? col.encodeHtml : true}
          alignment={'left'}
        />
      );
    }
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      visible: permissions && permissions.canCreate,
      options: {
        icon: 'add',
        onClick: () =>
          navigate(
            transactionType === 'transaction_transfer'
              ? '/views/manage-supervisor'
              : '/views/manage-headcount'
          )
      }
    });
  }

  // when table each cell is ready then this
  // function will be called and apply custom classes to manage styling
  const onCellPrepared = useCallback((e) => {
    if (e.rowType === 'header') {
      onHeaderCellRender(e.cellElement);
    } else if (e.rowType === 'data') {
      onCellRender(e.cellElement);
    }
  }, []);

  // apply class to headers
  const onHeaderCellRender = useCallback((headerCell) => {
    headerCell.classList.add('__data__grid__table__header');
  }, []);

  // apply class to body
  const onCellRender = useCallback((cell) => {
    cell.classList.add('__data__grid__table__cell');
  }, []);

  return (
    <div
      id="GroupsDataGrid"
      className="__font__family__regular __base__layout__container __data__grid__container"
    >
      {!isLoading ? (
        <DataGrid
          className="__font__family__regular __data__grid"
          onCellPrepared={onCellPrepared}
          // onToolbarPreparing={onToolbarPreparing}
          allowColumnResizing={true}
          dataSource={dataSource}
          keyExpr="id"
          showBorders={true}
        >
          <LoadPanel enabled={false} visible={false} />
          <Scrolling showScrollbar="always" mode="standard" />
          <HeaderFilter visible={true} allowSearch={true} />
          {/* <SearchPanel visible={true} /> */}
          <Paging defaultPageSize={25} />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={'full'} />
          {dataColumns && dataColumns.length
            ? dataColumns.map((d) => {
                return renderField(d);
              })
            : null}
          {/* <Export enabled={true} allowExportSelectedData={true} /> */}
        </DataGrid>
      ) : (
        <Skelton />
      )}
    </div>
  );
}
