import { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Context } from 'utils/context/store/Store';
import { SHOW_NOTIFICATIONS_MODAL, SHOW_PROFILE_MODAL, SET_IS_PROFILE_PICTURE } from 'utils/context/store/Constants';
import { getUserInfo } from '../../../utils/services/Helpers';
import Notifications from 'components/Modal/Notifications';
import Profile from 'components/Modal/Profile';

const RightNavigation = ({ setIsHeaderNavOpen = () => {}, pathname }) => {
  const [
    { isNotificationModalShown, isProfileModalShown, notifications, isProfilePictureUpdated },
    dispatch
  ] = useContext(Context);

  useEffect(() => {
    if (isProfilePictureUpdated) {
      dispatch({
        type: SET_IS_PROFILE_PICTURE,
        payload: false
      });
    }
  }, [isProfilePictureUpdated]);

  const handleOnClick = (e, type) => {
    e.preventDefault();

    setIsHeaderNavOpen(false);
    switch (type) {
      case 'notification':
        dispatch({
          type: SHOW_NOTIFICATIONS_MODAL,
          payload: !isNotificationModalShown
        });
        break;
      case 'profile':
        dispatch({
          type: SHOW_PROFILE_MODAL,
          payload: !isProfileModalShown
        });
        break;
      default:
        break;
    }
  };

  const getAvatar = () => {
    const { avatar } = getUserInfo() || {};

    return avatar;
  };

  return (
    <div className="nav__menu__right" id="nav-menu">
      <ul className="nav__list">
        <li className="nav__item">
          <NavLink to="/views/settings" className="nav__link">
            <i className="dx-icon-preferences" />
          </NavLink>
        </li>
        <li
          className={`nav__item __notification ${notifications.length > 0 ? `notification` : ''}`}
          onClick={(e) => handleOnClick(e, 'notification')}
        >
          <NavLink
            to="/notificaion"
            className="nav__link"
            onClick={(e) => handleOnClick(e, 'notification')}
          >
            <i className="dx-icon-bell" />
          </NavLink>
        </li>

        <li className="nav__item profile__modal" onClick={(e) => handleOnClick(e, 'profile')}>
          <NavLink
            to="/business"
            className="nav__link"
            onClick={(e) => handleOnClick(e, 'profile')}
          >
            {getAvatar() ? (
              <img className="__profile__icon__" src={getAvatar()} alt="profile" />
            ) : (
              <i className="dx-icon-user" />
            )}
          </NavLink>
        </li>
      </ul>
      {isNotificationModalShown && (
        <div className="div__notification__container">
          <Notifications routeKey={pathname} />
        </div>
      )}
      {isProfileModalShown && (
        <div className="div__profile__container">
          <Profile routeKey={pathname} />
        </div>
      )}
    </div>
  );
};

export default RightNavigation;
