import { useContext } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING } from "../context/store/Constants";
import request from "../services/Http";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { generateFileData, showDialogBox } from "utils/services/Helpers";
import useHeadcountRequest from "./useHeadcountRequest";
import { useIsLoading } from "./useIsLoading";
import useManageHeadCounts from "./useManageHeadCounts";

export default function useApprovals() {

  const [{ }, dispatch] = useContext(Context)
  const { isLoading, setIsLoading } = useIsLoading()
  const navigate = useNavigate()
  const { handleFileUpload } = useHeadcountRequest()
  const search = useLocation().search;
  const dgId = new URLSearchParams(search).get("dg");
  const { getAllHeadCounts } = useManageHeadCounts()

  async function withdrawGroup(data, callback, actionType = null, transactionType) {
    let popupTitle = "Drop Request",
      buttonText = "Yes, Drop"
    if (actionType !== null && actionType === "delete") {
      popupTitle = "Delete Request"
      buttonText = "Yes, Delete"
    }
    Swal.fire({
      title: popupTitle,
      text: "You will not be able to revert this",
      showCancelButton: true,
      confirmButtonText: buttonText,
      confirmButtonColor: 'red',
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
          request.post(`${transactionType === 'transaction_transfer' ? 'emp-transfer/' : ''}group/withdraw/${data?.id}`, data)
            .then(async (res) => {
              if (res) {
                await callback()
                toast.success(res.data.data)
              }
            })
        }
        catch (e) { }
        finally {
          setIsLoading(false)
          dispatch({ type: SET_IS_LOADING, payload: false })
        }
      }
    })
  }

  async function cancelGroupRequest(transactionId, transactionType, replacementRequired = false, cancelReplacements = false, callback = null) {

    if (transactionType === "transaction_leaver" && replacementRequired) {
      Swal.fire({
        title: "Cancel Request",
        text: "You're about to cancel your request. Please select one of the following options",
        input: "textarea",
        showCancelButton: false,
        showCloseButton: true,
        confirmButtonText: "Yes, Cancel Entire Request",
        confirmButtonColor: 'red',
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: 'Yes, Cancel Replacement',
        returnInputValueOnDeny: true,
        denyButtonColor: '#16ABE0',
        showLoaderOnConfirm: true, // Show loading spinner on confirm button
        inputValidator: (value) => {
          return !value && 'Please enter reason!'
        },
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true)
            dispatch({ type: SET_IS_LOADING, payload: true })
            try {
              const res = await request.post(`group/cancel/${transactionId}/request/${transactionType}`, { comments: result.value })
              if (res) {
                toast.success(res.data.data)
              }
              if (callback) await callback()
              // navigate('/views/manage-headcounts')
            } catch (e) { }
            finally {
              setIsLoading(false)
              dispatch({ type: SET_IS_LOADING, payload: false })
            }
          }
          if (result.isDenied) {
            setIsLoading(true)
            dispatch({ type: SET_IS_LOADING, payload: true })
            try {
              const res = await request.post(`group/cancel/${transactionId}/request/${transactionType}?cancelReplacements=true`, { comments: result.value })
              if (res) {
                toast.success(res.data.data)
              }
              if (callback) await callback()
              // navigate('/views/manage-headcounts')
            }
            catch (e) { }
            finally {
              setIsLoading(false)
              dispatch({ type: SET_IS_LOADING, payload: false })
            }
          }

        });
    }
    else {
      Swal.fire({
        title: "Cancel Request",
        text: "You will not be able to revert this",
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Yes, Cancel",
        confirmButtonColor: 'red',
        inputValidator: (value) => {
          return !value && 'Please enter reason!'
        },
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true)
            dispatch({ type: SET_IS_LOADING, payload: true })
            try {
              const res = await request.post(`group/cancel/${transactionId}/request/${transactionType}`, { comments: result.value })
              if (res) {
                toast.success(res.data.data)
              }
              if (callback) await callback()
              // navigate('/views/manage-headcounts')
            } catch (e) { }
            finally {
              setIsLoading(false)
              dispatch({ type: SET_IS_LOADING, payload: false })
            }
          }
        })
    }
  }
  async function cancelRequestBeforeApproval(transactionId, transactionType, cancelRequestType, callback = null) {

    Swal.fire({
      title: "Cancel Request",
      text: "You will not be able to revert this",
      // input: "textarea",
      showCancelButton: true,
      confirmButtonText: "Yes, Cancel Request",
      confirmButtonColor: 'red',
      // inputValidator: (value) => {
      //   return !value && 'Please enter reason!'
      // },
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      }
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true)
          dispatch({ type: SET_IS_LOADING, payload: true })
          try {
            const res = await request.post(`manage-transaction/cancel`, { transactionId, transactionType, cancelRequestType /*comments: result.value*/ })
            if (res)
            {
              toast.success(res.data.data)
              if (callback) await callback()
            }

            // navigate('/views/manage-headcounts')
          }
          catch (e) { }
          finally {
            setIsLoading(false)
            dispatch({ type: SET_IS_LOADING, payload: false })
          }
        }
      })
  }

  async function bulkCancelGroupRequest(transactionIds, transactionType, replacementRequired = false, cancelReplacements = false, callback = null) {

    if (transactionType === "transaction_leaver" && replacementRequired) {
      Swal.fire({
        title: `Cancel ${transactionIds.length > 1 ? 'Requests' : 'Request'}`,
        text: "You're about to cancel your request. Please select one of the following options",
        input: "textarea",
        showCancelButton: false,
        showCloseButton: true,
        confirmButtonText: "Yes, Cancel Entire Request",
        confirmButtonColor: 'red',
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: 'Yes, Cancel Replacement',
        returnInputValueOnDeny: true,
        denyButtonColor: '#16ABE0',
        showLoaderOnConfirm: true, // Show loading spinner on confirm button
        inputValidator: (value) => {
          return !value && 'Please enter reason!'
        },
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true)
            dispatch({ type: SET_IS_LOADING, payload: true })
            try {
              const res = await request.post(`group/bulk-cancel/request/${transactionType}`, { transactionIds, comments: result.value })
              if (res) {
                toast.success(res.data.data)
              }
              if (callback) await callback()
              // navigate('/views/manage-headcounts')
            } catch (e) { }
            finally {
              setIsLoading(false)
              dispatch({ type: SET_IS_LOADING, payload: false })
            }
          }
          if (result.isDenied) {
            setIsLoading(true)
            dispatch({ type: SET_IS_LOADING, payload: true })
            try {
              const res = await request.post(`group/bulk-cancel/request/${transactionType}?cancelReplacements=true`, { transactionIds, comments: result.value })
              if (res) {
                toast.success(res.data.data)
              }
              if (callback) await callback()
              // navigate('/views/manage-headcounts')
            }
            catch (e) { }
            finally {
              setIsLoading(false)
              dispatch({ type: SET_IS_LOADING, payload: false })
            }
          }

        });
    }
    else {
      Swal.fire({
        title: `Cancel ${transactionIds.length > 1 ? 'Requests' : 'Request'}`,
        text: "You will not be able to revert this",
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Yes, Cancel",
        confirmButtonColor: 'red',
        inputValidator: (value) => {
          return !value && 'Please enter reason!'
        },
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true)
            dispatch({ type: SET_IS_LOADING, payload: true })
            try {
              const res = await request.post(`group/bulk-cancel/request/${transactionType}`, { transactionIds, comments: result.value })
              if (res) {
                toast.success(res.data.data)
              }
              if (callback) await callback()
              // navigate('/views/manage-headcounts')
            } catch (e) { }
            finally {
              setIsLoading(false)
              dispatch({ type: SET_IS_LOADING, payload: false })
            }
          }
        })
    }
  }

  async function approveRejectTransactions(data, apiCallBack) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const headcountsFormData = new FormData();
      headcountsFormData.append('data', JSON.stringify(data))
      if (data.transactionType === "hc_transaction") {
        let headcountsWithAttachment = data.data.filter(l => l.hasOwnProperty('approver_attachments') && l?.approver_attachments !== null) ?? []
        const { fileDetails, attachments } = await generateFileData(headcountsWithAttachment, data.transactionType, 'approver_attachments')
        headcountsFormData.append('fileDetails', JSON.stringify(fileDetails))

        for (const a of attachments) {
          if (a instanceof File) headcountsFormData.append('attachment', a)
        }
      }

      const res = await request.post(`transaction-approval${dgId ? `?dgId=${dgId}` : ""}`, headcountsFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
      if (res) {
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
        toast.success(res.data.data)
        setTimeout(async () => {
          if (apiCallBack)
            await apiCallBack()
          else navigate('/views/manage-jobs')
        }, 500)
      }
    }
    catch (e) {
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }
  }

  async function bulkApproveRejectTransactions(data, apiCallBack, isAssociativeArray, transactionType, hasBoth = false) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const headcountsFormData = new FormData();

      if (transactionType === "hc_transaction" && data.isAssociativeArray && !hasBoth) {
        let headcountsWithAttachment = data.data.filter(l => l.hasOwnProperty('approver_attachments') && l?.approver_attachments !== null) ?? []
        const { fileDetails, attachments } = await generateFileData(headcountsWithAttachment, data.transactionType, 'approver_attachments')
        headcountsFormData.append('fileDetails', JSON.stringify(fileDetails))

        for (const a of attachments) {
          if (a instanceof File) headcountsFormData.append('attachment', a)
        }
      }
      delete data['hasBoth']
      const body = { ...data, isAssociativeArray, transactionType, hasBoth }
      headcountsFormData.append('data', JSON.stringify(body))


      const res = await request.post(`transaction-approval/approve-reject-bulk${dgId ? `?dgId=${dgId}` : ""}`, headcountsFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
      if (res) {
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
        toast.success(res.data.data)
        setTimeout(async () => {
          if (typeof apiCallBack === "function") await apiCallBack()
          // navigate('/views/manage-headcounts')
        }, 500)
      }
    }
    catch (e) { }
    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function holdReactivateTransaction(data, callback) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const res = await request.post('manage-transaction', data)
      toast.success(res.data.data)
      if (callback) await callback()
    }
    catch (e) { }
    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function bulkHoldReactivateTransaction(data, callback) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const res = await request.post('manage-transaction/bulk-hold', data)
      toast.success(res.data.data)
      if (callback) await callback()
    }
    catch (e) { }
    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  const handleHoldOrReActivateRequest = async (transactionId, operation, transactionType, masterModuleId, isApprover = false, callback = null) => {
    let html = ` <div class="label_wrapper">Reason: </div>
  <textarea id="swal-input1" style="width:75%" class="swal2-textarea"></textarea> <br/>`

    if (operation) {
      html += `<div class="label_wrapper">Reactivation Date: </div><input type="date" name="setTodaysDate" id="swal-input2" style="width:75%" class="swal2-input">`
    }


    Swal.fire({
      title: operation ? "Hold Request" : "Reactivate Request",
      text: "You will not be able to revert this",
      html: html,
      showCancelButton: true,
      confirmButtonText: operation ? 'Yes, Hold' : 'Yes, Reactivate',
      confirmButtonColor: operation ? 'red' : '#00B0A3',
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      },
      preConfirm: function () {
        let value1 = Swal.getPopup().querySelector('#swal-input1').value;
        let value2 = null
        if (operation)
          value2 = Swal.getPopup().querySelector('#swal-input2').value;

        if (value1 === "") {
          // Notify error and keep alert open.
          Swal.showValidationMessage('Reason is Required');
          return false

        } else if (!isApprover && operation && value2 === "") {
          // Notify error and keep alert open.
          if (isApprover)
            return true
          Swal.showValidationMessage('Reactivation Date is required');
          return false

        } else {
          return new Promise(function (resolve) {
            if (operation) {
              if (isApprover)
                resolve([
                  document.getElementById('swal-input1').value,
                  document.getElementById('swal-input2').value,
                ]);
              else
                resolve([
                  document.getElementById('swal-input1').value,
                  document.getElementById('swal-input2').value,
                ]);
            }
            else {
              resolve([
                document.getElementById('swal-input1').value,
              ]);
            }
          });
        }

      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const comments = result.value[0];
        const reactivationDate = result.value.length > 1 ? result.value[1] : isApprover && operation ? document.getElementById('swal-input2').value ?? new Date().toISOString().split("T")[0] : null;
        if (result.value.length > 1 && !isApprover) {
          if (comments === "" || reactivationDate === "") {
            toast.error('please fill all the fields')
          }
          else {
            await holdReactivateTransaction({ isApprover, id: transactionId, operation, transactionType, masterModuleId, comments, reactivationDate: reactivationDate === "" ? null : reactivationDate }, callback)
            // navigate('/views/manage-headcounts')
          }
        }
        else {
          if (comments === "") {
            toast.error('please enter reason')
          }
          else {
            await holdReactivateTransaction({ isApprover, id: transactionId, operation, transactionType, masterModuleId, comments, reactivationDate: reactivationDate === "" ? null : reactivationDate }, callback)
            // navigate('/views/manage-headcounts')
          }
        }
        // email_message = result[2];
      }
    })

    if (operation) {
      const today = new Date().toISOString().split("T")[0];
      document.getElementsByName("setTodaysDate")[0].setAttribute('min', today);
      if (isApprover) document.getElementsByName("setTodaysDate")[0].setAttribute('value', today);
    }
    // callBack([]);
  };

  const handleBulkHoldOrReActivateRequest = async (operation, data, isApprover = false, callback = null) => {
    let html = ` <div class="label_wrapper">Reason: </div>
  <textarea id="swal-input1" style="width:75%" class="swal2-textarea"></textarea> <br/>`

    if (operation) {
      html += `<div class="label_wrapper">Reactivation Date: </div><input type="date" name="setTodaysDate" id="swal-input2" style="width:75%" class="swal2-input">`
    }

    Swal.fire({
      title: operation ? "Hold Requests" : "Reactivate Requests",
      text: "You will not be able to revert this",
      html: html,
      showCancelButton: true,
      confirmButtonText: operation ? 'Yes, Hold' : 'Yes, Reactivate',
      confirmButtonColor: operation ? 'red' : '#00B0A3',
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      },
      preConfirm: function () {
        let value1 = Swal.getPopup().querySelector('#swal-input1').value;
        let value2 = null
        if (operation)
          value2 = Swal.getPopup().querySelector('#swal-input2').value;

        if (value1 === "") {
          // Notify error and keep alert open.
          Swal.showValidationMessage('Reason is Required');
          return false

        }
        else if (!isApprover && operation && value2 === "") {
          // Notify error and keep alert open.
          Swal.showValidationMessage('Reactivation Date is required');
          return false

        }
        else {
          return new Promise(function (resolve) {
            if (operation) {
              if (!isApprover) resolve([
                document.getElementById('swal-input1').value,
                document.getElementById('swal-input2').value,
              ]);
              else resolve([
                document.getElementById('swal-input1').value
              ]);
            }
            else {
              resolve([
                document.getElementById('swal-input1').value,
              ]);
            }
          });
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const comments = result.value[0];
        const reactivationDate = result.value.length > 1 ? result.value[1] : isApprover && operation ? document.getElementById('swal-input2').value ?? new Date().toISOString().split("T")[0] : null;
        if (result.value.length > 1) {
          if (comments === "" || reactivationDate === "") {
            toast.error('please fill all the fields')
          }
          else {
            await bulkHoldReactivateTransaction({ isApprover, operation, comments, reactivationDate, data }, callback)
            // navigate('/views/manage-headcounts')
          }
        }
        else {
          if (comments === "") {
            toast.error('please enter reason')
          }
          else {
            await bulkHoldReactivateTransaction({ isApprover, operation, comments, reactivationDate, data }, callback)
            // navigate('/views/manage-headcounts')
          }
        }
        // email_message = result[2];
      }
    })

    if (operation) {
      const today = new Date().toISOString().split("T")[0];
      document.getElementsByName("setTodaysDate")[0].setAttribute('min', today);
      if (isApprover) document.getElementsByName("setTodaysDate")[0].setAttribute('value', today);
    }
    // callBack([]);
  };

  // approve click button popup
  const bulkRollback = async (data, transactionType, masterModuleId, callBack, apiCallBack, key = 'needApproval') => {

    const isAssociativeArray = Array.isArray(data) && data.every(item => typeof item === 'object');
    const selectedRows = data.filter((obj) => obj[key] === true)
    if (selectedRows.length > 0) {
      Swal.fire({
        title: `Rollback ${masterModuleId === 7 ? 'Supervisor' : 'HC'}!`,
        text: "Please enter your comments:",
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Rollback',
        confirmButtonColor: '#00B0A3',
        inputValidator: (value) => {
          return !value && 'Please enter comments!'
        },
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let rollbackObj = { data: selectedRows, comments: result.value, transactionType, masterModuleId, isAssociativeArray }
          await rollbackTransaction(rollbackObj, apiCallBack)
        }
      })
    } else {
      toast.error("Request cannot be rollbacked!")
    }
    if (callBack !== null)
      callBack([]);
  };

  async function rollbackTransaction(data, apiCallBack) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const res = await request.post(`transaction-approval/bulk-rollback${dgId ? `?dgId=${dgId}` : ""}`, data)
      if (res) {
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
        toast.success(res.data.data)
        setTimeout(async () => {
          if (apiCallBack)
            await apiCallBack()
        }, 500)
      }
    }
    catch (e) {
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }
  }

  // approve click button popup
  const approveOrReject = async (approveReject = true, data, transactionType, masterModuleId, callBack, apiCallBack, key = 'needApproval') => {
    let popupApproveTitle = "Approve JD!";
    let popupRejectTitle = "Reject JD!";
    if (masterModuleId === 3) {
      popupApproveTitle = "Approve HC!";
      popupRejectTitle = "Reject HC!";
    }
    if (masterModuleId === 7) {
      popupApproveTitle = "Approve Supervisor!";
      popupRejectTitle = "Reject Supervisor!";
    }
    const isAssociativeArray = Array.isArray(data) && data.every(item => typeof item === 'object');
    const selectedRows = transactionType === "jd_transaction" ? data.filter((obj) => obj[key] === true) : data.filter((obj) => obj[key] === true && obj.cancelled !== true && obj.actionedByApproverId === null)
    if (selectedRows.length > 0) {
      const type = approveReject ? 'Approve' : 'Reject';
      Swal.fire({
        title: approveReject ? popupApproveTitle : popupRejectTitle,
        text: "Please enter your comments:",
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: type,
        confirmButtonColor: approveReject ? '#00B0A3' : 'red',
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        },
        inputValidator: (value) => {
          return !approveReject && !value && 'Please enter comments!'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let approveRejectObject = {
            actionType: type.toLowerCase(),
            data: selectedRows,
            comments: result.value,
            approveReject,
            transactionType,
            masterModuleId,
            isAssociativeArray
          }
          await approveRejectTransactions(approveRejectObject, apiCallBack)
          /*if (transactionType === "hc_transaction") {
            const attachmentsData = selectedRows.filter(l => l.hasOwnProperty('approver_attachments') && l?.approver_attachments !== null) ?? []
            if (attachmentsData?.length) handleFileUpload(approveRejectObject.data, transactionType, 'approver_attachments').then((res) => apiCallBack())
            else apiCallBack()
          }
          if (transactionType === "jd_transaction" && typeof apiCallBack === "function") await apiCallBack()*/
        }
      })
    } else {
      toast.error("Nothing to Approve or Reject!")
    }
    if (callBack !== null)
      callBack([]);
  };

  // Bulk approve click button popup
  const bulkApproveOrReject = async (approveReject = true, data, transactionType, masterModuleId, callBack, apiCallBack, key = 'needApproval', hasBoth = false, selectedRowKeys = []) => {
    let popupApproveTitle = "Approve JD's!";
    let popupRejectTitle = "Reject JD's!";
    if (masterModuleId === 3) {
      popupApproveTitle = "Approve HC's!";
      popupRejectTitle = "Reject HC's!";
    }
    if (masterModuleId === 7) {
      popupApproveTitle = "Approve Supervisors!";
      popupRejectTitle = "Reject Supervisors!";
    }
    const isAssociativeArray = Array.isArray(data) && data.every(item => typeof item === 'object');
    const filterData = !isAssociativeArray ? selectedRowKeys?.filter(d => data.includes(d.id)) : []
    const selectedRows = hasBoth ? data : isAssociativeArray ? data.filter((obj) => obj[key] === true && obj.cancelled !== true) : data
    if (selectedRows.length > 0) {
      const type = approveReject ? 'Approve' : 'Reject'
      Swal.fire({
        title: approveReject ? popupApproveTitle : popupRejectTitle,
        text: "Please enter your comments:",
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: approveReject ? 'Approve' : 'Reject',
        confirmButtonColor: approveReject ? '#00B0A3' : 'red',
        customClass: {
          container: '__swal__continer__ __font__family',
          confirmButton: '__default__button__layout',
          cancelButton: '__default__button__layout'
        },
        inputValidator: (value) => {
          return !approveReject && !value && 'Please enter comments!'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let approveRejectObject = {
            actionType: type.toLowerCase(),
            comments: result.value,
            approveReject,
            data: selectedRows,
            filterData
          }
          await bulkApproveRejectTransactions(approveRejectObject, () => apiCallBack(transactionType), isAssociativeArray, transactionType, hasBoth)
          /*if (transactionType === "hc_transaction" && isAssociativeArray && !hasBoth) {
            const attachmentsData = selectedRows.filter(l => l.hasOwnProperty('approver_attachments') && l?.approver_attachments !== null) ?? []
            if (attachmentsData?.length) handleFileUpload(approveRejectObject.data, transactionType, 'approver_attachments').then((res) => apiCallBack(transactionType))
            else apiCallBack(transactionType)
          }*/
        }
      })
    } else {
      toast.error("Nothing to Approve or Reject!")
    }
    if (callBack !== null)
      callBack([]);
  };

  // end Freelancer Request popup
  const endFreelancerRequest = async (transactionId, transactionType, masterModuleId, callback = null) => {

    showDialogBox(async () => {
      setIsLoading(true)
      dispatch({ type: SET_IS_LOADING, payload: true })
      try {
        const res = await request.post('manage-transaction/endFreelancerRequest', { id: transactionId, transactionType, masterModuleId })
        toast.success(res.data.data)
        if (callback) await callback()
        // navigate('/views/manage-headcounts')
      }
      catch (e) { }
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }, 'warning', 'Yes, continue', null, 'This action will end the freelancer contract and convert the request back to permanent')
  };

  return { approveRejectTransactions, approveOrReject, bulkApproveOrReject, withdrawGroup, cancelGroupRequest, handleHoldOrReActivateRequest, handleBulkHoldOrReActivateRequest, holdReactivateTransaction, endFreelancerRequest, bulkRollback, bulkCancelGroupRequest, cancelRequestBeforeApproval }
}
