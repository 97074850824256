import * as React from "react";
import DataGrid, {
  AsyncRule, MasterDetail, Column, Editing, Lookup, RowDragging, Export, Paging, Pager, Scrolling, Selection, SearchPanel, HeaderFilter, Button,
  RequiredRule, Form
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { SimpleItem } from "devextreme-react/form";
import { Context } from "../../../utils/context/store/Store";
import { SET_WORKFLOWS } from "../../../utils/context/store/Constants";
import WorkflowStep from "./WorkflowStep";
import { toast } from "react-toastify";
import { CustomTextArea, CustomDTag } from "components/CustomDataGridComponents";
import MDAlert from "components/MDAlert";
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { SET_NEW_WF_STEP } from "utils/context/store/Constants";
import Swal from "sweetalert2";
import { onKeyDown, onRowExpanding, cloneIconClick } from "../../../utils/services/DatagridHelpers";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import { DEButton, createSanitizeAsyncRule } from "utils/services/Helpers";
import BulkUploaderModal from "components/Modal/BulkUploader/BulkUploaderModal";

export default function WfDataGrid({ rows, columns, dropDownData, isLoading, isWorkflows, permissions, workflowStepTableData, isApprovals
  , editWfStep, data, hitApi, fetchFromApi, isAccess, valueToFetch, handleDelete, bulkUploadApi, uploadTemplateLink, orgStructureLink, apiCallback, tableName, allowBulkUploading = false }) {

  const [isDataChanged, setIsDataChanged] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false)
  const [{ workflows, newWfStep }, dispatch] = useContext(Context)
  const dataGridRef = useRef()
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setIsDataChanged(false)
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);

  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => { setDataColumns(columns) }, [columns]);

  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { }, [editWfStep]);
  useEffect(() => { }, [data]);
  useEffect(() => { }, [isDataChanged]);
  useEffect(() => { }, [selectedRowKeys])

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
          if (col.hasOwnProperty("bindedTo"))
            rowData[col.bindedTo] = null;
          this.defaultSetCellValue(rowData, value);
        }}>
          {
            col.required ? <RequiredRule /> : null
          }
          <Lookup allowClearing
            dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : []}
            displayExpr="label" valueExpr="id" />
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        return <Column dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value);
          if (col.hasOwnProperty("bindedTo")) {
            rowData[col.bindedTo] = null;
          }
        }}>
          <Lookup allowClearing dataSource={(options) => {
            const data = {
              store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
              filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
            }
            return data;
          }} displayExpr="label" valueExpr="id" />
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>;
      }
    }
    else if (col.type === "multi-select") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTag}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === 'search' && typeof (filterValue) === 'string') {
            return [col.dataIndex, 'contains', filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
        <Lookup allowClearing dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null} displayExpr="label" valueExpr="id" />
      </Column>;
    }
    else if (col.type === "toggle") {
      return <Column dataType="boolean"        
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "checkbox") {
      return <Column dataType="boolean"
        
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} width={"80"}>
        <Button hint="Delete" name="delete" icon={'trash'} />
        <Button hint="Clone" icon={'copy'} visible={(e) => permissions && permissions.canCreate} onClick={(e) => cloneIconClick(e, dataSource, setDataSource, false, false, false, false, false, true)} />
      </Column>
    }
    else if (col.type === "date") {
      return <Column dataType={"date"} format={'dd-MM-yyyy'} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.type === "button") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} type={"buttons"} dataField={col.dataIndex} caption={col.title}></Column>
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} type={"buttons"} dataField={col.dataIndex} caption={col.title} editCellComponent={CustomFileInput} />
    }
    else if (col.type === "textarea") {
      return <Column editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>    {
        col.required ? <RequiredRule /> : null
      }
      <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string"){
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
  }

  /**
 * @param e
 * initialize new row in the data-grid
 **/
  const onInitNewRow = (e) => {
    e.data.newRow = true
    e.data.status = true
    setIsDataChanged(false)
    let gridInstance = dataGridRef.current.instance;
    gridInstance.expandRow(e => e.component.collapseAll(-1))
  }

  /**
* @param e
* function to perform actions on row click
**/
  const onRowClick = (e) => {
    if (e && e.hasOwnProperty('data')) {
      e['data']['workflowStepTableData'] = workflowStepTableData
    }
  }

  /**
* function use to call the api to post data
**/
  const pushData = async () => {
    let newData = [...dataSource]
    if (isWorkflows) {
      let isEmpty = newData.some(obj => obj.workflow_step.length === 0)
      if (isEmpty) {
        toast.error("Please add a step before submitting the workflow!")
      } else {
        let isFirstStepASubProcess = false
        newData.map(wf => { wf.workflow_step[0].conditionType === "sub-process" ? isFirstStepASubProcess = true : false })
        if (isFirstStepASubProcess) {
          toast.error("First step cannot be a sub-process")
        } else {
          newData = newData.map(({ workflowStepTableData, ...rest }) => ({ ...rest }))
          await hitApi(newData, valueToFetch)
          // await fetchFromApi()
          setIsDataChanged(false)
        }
      }
    } else {
      await hitApi(newData, valueToFetch)
      setIsDataChanged(false)
      // await fetchFromApi(value)
    }
  }

  /**
* @param e
* function use to prepare toolbar
**/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "upload",
          text: "BULK UPLOAD",
          visible: permissions && permissions.canCreate && allowBulkUploading,
          onClick: function () { setBulkUploadModalVisible(true) },
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !isDataChanged,
          onClick: function () { pushData() },
        }
      }
    );
  }

  /**
* @param e
* function to define logic to view/hide the delete icon across each row
**/
  const allowDeleting = (e) => {
    return !e.row.data.hasOwnProperty('id');
  }

  /**
* @param selectedRowKeys
* @param selectedRowsData
* used to get selected rows detail of data-grid
**/
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
  * get selected rows
  **/
  const hasSelected = selectedRowKeys.length > 0

  // bulk delete using row selection
  const manageDelete = () => {

    const result = selectedRowKeys.map(a => a.id);;
    const msg = "You won't be able to revert this!"

    Swal.fire({
      title: 'Are you sure?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, delete it!",
      heightAuto: false,
      height: '200px',
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      }
    }).then(async (res) => {
      const newRecords = dataSource.filter(a => !a.hasOwnProperty('id'))
      let newData = [...dataSource]
      if (res.isConfirmed) {
        const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id'))
        const deleteFromTable = selectedRowKeys.filter(a => !a.hasOwnProperty('id'))

        if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {

          dispatch({ type: SET_NEW_WF_STEP, payload: {} })
          await handleDelete(result, valueToFetch)
        }
        else if (deleteFromApi && deleteFromApi.length > 0) {

          dispatch({ type: SET_NEW_WF_STEP, payload: {} })
          newWfStep.rows = newRecords
          newWfStep.apiDelete = true

          dispatch({ type: SET_NEW_WF_STEP, payload: newWfStep })
          await handleDelete(result, valueToFetch)
        }
        else if (deleteFromTable && deleteFromTable.length > 0) {
          deleteFromTable.map(a => {
            newData = newData.filter((item) => item.key !== a.key);
          })
          setDataSource(newData)
          toast.success("Record Deleted!")
        }
        setSelectedRowKeys([]);
      }
      setSelectedRowKeys([]);
    })
  }

  /**
 * get selected rows
 **/
  function onRowInserted(e) {
    e.component.navigateToRow(e.key);
  }

  /**
* handle save rows
**/
  function onSave(e) {
    if (isWorkflows && e && e.changes && e.changes.length && (e.changes[0].type === "insert" || e.changes[0].type === "update")) {
      e.changes[0]['data']['workflowStepTableData'] = workflowStepTableData
      e.changes[0]['data']['workflow_step'] = workflows && workflows.rows && workflows.rows.length
        && workflows.rows.hasOwnProperty('workflow_step') ? workflows.rows.workflow_step : e.changes[0]['data']['workflow_step'] ?? []
      const data = e.changes[0].data
      let rows = []
      let result = []
      if (workflows.rows.length) {
        result = [data, ...workflows.rows]
        if (e.changes[0].type === "update")
          result = [...workflows.rows, data]

        rows = [...new Map(result.map(item => [item['sequence'], item])).values()];
      }
      else {
        rows.push(data)
      }

      dispatch({
        type: SET_WORKFLOWS,
        payload: { rows: rows, columns: workflows.columns, dropdownValues: dropDownData }
      })
      setIsDataChanged(true)
    } else if (isAccess && e && e.changes && e.changes.length && (e.changes[0].type === "insert" || e.changes[0].type === "update")) {
      const data = e.changes[0].data
      let rows = []
      let result = []
      if (workflows.rows.length) {
        result = [data, ...workflows.rows]
        if (e.changes[0].type === "update")
          result = [...workflows.rows, data]

        rows = [...new Map(result.map(item => [item['sequence'], item])).values()];
      }
      else {
        rows.push(data)
      }

      dispatch({
        type: SET_WORKFLOWS,
        payload: { rows: rows, columns: workflows.columns, dropdownValues: dropDownData }
      })
      setIsDataChanged(true)
    }
  }

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const Comp = useMemo(() => {
    return (
      <>

        {isAccess && selectedRowKeys.length > 0
          ?
          <>
            <br />
            <MDAlert color="light">

              <MDTypography variant="subtitle2">
                {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
              </MDTypography>

              <Divider orientation="vertical" color="dark" flexItem />

              <MDBox>
                <Grid container spacing={2}>
                  {
                    permissions && permissions.canDelete
                      ? <Grid item >
                        <DEButton stylingMode={"contained"} type={"danger"} hint="Delete" icon="trash" onClick={() => manageDelete()} />
                      </Grid>
                      : null
                  }
                </Grid>
              </MDBox>
            </MDAlert>
          </>
          : ""
        }

        <BulkUploaderModal title={"Workflow Access - Bulk Upload"} isModalVisible={bulkUploadModalVisible} setIsModalVisible={setBulkUploadModalVisible} bulkUploadApi={bulkUploadApi} apiCallback={apiCallback} tableName={tableName} downloadLink={uploadTemplateLink} orgStructureLink={orgStructureLink} valueToFetch={valueToFetch} />

        <div id="data-grid-demo">
          <DataGrid
            cacheEnabled={true}
            onSaved={onSave}
            dataSource={dataSource}
            showColumnLines={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            ref={dataGridRef} onInitNewRow={onInitNewRow}
            onSelectionChanged={onSelectionChanged} allowColumnResizing={true} disabled={isLoading} keyExpr="sequence"
            showBorders={true}
            onRowClick={onRowClick}
            onRowExpanding={onRowExpanding}
            onKeyDown={onKeyDown}
            onToolbarPreparing={onToolbarPreparing}
            onRowInserted={onRowInserted} repaintChangesOnly={true}>
            <RowDragging allowReordering={isApprovals ? true : false} />
            <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
            <HeaderFilter visible={true} allowSearch={true} />
            <SearchPanel visible={true} />
            <Paging defaultPageSize={25} />
            <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
            <Scrolling showScrollbar="always" mode="standard" />
            <Export enabled={true} allowExportSelectedData={true} />
            <MasterDetail enabled={!isApprovals && !isAccess} component={(props) => <WorkflowStep data={props.data} updateButtonStateOfParent={setIsDataChanged} permissions={permissions} valueToFetch={valueToFetch} fetchFromApi={fetchFromApi} />} />
            <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="cell" allowUpdating={permissions && permissions.canCreate} allowAdding={permissions && permissions.canCreate} allowDeleting={allowDeleting}>
              <Form>
                <SimpleItem dataField={"name"} visible={true} />
                <SimpleItem dataField={"description"} visible={true} />
                <SimpleItem dataField={"masterModuleId"} visible={true} />
                <SimpleItem dataField={"status"} visible={true} />
              </Form>
            </Editing>
            {
              dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
            }
          </DataGrid>
        </div>
      </>
    )
  }, [dataSource, dataColumns, isDataChanged, dropDownData, isLoading, selectedRowKeys, rows, bulkUploadModalVisible]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setIsDataChanged} isDataChanged={isDataChanged} />
      {Comp}
    </React.Fragment>
  );
}
