import React, { useContext, useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { SelectBox } from 'devextreme-react/select-box';
import { Context } from 'utils/context/store/Store';
import Skelton from '../../components/Skelton/defaultSkelton';
import useValues from '../../utils/hooks/useValues';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import usePagePermissions from '../../utils/hooks/usePagePermissions';
import Select from 'react-select';
import { Grid } from '@mui/material';
import { selectCustomStyles } from '../../utils/services/Helpers';
const ManageValuesDataGrid = Loadable({
  loader: () => import('./DataGrid/ManageValuesDataGrid'),
  loading: () => <Skelton />
});
import './styles.scss';

const options = [
  {
    value: 'generic',
    label: 'Generic'
  },
  {
    value: 'level',
    label: 'Levels'
  }
];

function ManageValues({ routeKey, pageName }) {
  const { getValues, deleteValues, updateOrCreateValues, clearComponentValues, isLoading } = useValues(routeKey);
  const [{ values }, dispatch] = useContext(Context);
  const [value, setValue] = useState(null);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  useEffect(async () => {
    if (value !== null) await getValues(value);

    return () => {
      setValue(null);
      clearComponentValues();
    };
  }, [value]);

  return (
    <div className="__body__section __manage__values__">
      <div className="__selectbox__container__">
        <SelectBox
          placeholder={'Please select an option'}
          dataSource={options}
          showClearButton={true}
          valueExpr="value"
          displayExpr="label"
          onValueChanged={(e) => {
            setValue(e.value);
          }}
          style={{
            borderRadius: '7px',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
        />
      </div>
      {value ? (
        <ManageValuesDataGrid
          loading={isLoading}
          columns={values.columns}
          rows={values.rows}
          postData={updateOrCreateValues}
          handleDelete={deleteValues}
          valueToFetch={value}
          isDeleteable={false}
          defaultKey={'sequence'}
          isForValues={true}
          permissions={getPermissionsForPage(routeKey)}
          allowDeleting={false}
          allowAdding={false}
          allowUpdating={true}
        />
      ) : null}
    </div>
  );
}

export default ManageValues;
