import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, { Column, Editing, Export, Paging, Pager, Scrolling, Selection, SearchPanel, HeaderFilter, RequiredRule, MasterDetail, Button, Form, Texts, AsyncRule } from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { CustomJsonEditor } from "../../../components/CustomDataGridComponents";
import CustomSkelton from "components/Skelton/CustomSkelton";
import MDAlert from "../../../components/MDAlert";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import { addRow, createSanitizeAsyncRule, DEButton, JSONStringify } from "../../../utils/services/Helpers";
import { SimpleItem } from "devextreme-react/form";
import { onEditingStart, onRowExpanding } from "../../../utils/services/DatagridHelpers";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import PagePropertiesDataGrid from "./PagePropertiesDataGrid"
import Swal from "sweetalert2";
const _ = require("lodash");

export default function NavigationDataGrid({ rows, columns, dropDownData, hitApi, isLoading, routeKey, permissions, allowAdding = true, allowSelection = true, allowDeletingFromApi = true, pagePropertyColumns, getNavListingOfParent }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);       // store selected row key
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [onRowExpand, setOnRowExpand] = useState(false)
  const dataGridRef = useRef();
  const [autoWidth, setAutoWidth] = useState(true)
  const [addEditMode, setAddEditMode] = useState(false)
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    rows.map(row => {
      row.route ? row.route = JSONStringify(row.route) : null
    })
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  useEffect(() => {
    if (rows && rows.length <= 0 && columns && columns.length) {
      setTimeout(() => addRow(dataGridRef, onInitNewRow), 200);
    }
  }, [addEditMode, autoWidth]);

  /**
 * @param selectedRowKeys
 * @param selectedRowsData
 * used to get selected rows detail of data-grid
 **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData);
  }

  function renderField(col, dropDownData) {
    if (col.type === "actions") {
      return <Column key={col.dataIndex} alignment={"center"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} width={"auto"}>

        <Button name="edit" icon={'edit'} visible={permissions && permissions.canCreate} />
        <Button name="delete" icon={'trash'}
          visible={(e) => e.visible = !!(e && e.row && e.row.data && e.row.data.newRow && permissions && permissions.canDelete && allowDeletingFromApi)}
          disabled={(e) => e.disabled = !isDataChanged} />
      </Column>
    }
    else if (col.type === "json" && col.dataIndex !== "properties") {
      return <Column key={col.dataIndex} encodeHtml={false} width={"750px"} alignment={"left"} dataType={"json"} editCellComponent={CustomJsonEditor} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.dataIndex === "string") {
      return <Column key={col.dataIndex} alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.dataIndex !== "properties") {
      return <Column key={col.dataIndex} alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    e.data.newRow = true
    setIsDataChanged(false)
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !isDataChanged,
          onClick: async () => {
            // handle API create and update of data-grid
            await hitApi(dataSource)
            setIsDataChanged(false)
          },
        },
      },
    );
  }

  /**
   * @param e
   * Manage post api call to save data
   **/
  const onSave = (e) => {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dataSourceArray = [...dataSource]
        const deleteItemId = e.changes[0].key
        if (deleteItemId) {
          const deleteFromTable = dataSourceArray.length ? dataSourceArray.filter(data => data.id !== deleteItemId) : []
          setDataSource(deleteFromTable)
        }
      }
      else {
        const updatedData = e.changes[0].data;
        let finalResult = []
        if (dataSource && dataSource.length) {
          finalResult = _.unionBy(updatedData, dataSource);
        }
        else { finalResult.push(updatedData) }
        setDataSource(finalResult)
        setIsDataChanged(true)
      }
    }
  }

  // handle API delete of data-grid
  const manageDelete = () => {
    const msg = "You won't be able to revert this!"
    Swal.fire({
      title: 'Are you sure?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, delete it!",
      heightAuto: false,
      height: '200px',
      customClass: {
        container: '__swal__continer__ __font__family',
        confirmButton: '__default__button__layout',
        cancelButton: '__default__button__layout'
      }
    }).then(async (res) => {
      let dataSourceArray = [...dataSource]
      if (res.isConfirmed) {
        const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow'))
        const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))

        if (deleteFromApi && deleteFromApi.length) {
          const deleteResult = deleteFromApi.map(a => a.id);
          await handleDelete(deleteResult)
        }
        else if (deleteFromTable && deleteFromTable.length) {
          deleteFromTable.map(a => {
            dataSourceArray = dataSourceArray.filter((item) => item.id !== a.id);
          })
          setDataSource(dataSourceArray)
        }
        setIsDataChanged(false)
        setSelectedRowKeys([]);
      }
    })
  }

  /**
   * @param e
   * Function to handle row form cancel event
   **/
  function onEditCancelled(e) {
    const hasChanges = dataSource.some(d => d.hasOwnProperty('newRow'))
    setAutoWidth(true)
    setAddEditMode(false)
    setIsDataChanged(hasChanges)
  }

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="data-grid-demo">
          <DataGrid id="grid"
            onRowInserted={(e) => {
              if (e && e.data && e.data.replacementRequired) {
                setAddEditMode(true)
                setAutoWidth(false)
                e?.component?.expandRow(e?.key)
              }
            }}
            onEditCanceled={onEditCancelled} onRowCollapsing={(e) => setAutoWidth(true)}
            onToolbarPreparing={onToolbarPreparing} showBorders={true}
            onEditingStart={(e) => {
              setAddEditMode(true)
              onEditingStart(e, setAutoWidth);
              onRowExpanding(e, false, setAutoWidth)
            }}
            onRowExpanding={(e) => {
              setOnRowExpand(true);
              const d = [...dataSource]
              const newRow = d.some(a => a.id === e.key && a.hasOwnProperty('newRow'))
              onRowExpanding(e, true, newRow === true ? setAutoWidth : null)
              let gridInstance = dataGridRef.current.instance;
              let editRowKey = gridInstance.option("editing.editRowKey");
              let index = gridInstance.getRowIndexByKey(e.key);
              const replacementRequired = gridInstance.cellValue(index, "replacementRequired");
              if (replacementRequired) {
                setAutoWidth(false)
                setAddEditMode(true)
              }

            }}
            onRowCollapsed={(e) => {
              e?.component?.collapseRow(e?.key);
              setOnRowExpand(false)
            }}
            columnAutoWidth={autoWidth} onSaved={onSave}
            showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
            ref={dataGridRef} onInitNewRow={onInitNewRow}
            onSelectionChanged={onSelectionChanged} allowColumnResizing={true}
            disabled={isLoading} dataSource={dataSource}
            key="id" keyExpr="id">
            <HeaderFilter visible={true} allowSearch={true} />
            <SearchPanel visible={true} />
            <Paging defaultPageSize={25} />
            <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
            {
              addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />
            }
            <Export enabled={true} allowExportSelectedData={true} />
            <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="form"
              allowUpdating={permissions && permissions.canCreate}
              allowAdding={permissions && permissions.canCreate && allowAdding}
              allowDeleting={permissions && permissions.canDelete && allowDeletingFromApi}>
              <Texts saveRowChanges={"Save"} />
              <Form>
                <SimpleItem dataField={"name"} />
                <SimpleItem dataField={"route"} />
                <SimpleItem visible={false} dataField={"properties"} />
              </Form>
            </Editing>
            <MasterDetail enabled={true} component={(props) => <PagePropertiesDataGrid data={props.data} updateIsDataChangedOfParent={setIsDataChanged} getNavListingOfParent={getNavListingOfParent} pagePropertyColumns={pagePropertyColumns} permissions={permissions} allowDeletingFromApi={allowDeletingFromApi} allowAdding={allowAdding} />} />
            {
              dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
            }
            {
              allowSelection ?
                <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
                : null
            }
          </DataGrid>
        </div>
      );
    }
    catch (e) {
    }
  }, [dataSource, dataColumns, dropDownData, isDataChanged, selectedRowKeys, isLoading, autoWidth, addEditMode, onRowExpand]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setIsDataChanged} isDataChanged={isDataChanged} />
      <CustomSkelton>
        {Comp}
      </CustomSkelton>
    </React.Fragment>
  );
}