import React, { useEffect, useState, useContext } from "react";
import 'antd/dist/antd.css';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Context } from 'utils/context/store/Store';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {  DEButton } from "utils/services/Helpers";
import { useParams } from "react-router-dom";
import useJobDescriptionPage from "utils/hooks/useJobDescriptionPage";
import BaseLayOut from "components/Layout/BaseLayOut";
import { PDFDownloadLink } from '@react-pdf/renderer';
import ToPrint from '../../job-description/ToPrint'

const JDView = ({ data, title = "JD View", btnTitle = "View", isDisabled = false }) => {

  const { id, version } = useParams()

  const { getJDById } = useJobDescriptionPage()

  const [{ dataAccess, jdView }, dispatch] = useContext(Context)

  const [mainTaskColorBar, setMainTaskColorBar] = useState([]);

  useEffect(async () => {

    if (id && version) {
      await getJDById(id, false, version)
    }
  }, [])

  useEffect(() => {
    updateColorBar()
  }, [jdView])

  useEffect(() => { }, [dataAccess])

  const upsert = (array, item, type = null) => {
    let i = null

    if (type === "deletedVal") {
      i = array.findIndex(_item => _item.id === item.id);
    } else {
      i = array.findIndex(_item => _item.masterJdElementId === item.masterJdElementId);
    }

    if (i > -1) array[i] = item;
    else array.push(item);

    return array
  }

  const updateColorBar = () => {
    if (jdView.value && jdView.value.length > 0) {
      let updatedColorbarObject = []
      jdView.value.map((segment) => {
        segment.elements.map((element) => {
          element.jd_values.map((value) => {
            if (segment.mainTask) {
              let cBar = [...mainTaskColorBar]
              updatedColorbarObject = upsert(mainTaskColorBar, { masterJdElementId: element.id, value: value.elementScore, color: element.color, showPercentage: value.elementScore != 0 ? true : false, textColor: "black", fontSize: 12 })
            }
          })
        })
      })
    }
  }

  const getJobName = () => {
    return jdView.data?.jobName
  }

  const getLevelLabel = () => {
    // if (jdView && jdView.data && jdView.data.hasOwnProperty('masterLevelId')) {
    //   const filteredData = dataAccess && dataAccess?.levels?.filter(l => l.id === jdView.data.masterLevelId)
    //   return filteredData && filteredData.length ? filteredData[0].label : null
    // }
    //
    // return null

    return jdView.data?.masterLevelName
  }

  const getDivisionLabel = () => {
    // if (jdView && jdView.data && jdView.data.hasOwnProperty('masterOrgDivisionId')) {
    //   const filteredData = dataAccess && dataAccess?.divisions?.filter(l => l.id === jdView.data.masterOrgDivisionId)
    //   return filteredData && filteredData.length ? filteredData[0].label : null
    // }
    return jdView.data?.masterOrgDivisionName
  }

  const getEntityLabel = () => {
    // if (jdView && jdView.data.hasOwnProperty('masterOrgEntityId')) {
    //   const filteredData = dataAccess && dataAccess?.Entities?.filter(l => l.id === jdView.data.masterOrgEntityId)
    //   return filteredData && filteredData.length ? filteredData[0].label : null
    // }
    //
    // return null
    return jdView.data?.masterOrgEntityName ? jdView.data?.masterOrgEntityName : "All Entities"

  }

  const getCountryLabel = () => {
    // if (jdView && jdView.data.hasOwnProperty('masterCountryId')) {
    //   const filteredData = dataAccess && dataAccess?.countries?.filter(l => l.id === jdView.data.masterCountryId)
    //   return filteredData && filteredData.length ? filteredData[0].label : null
    // }
    //
    // return null

    return jdView.data?.masterCountryName ? jdView.data?.masterCountryName : "All Countries"
  }

  const getVerticalLabel = () => {
    // if (jdView && jdView.data && jdView.data.hasOwnProperty('masterOrgVerticalId')) {
    //   const filteredData = dataAccess && dataAccess?.Verticals?.filter(l => l.masterOrgVerticalId === jdView.data.masterOrgVerticalId)
    //   return filteredData && filteredData.length ? filteredData[0].name : null
    // }
    //
    // return null

    return jdView.data?.masterOrgVerticalName

  }

  const getFunctionLabel = () => {
    // if (jdView && jdView.data && jdView.data.hasOwnProperty('masterOrgFunctionId')) {
    //   const filteredData = dataAccess && dataAccess?.functions?.filter(l => l.masterOrgFunctionId === jdView.data.masterOrgFunctionId)
    //   return filteredData && filteredData.length ? filteredData[0].name : null
    // }
    //
    // return null

    return jdView.data?.masterOrgFunctionName

  }

  return (
    <BaseLayOut pageTitle={jdView && jdView.data ? jdView.data.jobName : null} isForJD={true}>
      <MDBox my={3}>
        {
          jdView && jdView.data ?
            <>
              <MDBox mb={3}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <MDTypography variant="h4" fontWeight="medium">{getJobName()}</MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox py={3}>
                <Card>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">Job Summary</MDTypography>
                      <MDBox mt={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6" fontWeight="medium">Level:</MDTypography>
                          </Grid>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6">{getLevelLabel()}</MDTypography>
                          </Grid>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6" fontWeight="medium">Division:</MDTypography>
                          </Grid>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6">{getDivisionLabel()}</MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mt={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6" fontWeight="medium">Entity:</MDTypography>
                          </Grid>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6">{getEntityLabel()}</MDTypography>
                          </Grid>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6" fontWeight="medium">Country:</MDTypography>
                          </Grid>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6">{getCountryLabel()}</MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mt={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6" fontWeight="medium">Vertical:</MDTypography>
                          </Grid>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6">{getVerticalLabel()}</MDTypography>
                          </Grid>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6" fontWeight="medium">Function:</MDTypography>
                          </Grid>
                          <Grid item xs={1} sm={3} md={3}>
                            <MDTypography variant="h6">{getFunctionLabel()}</MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Card>
              </MDBox>
            </>
            : null
        }

        {
          jdView.value && jdView.value.length > 0 ?

            jdView.value.map((segment) => {
              return <MDBox py={3}>
                <Card>
                  <MDBox p={3} lineHeight={1}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <MDTypography variant="h6" fontWeight="medium">{segment.name}</MDTypography>
                      </Grid>
                    </Grid>
                    {
                      segment.elements.map((element) => {
                        return <MDBox pt={2} lineHeight={1}>
                          <Grid container spacing={2} alignItems="center">
                            {
                              !element.summary
                                ? <Grid item xs={2.5}>
                                  <MDTypography variant="button" style={{ color: element.color }} fontWeight="medium">{element.name}</MDTypography>
                                </Grid>
                                : null
                            }
                          </Grid>
                          {
                            element.jd_values.map((value) => {
                              return <MDBox pt={2} lineHeight={1}>
                                <MDTypography variant="button" >
                                  <MDBox pl={3} dangerouslySetInnerHTML={{ __html: value.value }} />
                                </MDTypography>
                              </MDBox>
                            })
                          }
                        </MDBox>
                      })
                    }
                  </MDBox>
                </Card>
              </MDBox>
            })
            : null
        }

        {
          jdView && jdView.data && jdView.value && jdView.value.length > 0 ?
            <MDBox component="span" m={1} display="flex" justifyContent="flex-end" alignItems="center">
              <MDBox mr={1}>
                <>
                  <PDFDownloadLink document={<ToPrint jobName={getJobName()} jdData={jdView.value} division_name={getDivisionLabel()} />} fileName={getJobName()}>
                    <DEButton text="Export to PDF" disabled={false} stylingMode="contained" type="normal" />
                  </PDFDownloadLink>
                </>
              </MDBox>
            </MDBox>
            : null
        }
      </MDBox>
    </BaseLayOut>
  );
};

export default JDView;