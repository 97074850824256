import React, { useState, useRef, useEffect } from 'react';
import { Popover } from 'devextreme-react/popover';
import { Button } from 'devextreme-react/button';
import {
    Timeline, TimelineItem, TimelineSeparator, TimelineConnector,
    TimelineContent, TimelineDot, TimelineOppositeContent
} from '@mui/lab';
import { Paper, Typography, IconButton, Slide } from '@mui/material';
import {
    CheckCircle, Error, KeyboardArrowDown, Mouse, TouchApp,
    PlayCircleFilled, PauseCircleFilled, StopCircle, ContentCopy,
    ContentPaste, ContentCut, DragIndicator, PlayCircleOutline,
    StopCircleOutlined, Login, Delete, Edit, AddCircle, Refresh,
    Info
} from '@mui/icons-material';
import ScrollView from 'devextreme-react/scroll-view';
import { isJSONValid } from 'utils/services/Helpers';

const AuditTimeline = ({ audits }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentData, setCurrentData] = useState({});

    const operationIcons = {
        click: <TouchApp />,
        dblclick: <TouchApp />,
        keydown: <KeyboardArrowDown />,
        keyup: <KeyboardArrowDown />,
        input: <Edit />,
        change: <Edit />,
        submit: <CheckCircle />,
        focus: <KeyboardArrowDown />,
        blur: <KeyboardArrowDown />,
        resize: <KeyboardArrowDown />,
        copy: <ContentCopy />,
        cut: <ContentCut />,
        paste: <ContentPaste />,
        drag: <DragIndicator />,
        dragstart: <PlayCircleOutline />,
        dragend: <StopCircleOutlined />,
        drop: <DragIndicator />,
        touchstart: <TouchApp />,
        touchmove: <TouchApp />,
        touchend: <TouchApp />,
        play: <PlayCircleFilled />,
        pause: <PauseCircleFilled />,
        ended: <StopCircle />,
        volumechange: <KeyboardArrowDown />,
        timeupdate: <KeyboardArrowDown />,
        seeking: <KeyboardArrowDown />,
        seeked: <KeyboardArrowDown />,
        contextmenu: <KeyboardArrowDown />,
        error: <Error />,
        abort: <KeyboardArrowDown />,
        wheel: <Mouse />,
        create: <AddCircle />,
        update: <Edit />,
        delete: <Delete />,
        login: <Login />,
        page_load: <Refresh />
    };

    const renderIcon = (operation) => operationIcons[operation] || <Error />;

    const renderTimelineDotColor = (origin) => origin === 'frontend' ? 'primary' : 'secondary';

    const handlePopoverOpen = (event, data) => {
        setAnchorEl(event.currentTarget);
        setCurrentData(data);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setCurrentData({});
    };

    const open = Boolean(anchorEl);

    const renderTable = (data) => {
        const createTable = (obj) => {
            const rows = Object.entries(obj).map(([key, value]) => {
                const displayValue = typeof value === 'object' && value !== null ? createTable(value) : value;
                return (
                    <tr key={key}>
                        <td style={{ fontWeight: 'bold', padding: '4px', border: '1px solid #ddd' }}>{key}</td>
                        <td style={{ padding: '4px', border: '1px solid #ddd' }}>{displayValue}</td>
                    </tr>
                );
            });

            return (
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '10px' }}>
                    <tbody>{rows}</tbody>
                </table>
            );
        };

        let parsedData;
        try {
            parsedData = isJSONValid(data);
        } catch (e) {
            console.error('Failed to parse JSON data:', e);
            return <Typography color="error">Invalid JSON data</Typography>;
        }

        return createTable(parsedData);
    };

    return (
        <div className="timeline-container">
            <Timeline position="alternate">
                {audits.map((audit, index) => (
                    <TimelineItem key={index} className="timeline-item" data-index={index}>
                        <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                                {new Date(audit.eventTimestamp).toLocaleString()}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color={renderTimelineDotColor(audit.origin)}>
                                {renderIcon(audit.operation)}
                            </TimelineDot>
                            {index < audits.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={3} style={{ padding: '6px 16px' }}>
                                <Typography variant="h6" component="h1">
                                    {audit.operation.charAt(0).toUpperCase() + audit.operation.slice(1)}
                                </Typography>
                                <Typography>{audit.description}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {audit.url}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    IP Address: {audit.ipAddress}
                                </Typography>
                                <IconButton
                                    onClick={(e) => handlePopoverOpen(e, {
                                        displayInfo: audit.displayInfo,
                                        clientInfo: audit.clientInfo,
                                        systemInfo: audit.systemInfo,
                                        targetElement: audit.targetElement,
                                        document: audit.document
                                    })}
                                >
                                    <Info />
                                </IconButton>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>

            <Popover
                target={anchorEl}
                visible={open}
                onHiding={handlePopoverClose}
                showTitle={true}
                title="Detailed Information"
                width={500}
                height={400} // Set a fixed height to enable scrolling
            >
                <ScrollView width='100%' height='100%' direction='both'>
                    <div style={{ padding: '16px' }}>
                        {currentData.displayInfo && (
                            <>
                                <Typography variant="subtitle1">Display Info</Typography>
                                {renderTable(currentData.displayInfo)}
                            </>
                        )}
                        {currentData.clientInfo && (
                            <>
                                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Client Info</Typography>
                                {renderTable(currentData.clientInfo)}
                            </>
                        )}
                        {currentData.systemInfo && (
                            <>
                                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>System Info</Typography>
                                {renderTable(currentData.systemInfo)}
                            </>
                        )}
                        {currentData.targetElement && (
                            <>
                                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Target Element</Typography>
                                {renderTable(currentData.targetElement)}
                            </>
                        )}
                        {currentData.document && (
                            <>
                                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Document</Typography>
                                {renderTable(currentData.document)}
                            </>
                        )}
                        <Button onClick={handlePopoverClose} text="Close" type="success" style={{ marginTop: '16px' }} />
                    </div>
                </ScrollView>
            </Popover>
        </div>
    );
};

export default AuditTimeline;