import request from '../services/Http';
import React, { useContext } from 'react';
import { Context } from '../context/store/Store';
import { SET_IS_LOADING, SET_VALUES } from '../context/store/Constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  addIndexAndKeyInNewRowObj,
  getPageProperties,
  getTableColumns,
  setColumnsForDataTable
} from '../services/Helpers';
import { useIsLoading } from './useIsLoading';

export default function useValues(routeKey) {
  const [{ pagesAndPermissions }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();

  async function getValues(data_by) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get(`value?data_by=${data_by}`);
      const rows = res.data.data;
      const properties = getPageProperties(pagesAndPermissions, routeKey);
      const table = getTableColumns(properties.page.table, data_by);
      rows.forEach((row) => {
        row.key = row.sequence;
      });
      const dataTableData = {
        columns: setColumnsForDataTable(table.columns),
        rows: rows,
        newRowObject: addIndexAndKeyInNewRowObj(table.row.defaultObj)
      };
      dispatch({
        type: SET_VALUES,
        payload: dataTableData
      });
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function updateOrCreateValues(data, val) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const res = await request.post('value', { values: data });
      toast.success(res.data.data);
      await getValues(val);
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function deleteValues(values) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete('value', {
        data: {
          values: values
        }
      });
      toast.success(res.data.data);
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  function clearComponentValues() {
    dispatch({ type: SET_VALUES, payload: {} });
  }

  return { getValues, updateOrCreateValues, deleteValues, clearComponentValues, isLoading };
}
