import React, { useContext, useEffect, useState, memo } from 'react';
import { Context } from 'utils/context/store/Store';
import BlackSectionController from 'components/BlackSectionController';
import useRevisions from 'utils/hooks/useRevisions';
import { Button, DateBox, TagBox } from 'devextreme-react';
import { Form, SimpleItem } from 'devextreme-react/form';
import MDTypography from 'components/MDTypography';
import "../manage-client-vitals/components/client-vitals.scss"
import AuditTimeline from './AuditTimeline';

const Revision = memo(({ routeKey, pageName }) => {
  const [{ revisions }, dispatch] = useContext(Context);
  const { fetchRevisions, isLoading, revisionColumns, fetchFilterOptions, filterOptions } = useRevisions(routeKey);

  const [filters, setFilters] = useState({
    user: 'All users',
    date: 'All days',
    origin: 'All origins',
    operation: 'All operations',
    model: 'All models',
    url: 'All url',
  });

  useEffect(() => {
    const initializeData = async () => {
      await fetchFilterOptions()
    };

    initializeData();

    return () => { };
  }, []);


  const handleFilterChange = (e, field) => {
    setFilters({ ...filters, [field]: e.value });
  };

  const handleFetchLogs = () => {
    fetchRevisions(filters);
  };

  return (
    <div className="__body__section __manage__jobs__">
      <div className="__manage__jobs__container__">
        <BlackSectionController>
          <div className="__intro__section__title__description__">
            <h1 className="__primary__color">Activity Logs</h1>
          </div>
        </BlackSectionController>
      </div>

      <div className="cv-grid">
        <div className="filters-container" style={{ padding: "20px" }}>
          <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
            Filters
          </MDTypography>
          <div className="header-filters">
            <Form colCount={6}>
              <SimpleItem editorType="dxSelectBox" editorOptions={{
                dataSource: ['All users', ...filterOptions?.userId ?? []],
                value: filters.user,
                onValueChanged: (e) => handleFilterChange(e, 'user')
              }} />
              <SimpleItem editorType="dxSelectBox" editorOptions={{
                dataSource: ['All days', 'Today', 'Last 7 days', 'Last 30 days'],
                value: filters.date,
                onValueChanged: (e) => handleFilterChange(e, 'date')
              }} />
              <SimpleItem editorType="dxSelectBox" editorOptions={{
                dataSource: ['All origins', ...filterOptions?.origin ?? []],
                value: filters.origin,
                onValueChanged: (e) => handleFilterChange(e, 'origin')
              }} />
              <SimpleItem editorType="dxSelectBox" editorOptions={{
                dataSource: ['All operations', ...filterOptions?.operation ?? []],
                value: filters.operation,
                onValueChanged: (e) => handleFilterChange(e, 'operation')
              }} />
              <SimpleItem editorType="dxSelectBox" editorOptions={{
                dataSource: ['All models', ...filterOptions?.model ?? []],
                value: filters.model,
                onValueChanged: (e) => handleFilterChange(e, 'model')
              }} />
              <SimpleItem editorType="dxSelectBox" editorOptions={{
                dataSource: ['All url', ...filterOptions?.url ?? []],
                value: filters.url,
                onValueChanged: (e) => handleFilterChange(e, 'url')
              }} />
            </Form>
            <Button
              text="Get these logs"
              type="success"
              onClick={handleFetchLogs}
            />
          </div>
        </div>
        {revisionColumns?.length > 0 ? (
          <AuditTimeline audits={revisions?.rows ?? []} />
        ) : null
          // (
          //   <Loader />
          // )
        }
      </div>
    </div>
  );
});

export default Revision;