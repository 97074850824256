import React, { useCallback, memo } from 'react';
import { TextBox } from 'devextreme-react/text-box';

const ColumnInput = memo(({ label, onChange }) => {
  const onValueChange = useCallback((v) => {
    onChange(v);
  }, [onChange]);

  return (
    <TextBox
      className="display-inline-block __ff__header__textbox"
      width={200}
      valueChangeEvent="input"
      label="Column name"
      value={label}
      onValueChange={onValueChange}
    />
  );
});

export default ColumnInput;
