import { getUserInfo } from "./Helpers";
import request from "./Http";

let eventQueue = {
    DOM: [],
    RRWEB: []
};
let isSending = false;

const batchConfig = JSON.parse('{"batchSize": 10, "batchInterval": 5000}');
const { batchSize, batchInterval } = batchConfig;

// Add event to the queue and send it immediately or in batches
const addToQueue = (events, socket) => {
    if (Array.isArray(events)) {
        events.forEach(event => {
            if (event.eventType === 'RRWEB') {
                eventQueue.RRWEB.push(event);
            } else {
                eventQueue.DOM.push(event);
            }
        });
    } else {
        if (events.eventType === 'RRWEB') {
            eventQueue.RRWEB.push(events);
        } else {
            eventQueue.DOM.push(events);
        }
    }

    if (eventQueue.DOM.length >= batchSize || eventQueue.RRWEB.length >= batchSize) {
        sendBatch(socket);
    }
};

// Function to send events
const sendBatch = async (socket) => {
    if (isSending) return;

    isSending = true;

    const domEventsToSend = eventQueue.DOM.splice(0, eventQueue.DOM.length);
    const rrwebEventsToSend = eventQueue.RRWEB.splice(0, eventQueue.RRWEB.length);


    try {
        if (domEventsToSend.length > 0) {
            // console.log('domEventsToSend', domEventsToSend)
            socket.emit('log-events', { eventType: 'DOM', data: domEventsToSend, userId: getUserInfo()?.id });
        }
        // if (rrwebEventsToSend.length > 0) {
        //     console.log('here', socket)
        //     socket.emit('log-events', { eventType: 'RRWEB', data: JSON.stringify(d), userId: getUserInfo()?.id });
        // }
    } catch (error) {
        console.error('Failed to send logs:', error);
        // Put back events in the queue if failed
        eventQueue.DOM.unshift(...domEventsToSend);
        eventQueue.RRWEB.unshift(...rrwebEventsToSend);
    } finally {
        isSending = false;
    }
};

// Send remaining events when the tab is about to close
const setupUnloadListener = (socket) => {
    window.addEventListener('beforeunload', () => {
        sendBatch(socket);
    });
};

// Periodically send the batch if there are events in the queue
const setupInterval = (socket) => {
    setInterval(() => {
        if (eventQueue.DOM.length > 0 || eventQueue.RRWEB.length > 0) {
            sendBatch(socket);
        }
    }, batchInterval);
};

export { addToQueue, setupUnloadListener, setupInterval };