import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_HC_LEAVER, SET_IS_LOADING } from "../context/store/Constants";
import request from "../services/Http";
import { toast } from "react-toastify";
import useHeadcountRequest from "./useHeadcountRequest";
import { useIsLoading } from "./useIsLoading";
import { useNavigate, useLocation } from "react-router-dom";
import { generateFileData } from "../services/Helpers";

export default function useHeadCountLeaver(routekey) {

  const { getHeadcountRequest } = useHeadcountRequest('manage-headcount');
  const [{ hcLeaver }, dispatch] = useContext(Context)
  const { handleFileUpload, checkFileUpload } = useHeadcountRequest(routekey)
  const { isLoading, setIsLoading } = useIsLoading()
  const [leaversData, setLeaversData] = useState({})
  const navigate = useNavigate()
  const search = useLocation().search;
  const dgId = new URLSearchParams(search).get("dg");

  async function getHcLeaverDetails(groupId = null, localState = false) {
    setIsLoading(true)
    if (!localState) dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const url = groupId ? `/transaction-leaver/?groupId=${groupId}${dgId !== null ? `&dgId=${dgId}` : ""}` : '/transaction-leaver'
      const res = await request.get(url)
      let columns = res.data.columns
      let rows = res.data.data
      let dropdownValues = res.data.dropdownValues
      let isDraft = res.data.isDraft
      if (!localState) dispatch({ type: SET_HC_LEAVER, payload: { columns, rows, dropdownValues, isDraft } })
      else setLeaversData({ columns, rows, dropdownValues, isDraft })
      if (!localState) await getHeadcountRequest();
    }
    catch (e) { }
    setIsLoading(false)
    if (!localState) dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function createUpdateTransactionLeaver(leavers, masterRequestTypeId, edited = false, isDraft = false) {
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const headcountsFormData = new FormData();
      const leaverWithAttachments = leavers.filter(l => l.hasOwnProperty('attachment') && l?.attachment?.length) ?? []
      const replacements = leavers.filter(l => l.replacementRequired === true && l?.headcounts?.length) ?? []
      let hcsWithAttachments = []
      if (replacements?.length) {
        for (const leave of replacements ?? []) {
          const hcs = leave.headcounts?.filter(h => h.hasOwnProperty('attachment') && h?.attachment?.length)
          hcsWithAttachments = hcsWithAttachments.concat(hcs)
        }
      }
      let finalAttachments = []
      let finalFileDetails = []
      leavers.map(l => {
        delete l['columns'];
        delete l['dropDownData'];
        return l;
      });
      const body = { leavers, masterRequestTypeId, edited, isDraft }
      headcountsFormData.append('data', JSON.stringify(body))

      if (leaverWithAttachments?.length)
      {
        const { fileDetails, attachments} = await generateFileData(leaverWithAttachments, 'transaction_leaver')
        finalFileDetails = [...finalFileDetails, ...fileDetails]
        finalAttachments = [...finalAttachments, ...attachments]
      }

      if (hcsWithAttachments?.length)
      {
        const { fileDetails, attachments} = await generateFileData(hcsWithAttachments)
        finalFileDetails = [...finalFileDetails, ...fileDetails]
        finalAttachments = [...finalAttachments, ...attachments]
      }

      headcountsFormData.append('fileDetails', JSON.stringify(finalFileDetails))

      for (const a of finalAttachments)
      {
        if (a instanceof File) headcountsFormData.append('attachment', a)
      }

      const res = await request.post('/transaction-leaver', headcountsFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
      if (res)
      {
        localStorage.removeItem('clonedHCS');
        localStorage.removeItem('HC')
        localStorage.removeItem('LR')
        dispatch({
          type: SET_HC_LEAVER,
          payload: { rows: [], columns: hcLeaver.columns, dropdownValues: hcLeaver.dropdownValues }
        });
        toast.success(res.data.data)
        localStorage.setItem('transactionType', 'transaction_leaver');
        return navigate('/views/manage-headcounts')
      }
    }
    catch (e) { }
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  return { getHcLeaverDetails, createUpdateTransactionLeaver, isLoading, leaversData }
}
