import React, { useState, Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Tooltip } from 'devextreme-react/tooltip';
import Drawer from 'devextreme-react/drawer';
import TreeStructure from './navigationTree';
import useNavigation from '../../utils/hooks/useNavigation';

import './styles.scss';
import './responsive-styles.scss';

const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }
    if (route.route) {
      const navLinks = route.route.split('/').pop();
      return <Route exact path={`/${navLinks}`} element={route.component} key={route.key} />;
    }

    return null;
  });

const Settings = ({ routeKey, pageName }) => {
  const [isOpened, setIsOpened] = useState(true);
  const { settings } = useNavigation();

  return (
    <div className="__body__section __settings__container__">
      <Drawer
        opened={isOpened}
        openedStateMode={'shrink'}
        position={'left'}
        revealMode={'slide'}
        component={TreeStructure}
        closeOnOutsideClick={() => {
          console.log('@uz outside clicked');
        }}
      >
        <Fragment>
          <span className="__straight__line__controller">
            <i
              id="settings-navigation-btn"
              className={`dx-icon-${
                isOpened ? 'back' : 'chevronright'
              } __drawer__icon__controller__`}
              onClick={() => {
                console.log('@uz drawer icon handler');
                setIsOpened(!isOpened);
              }}
            ></i>
            <Tooltip
              target={`#settings-navigation-btn`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
              hideOnOutsideClick={false}
            >
              <div>{`${isOpened ? `Hide Navigation` : `Show Navigation`}`}</div>
            </Tooltip>
          </span>
          <div id="content" className="__content__item__container__">
            <div id="view">
              <Routes>{getRoutes(settings)}</Routes>
            </div>
          </div>
        </Fragment>
      </Drawer>
    </div>
  );
};

export default Settings;
