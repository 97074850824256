import request from '../services/Http';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../context/store/Store';
import {
  SET_IS_LOADING,
  SET_HC_REQUEST,
  SET_CHECKBOX_COUNTER,
  SET_HC_JD_VIEW
} from '../context/store/Constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  isJSONValid,
  getPageProperties,
  getTableColumns,
  generateFileData
} from '../services/Helpers';
import { useIsLoading } from './useIsLoading';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

export default function useHeadcountRequest(routeKey) {
  const [{ headcountRequest, pagesAndPermissions, dataAccess }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();
  const [jdView, setJdView] = useState({});
  const [hcJdView, setHcJdView] = useState([]);
  const [headCountRequest, setHeadCountRequest] = useState({});
  const [btData, setBtData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const search = useLocation().search;
  const dgId = new URLSearchParams(search).get('dg');

  useEffect(() => setIsLoading(false), []);

  async function getHeadcountRequest(setLocalState = false, groupId = null) {
    if (setLocalState) {
      setHeadCountRequest({});
    } else {
      dispatch({
        type: SET_HC_REQUEST,
        payload: {}
      });
    }
    setIsLoading(true);
    if (!setLocalState) {
      dispatch({ type: SET_IS_LOADING, payload: true });
    }
    try {
      const url = groupId
        ? `headcount/request?groupId=${groupId}${dgId !== null ? `&dgId=${dgId}` : ''}`
        : 'headcount/request';
      const res = await request.get(url);
      const properties = getPageProperties(pagesAndPermissions, routeKey);
      const table = getTableColumns(properties.page.table, 'raise-hc');
      let columns = _.cloneDeep(table.columns);
      let rows = res.data.data;
      let addFields = res.data.fields;
      let canAction = res.data.canAction;
      let isDraft = res.data.isDraft;
      let newRowObject = isJSONValid(table.row.defaultObj) ?? {};
      let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {};
      const filteredCountries = dataAccess && dataAccess.jdCountries ? dataAccess.jdCountries : [];
      const filteredVerticals = dataAccess && dataAccess.Verticals ? dataAccess.Verticals : [];
      filteredVerticals.map((ver) => {
        ver.id = ver.masterOrgVerticalId;
        ver.label = ver['name'] ?? ver['vertical.name'];
      });
      const filteredCap = dataAccess && dataAccess.functions ? dataAccess.functions : [];
      filteredCap.map((cap) => {
        cap.id = cap.masterOrgFunctionId;
        cap.label = cap.name ?? cap.masterOrgFunctionName ?? cap.function_name;

        return cap;
      });
      const sortedDivision =
        dataAccess.divisions && dataAccess.divisions.length
          ? dataAccess.divisions.sort((a, b) =>
              a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
            )
          : [];
      delete dataAccess['market'];
      delete dataAccess['master_employee'];
      dropdownValues = {
        ...dropdownValues,
        ...dataAccess
      };

      if (headcountRequest && headcountRequest.rows) {
        rows.concat(headcountRequest.rows);
      }

      if (
        rows &&
        rows.length &&
        rows.every((e) => e.hasOwnProperty('isInternalMobilty') && e?.isInternalMobilty)
      ) {
        columns.map((obj) => {
          if (obj.dataIndex === 'internalHeadcountId') {
            obj.is_visible = true;
            return obj;
          }
          return obj;
        });
      }

      if (addFields && addFields.length) {
        if (!groupId || !isDraft) {
          if (groupId) {
            columns.map((o) => {
              (o.editable = false), (o.isForApprover = true);
            });
          }
          addFields.map((newObj) => {
            const i = columns.findIndex((_item) => _item.dataIndex === newObj.dataIndex);
            if (i > -1) columns[i] = newObj;
            else columns.splice(columns.length - 2, 0, newObj);
          });
        }
      }

      const showButtonToCreatorAfterApproval = rows.some((o) => o.status);

      const internalEmpData = JSON.parse(localStorage.getItem('internalEmpData'));
      if (internalEmpData) {
        rows = [
          {
            id: uuidv4(),
            newRow: true,
            billable: false,
            hireDate: null,
            critical: false,
            budget: null,
            hiringManager: null,
            candidateType: null,
            candidateName: null,
            status: null,
            hold: null,
            cancelled: null,
            reActivate: null,
            pToF: false,
            startDate: null,
            endDate: null,
            justification: null,
            clients: null,
            cancel_reason: null,
            hold_reason: null,
            activation_reason: null,
            reactivationDate: null,
            masterOrgDivisionId: internalEmpData?.newHc?.masterOrgDivisionId,
            masterOrgEntityId: internalEmpData?.newHc?.masterOrgEntityId,
            masterCountryId: internalEmpData?.newHc?.masterCountryId,
            masterOrgVerticalId: internalEmpData?.newHc?.masterOrgVerticalId,
            masterOrgFunctionId: internalEmpData?.newHc?.masterOrgFunctionId,
            masterLevelId: null,
            levelId: internalEmpData?.newHc?.levelId,
            dECVLId: null,
            masterJobTypeId: null,
            masterManagementTypeId: null,
            masterCurrencyId: null,
            masterOrgHfmId: null,
            masterBudgetStatusId: null,
            masterRecruiterId: null,
            masterRecruitmentStatusId: null,
            masterEmployeeId: null,
            masterGigId: null,
            transactionLeaverId: null,
            master_job_type: null,
            master_recruitment_status: null,
            transaction_files: [],
            jd_transaction: null,
            transaction_headcount_markets: [],
            transaction_approvals: [],
            minRange: 0,
            master_clients: null,
            attachment: [],
            market: [],
            canView: true,
            needApproval: false,
            dStatus: false,
            canCancel: false,
            canHoldOrReactivate: false,
            canReactivate: false,
            onHold: false,
            canEditAfterApproval: false,
            isCancelled: false,
            re_activate: false,
            wantsToConvertToFreelancer: false,
            canEdit: true,
            isInternal: true,
            showInternalMobilityModal: false,
            replacementType: 'Internal Mobility',
            endFreelancerRequest: false,
            steps: []
          }
        ];
      }

      const dataTableData = {
        columns: columns,
        rows: rows,
        newRowObject: newRowObject,
        dropdownValues: dropdownValues,
        apiDelete: false,
        appFields: addFields,
        orgColumns: _.cloneDeep(table.columns),
        canAction: canAction,
        showButtonToCreatorAfterApproval: showButtonToCreatorAfterApproval,
        isDraft
      };

      if (setLocalState) {
        setHeadCountRequest(dataTableData);
      } else {
        dispatch({
          type: SET_HC_REQUEST,
          payload: dataTableData
        });
      }

      setIsLoading(false);
    } catch (e) {}
    setIsLoading(false);
    if (!setLocalState) dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function getJDByCombination(combination, setState = true, callback = null) {
    let showJd = null;
    try {
      delete combination.hasJd;
      const res = await request.post(`headcount/request/jd`, { combination });
      let data = res.data.data;
      let value = res.data.value;
      if (res && setState && callback) {
        callback(true);
      }
      if (setState) {
        setJdView({ data: data, value: value });
        setHcJdView({ data: data, value: value });
      } else {
        showJd = { transactionJdId: data[0].id, jobName: data[0].jobName };
        const hcJdViewData = typeof data === 'object' && Object.keys(data).length === 0 ? [] : data;
        setHcJdView(hcJdViewData);
      }
    } catch (e) {
      if (!setState) {
        setHcJdView([]);
        return false;
      }
    }

    return showJd;
  }

  async function updateOrCreateHeadcountRequest(
    data,
    masterRequestTypeId,
    masterHeadcountTypeId,
    internalEmpData,
    isDraft = false,
    callback = null
  ) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    localStorage.removeItem('isInternal');
    try {
      const headcountsFormData = new FormData();
      const updHc = internalEmpData?.updHc ?? null;
      const headcountsWithAttachment =
        data.filter((l) => l.hasOwnProperty('attachment') && l?.attachment !== null) ?? [];
      const body = {
        headcounts: data,
        masterHeadcountTypeId,
        masterRequestTypeId,
        internalEmpData: updHc,
        isDraft
      };
      headcountsFormData.append('data', JSON.stringify(body));
      const { fileDetails, attachments } = await generateFileData(headcountsWithAttachment);
      headcountsFormData.append('fileDetails', JSON.stringify(fileDetails));

      for (const a of attachments) {
        if (a instanceof File) headcountsFormData.append('attachment', a);
      }

      const res = await request.post('/headcount/request', headcountsFormData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (res) {
        localStorage.removeItem('clonedHCS');
        localStorage.removeItem('HC');
        localStorage.removeItem('LR');
        dispatch({
          type: SET_HC_REQUEST,
          payload: {
            rows: [],
            columns: headcountRequest.columns,
            dropdownValues: headcountRequest.dropdownValues
          }
        });
        toast.success(res.data.data);
        setIsLoading(false);
        dispatch({ type: SET_IS_LOADING, payload: false });
        if (callback) callback();
        else return navigate('/views/manage-headcounts');
      }
    } catch (e) {}
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function deleteHeadcountRequest(data, val) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete(val, {
        data: {
          orgstruct: data
        }
      });
      await getStructureManagement(val);
      toast.success(res.data.data);
    } catch (e) {}
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function handleFileUpload(data, transactionType = 'hc_transaction', key = 'attachment') {
    let promises = [];
    let headcountsFormData = new FormData();
    headcountsFormData.append('transactionType', transactionType);
    let obj = [];
    for (const d of data) {
      if (d && d.hasOwnProperty(key)) {
        let currentObj = obj.find((item) => item.id === (d?.isDraft ? d?.fileId ?? d.id : d.id));

        if (!currentObj) {
          currentObj = {
            id: key === 'approver_attachments' ? d?.fileId : d?.isDraft ? d?.fileId ?? d.id : d.id,
            fileName: []
          };
          obj.push(currentObj);
        }

        for (const a of d[key]) {
          if (a instanceof File) {
            currentObj.fileName.push(a.name);
            headcountsFormData.append('attachment', a);
          }
        }
      }
    }

    headcountsFormData.append('fileDetails', JSON.stringify(obj));
    const config = {
      headers: {
        'content-type': `multipart/form-data; charset=utf-8; boundary=${headcountsFormData}`
      }
    };
    const res1 = request.post('/file-upload', headcountsFormData, config);
    promises.push(res1);

    return await Promise.all(promises);
  }

  async function checkFileUpload(data, transactionType = 'hc_transaction', key = 'attachment') {
    let promises = [];
    let headcountsFormData = new FormData();
    headcountsFormData.append('transactionType', transactionType);
    let obj = [];
    for (const d of data) {
      if (d && d.hasOwnProperty(key)) {
        let currentObj = obj.find((item) => item.id === (d?.isDraft ? d?.fileId ?? d.id : d.id));

        if (!currentObj) {
          currentObj = {
            id: key === 'approver_attachments' ? d?.fileId : d?.isDraft ? d?.fileId ?? d.id : d.id,
            fileName: []
          };
          obj.push(currentObj);
        }

        for (const a of d[key]) {
          if (a instanceof File) {
            currentObj.fileName.push(a.name);
            headcountsFormData.append('attachment', a);
          }
        }
      }
    }

    // headcountsFormData.append('fileDetails', JSON.stringify(obj))
    const config = {
      headers: {
        'content-type': `multipart/form-data; charset=utf-8; boundary=${headcountsFormData}`
      }
    };
    const res1 = request.post('/file-upload/check', headcountsFormData, config);
    promises.push(res1);

    return await Promise.all(promises);
  }

  async function updateInternalMobilityDetails(data, hcId, apiCallBack) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.put(`/headcount/request/internal-mobility/${hcId}`, data);
      if (res) {
        toast.success(res.data.data);

        localStorage.setItem('isInternal', 'true');
        await apiCallBack();
      }
    } catch (e) {}
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function getBriefingTemplateData(id = null) {
    setIsLoading(true);
    try {
      const res = await request.get(`/headcount/request/bt`, { params: { id } });
      if (res) {
        let data = res?.data?.data;
        data.employees = res?.data?.dropdownData?.employees;
        setBtData(data);
        localStorage.removeItem('forceLogout');
      } else {
        toast.error('You are not authroized to view this page!');
        if (localStorage.getItem('hasProfile') === 'true') {
          navigate('views/home');
        } else {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = '/';
        }
      }
    } catch (e) {
      return false;
    }
    setIsLoading(false);
  }

  async function updateBriefingTemplateData(data, id = null, apiCallBack = null) {
    setIsLoading(true);
    try {
      const res = await request.post(`/headcount/request/bt`, { btData: data });
      toast.success(res.data.data);
      if (res) {
        await getBriefingTemplateData(id);
        apiCallBack();
      }
    } catch (e) {}
    setIsLoading(false);
  }

  async function createUpdateFreeLancerDetails(data, apiCallBack = null) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const dc = { ...data };
      delete dc['cv'];
      delete dc['contract'];
      delete dc['noc'];
      const fd = new FormData();
      fd.append('data', JSON.stringify(dc));
      if (data?.cvFile?.length) fd.append('cvFile', data.cvFile[0]);
      if (data?.contractFile?.length) fd.append('contractFile', data.contractFile[0]);
      if (data?.nocFile?.length) fd.append('nocFile', data.nocFile[0]);
      const res = await request.post('/headcount/request/freelancer-detail', fd, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (res) {
        toast.success(res.data.data);
        if (apiCallBack) await apiCallBack();
      }
    } catch (e) {}
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  return {
    getHeadcountRequest,
    getJDByCombination,
    updateOrCreateHeadcountRequest,
    deleteHeadcountRequest,
    isLoading,
    jdView,
    setJdView,
    hcJdView,
    setHcJdView,
    headCountRequest,
    handleFileUpload,
    updateInternalMobilityDetails,
    checkFileUpload,
    getBriefingTemplateData,
    btData,
    updateBriefingTemplateData,
    createUpdateFreeLancerDetails
  };
}
