import { useEffect, useRef, useContext } from 'react';
import { addToQueue, setupInterval, setupUnloadListener } from '../services/eventQueue';
import { useLocation } from 'react-router-dom';
import { getUserInfo } from 'utils/services/Helpers';
import { SocketContext } from 'utils/context/socketContext';

const loggedEvents = new Set();
const THROTTLE_TIME = 1000;

// Define dynamic classes and corresponding messages
const classActionsMap = {
    'dx-header-filter': 'on the filter of the',
    'dx-button': 'on the button with text',
    'dx-datagrid-group-closed': 'and expanded the request',
    'dx-datagrid-group-opened': 'and collapsed the request',
    'lights-legend__item lights-legend__item--active': 'and clicked on the filter with text',
    'dx-icon dx-icon-add': 'on the add button',
    'dx-icon-spindown': 'on the export to excel button',
    'dx-checkbox': 'on the checkbox',
};

const getClientInfo = () => ({
    userAgent: navigator.userAgent,
    language: navigator.language,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

const getDisplayInfo = () => ({
    viewportSize: { width: window.innerWidth, height: window.innerHeight },
    screenResolution: { width: screen.width, height: screen.height },
});

const getSystemInfo = () => {
    const networkInfo = navigator.connection || {};
    const memoryUsage = performance.memory || {};
    return {
        networkInfo: {
            type: networkInfo.effectiveType,
            downlink: networkInfo.downlink,
            rtt: networkInfo.rtt,
        },
        memoryUsage: {
            jsHeapSizeLimit: memoryUsage?.jsHeapSizeLimit,
            totalJSHeapSize: memoryUsage?.totalJSHeapSize,
            usedJSHeapSize: memoryUsage?.usedJSHeapSize,
        },
    };
};

const constructLogDescription = (userId, eventType, eventDetails, targetElement) => {
    let description = `User ${userId} (${getUserInfo()?.name} - ${getUserInfo()?.username}) triggered a ${eventType} event`;

    try {
        const matchedAction = Object.keys(classActionsMap).find(className => targetElement?.className?.includes(className));

        // console.log('eventType', eventType);
        // console.log('matchedAction', matchedAction);
        // console.log('targetElement?.className', targetElement?.className);

        if (matchedAction) {
            if (matchedAction === 'dx-datagrid-group-closed') {
                const rowElement = targetElement.closest('tr');
                const hcIdElement = rowElement?.querySelectorAll('td')?.[3];
                const hcId = hcIdElement?.innerText || 'Unknown';
                description += ` ${classActionsMap[matchedAction]} with HC-ID "${hcId}"`;
            } else if (targetElement.className.includes('dx-button') && window.location.pathname === "/views/home") {
                const jobNumberElement = targetElement.closest('.__second__section__container__')?.querySelector('.job__number h6');
                const jobNumber = jobNumberElement?.innerText || 'Unknown Job Number';
                description += ` ${classActionsMap[matchedAction]} "${targetElement?.innerText || ''}" for job number "${jobNumber}"`;
            } else {
                description += ` ${classActionsMap[matchedAction]} "${targetElement?.innerText || ''}"`;
            }
        } else if (eventType === 'blur' && targetElement?.tagName === 'INPUT') {
            const finalText = targetElement?.value?.trim();
            if (!finalText) return null; // Skip logging if input text is empty
            description += ` on an input element with final text "${finalText || ''}"`;
        } else if (eventType === 'scroll') {
            description += ` while scrolling at position (${eventDetails?.scrollPosition?.x}, ${eventDetails?.scrollPosition?.y})`;
        } else if (eventType === 'submit') {
            description += ` on a form with the following data: ${JSON.stringify(eventDetails?.formData || {})}`;
        } else if (eventType === 'keydown' || eventType === 'keyup') {
            description += ` while pressing the "${eventDetails?.key || ''}" key`;
        } else if (eventType === 'wheel') {
            description += ` while scrolling on a ${eventDetails?.targetTag || ''} element with text "${eventDetails?.targetText || ''}"`;
        } else if (eventType === 'focus' || eventType === 'blur') {
            if (targetElement?.className?.includes('dx-htmleditor-content')) {
                // Capturing content from the DevExtreme HTML Editor
                const editorContent = targetElement.innerHTML;
                description += ` in the HTML editor with content: "${editorContent}"`;
            } else {
                description += ` on an input element with text "${eventDetails?.targetText || ''}"`;
            }
        } else {
            // Handle the case where the target is a dropdown or a selected dropdown item
            const labelElement = targetElement.closest('.dx-field-item')?.querySelector('.dx-field-item-label');
            const labelText = labelElement?.innerText?.trim() || null;

            if (eventType === 'click' && eventDetails?.editorContent) {
                description += ` in the HTML editor with content: "${eventDetails.editorContent || ''}"`;
            } else if (targetElement?.className?.includes('dx-texteditor-input') && labelText) {
                // Case where the input itself is clicked (e.g., opening the dropdown)
                description += ` on a dropdown with label "${labelText}"`;
            } else if (targetElement?.className?.includes('dx-list-item-content') && labelText) {
                // Case where a dropdown item is clicked
                description += ` on a ${eventDetails?.targetTag || ''} element with text "${eventDetails?.targetText || ''}" for the dropdown with label "${labelText}"`;
            } else if (!labelText) { // If no label was found, check for a placeholder
                const placeholderElement = targetElement.closest('.dx-texteditor-container')?.querySelector('.dx-placeholder');
                const placeholderText = placeholderElement?.getAttribute('data-dx_placeholder')?.trim() || null;

                if (placeholderText) {
                    if (targetElement?.className?.includes('dx-texteditor-input')) {
                        // Case where the input itself is clicked (e.g., opening the dropdown)
                        description += ` on a dropdown with placeholder "${placeholderText}"`;
                    } else if (targetElement?.className?.includes('dx-list-item-content')) {
                        // Case where a dropdown item is clicked
                        description += ` on a ${eventDetails?.targetTag || ''} element with text "${eventDetails?.targetText || ''}" for the dropdown with placeholder "${placeholderText}"`;
                    } else {
                        const finalText = eventDetails?.targetText?.trim();
                        if (!finalText) return null; // Skip logging if the text is empty
                        description += ` on a ${eventDetails?.targetTag || ''} element with text "${finalText || ''}" for the dropdown with placeholder "${placeholderText}"`;
                    }
                } else {
                    if (eventType === 'input') return null;
                    const finalText = eventDetails?.targetText?.trim();
                    if (!finalText) return null; // Skip logging if the text is empty
                    description += ` on a ${eventDetails?.targetTag || ''} element with text "${finalText || ''}"`;
                }
            } else {
                if (eventType === 'input') return null;
                const finalText = eventDetails?.targetText?.trim();
                if (!finalText) return null; // Skip logging if the text is empty
                description += ` on a ${eventDetails?.targetTag || ''} element with text "${finalText || ''}"`;
            }
        }

        return description;
    } catch (error) {
        console.error('Error constructing log description:', error);
        return null;
    }
};

const logEvent = (userId, eventType, eventDetails, targetElement) => {
    try {
        const description = constructLogDescription(userId, eventType, eventDetails, targetElement);
        if (!description) return;

        const logObject = {
            eventType: 'DOM',
            userId,
            operation: eventType,
            document: JSON.stringify(eventDetails),
            description: `${description} at ${new Date().toLocaleString()}`,
            origin: 'frontend',
            url: window.location.href,
            ipAddress: localStorage.getItem('ipAddress') || "",
            displayInfo: getDisplayInfo(),
            clientInfo: getClientInfo(),
            systemInfo: getSystemInfo(),
            targetElement: {
                tagName: targetElement?.tagName || '',
                id: targetElement?.id || '',
                className: targetElement?.className || '',
                textContent: targetElement?.innerText || targetElement?.alt || targetElement?.value || null,
            },
            additionalInfo: null,
            eventTimestamp: new Date().toISOString(),
        };

        if (window.location.pathname !== "/views/settings/activity-log")
            addToQueue(logObject);
    } catch (error) {
        console.error('Error logging event:', error);
    }
};

const useGlobalEventLogger = () => {
    const location = useLocation();
    const lastEventDetail = useRef({});
    const socket = useContext(SocketContext);
    const activeEditorRef = useRef(null); // Store the active editor
    const editorContentRef = useRef(''); // Store editor content

    useEffect(() => {
        // Set up the interval and unload listener with the socket
        setupInterval(socket);
        setupUnloadListener(socket);

        const handleEvent = (event) => {
            // console.log('event', event)
            try {
                const eventType = event.type;
                const currentTime = Date.now();

                if (['touchmove'].includes(eventType)) {
                    if (lastEventDetail.current.eventType === eventType && (currentTime - lastEventDetail.current.timestamp) < THROTTLE_TIME) {
                        return;
                    }
                    lastEventDetail.current = { eventType, timestamp: currentTime };
                }

                const eventDetails = {
                    targetTag: event.target?.tagName || '',
                    targetId: event.target?.id || '',
                    targetClass: event.target?.className || '',
                    targetText: event.target?.innerText || event.target?.alt || event.target?.value || null,
                    eventX: event.clientX,
                    eventY: event.clientY,
                    key: event.key || null,
                    clipboardData: event.clipboardData ? event.clipboardData.getData('text') : null,
                    errorMessage: event.message || null,
                    errorStack: event.error ? event.error.stack : null,
                    scrollPosition: { x: window.scrollX, y: window.scrollY },
                    hoverDuration: null,
                };

                // When the user clicks inside the HTML editor
                if (eventType === 'click' && event.target.className.includes('dx-htmleditor-content')) {
                    activeEditorRef.current = event.target.innerHTML; // Set active editor
                    editorContentRef.current = event.target.innerHTML; // Capture initial content
                }

                // On input, update the editor content
                if (eventType === 'input' && event.target.className.includes('dx-htmleditor-content')) {
                    editorContentRef.current = event.target.innerHTML;
                }

                // On clicking outside the editor, log the final content
                if (eventType === 'click' && editorContentRef.current /* && !event.target.className.includes('dx-htmleditor-content') */) {
                    eventDetails.editorContent = editorContentRef.current; // Add the final content to event details
                    activeEditorRef.current = null; // Clear active editor
                }

                logEvent(getUserInfo()?.id, eventType, eventDetails, event.target);
                loggedEvents.add(eventType);
            } catch (error) {
                console.error('Error handling event:', error);
            }
        };

        const eventTypes = [
            'click', 'dblclick', 'change', 'submit', 'focus', 'blur', 'input',
            'resize', 'copy', 'cut', 'paste', /* 'drag', 'dragstart', 'dragend', 'dragenter', 'dragleave', 'dragover', */ 'drop',
            'touchstart', 'touchmove', 'touchend', 'touchcancel', 'play', 'pause', 'ended', 'volumechange', 'timeupdate', 'seeking', 'seeked',
            'contextmenu', 'error', 'abort'
        ];

        eventTypes.forEach(eventType => document.addEventListener(eventType, handleEvent));

        const handleUrlChange = () => {
            try {
                logEvent(getUserInfo()?.id, 'url_change', { url: window.location.href }, {});
            } catch (error) {
                console.error('Error handling URL change:', error);
            }
        };

        window.addEventListener('popstate', handleUrlChange);
        window.addEventListener('pushstate', handleUrlChange);

        return () => {
            try {
                eventTypes.forEach(eventType => document.removeEventListener(eventType, handleEvent));
                window.removeEventListener('popstate', handleUrlChange);
                window.removeEventListener('pushstate', handleUrlChange);
            } catch (error) {
                console.error('Error during cleanup:', error);
            }
        };
    }, [location, socket]);
};

export default useGlobalEventLogger;