import MDBox from 'components/MDBox';
import React, { useContext, useEffect, useState } from 'react';
import { CheckboxTreeContainer } from './components/checkbox-tree-container';
import { CombinationTypeSelect } from './components/combination-type-select';
import { FormulaCheckboxes } from './components/formula-checkboxes';
import { DataAccessContext } from './contexts/data-access-context';
import { flattenData, getNodesByValues, groupBy } from './helpers';
import {
  consolidateData,
  filterItemsByKeys,
  getCheckedCombinationsKeys,
  getCombinationsByKeys,
  handleFormulaResult,
  handleHFMSValueCase,
  handleMarketValueCase,
  handleMasterClientValueCase,
  handleMasterDepartmentValueCase,
  handleMasterEmployeeValueCase,
  updateCombination,
} from "./helpers/tree-data-helper";
import {
  validateOrgCountry,
  validateCountryOrg,
  validateLevelCountry,
  validateOrgLevel,
  validateCountryLevel,
  validateLevelOrg, validateDepartmentClient, validateEntityTypeBudget
} from "./helpers/combination-validators";
import { cloneDeep } from 'lodash';

const DataAccessCombination = ({
  accordionID,
  combinationID,
  userDataAccess,
  combinationsData,
  dataAccessFormulasData,
  editMode
}) => {
  const {
    handleUpdateCombination,
    setDataAccessValidations,
    getCombinationsList,
    getCombination,
    getDataAccessValue,
    removeKeyDataValidation,
    getCheckedNodes,
    updateCombinationAndEmptyTransactions,
    removeCheckedNodes,
    dataAccesses
  } = useContext(DataAccessContext);
  // the combination type dropdown value
  const [combinationVal, setCombinationVal] = useState(
    getCombination(accordionID, combinationID)?.type || null
  );
  // formula/combination checkboxes
  const [selectedFormula, setSelectedFormula] = useState(null);
  // the combination list generated based on the selected formula
  const [combinationsList, setCombinationsList] = useState(null);
  // the tree checkbox data source
  const [treeDataSource, setTreeDataSource] = useState(null);
  // list of checked nodes in the tree data source
  const [nodeChecked, setNodeChecked] = useState([]);
  // list of selected combination based on the nodeChecked values
  const [selectedCombination, setSelectedCombination] = useState([]);

  const handleSelectedFormulaChange = (formulas) => {
    setTreeDataSource(null);
    removeCheckedNodes(accordionID, combinationID);
    setNodeChecked([]);
    setSelectedFormula(formulas);
    removeKeyDataValidation(accordionID, combinationID, 'transactions');
    setSelectedCombination([]);
    const checkedCombinationsKeys = getCheckedCombinationsKeys(formulas);
    updateCombinationAndEmptyTransactions(accordionID, combinationID, {
      formula: checkedCombinationsKeys,
      selectedFormula: formulas
    });
    const combinations = getCombinationsByKeys(formulas, userDataAccess);
    setCombinationsList(combinations);
  };

  useEffect(() => {
    if (combinationsData.length) {
      setDataAccessValidations((prevDataAccessValidations) => ({
        ...prevDataAccessValidations,
        combinationsData
      }));
    }
  }, [combinationsData, combinationVal]);

  useEffect(() => {
    if (combinationVal) {
      // Update the state of dataAccessValidations for the selectedType object
      setDataAccessValidations((prevDataAccessValidations) => ({
        ...prevDataAccessValidations,
        [accordionID]: {
          ...prevDataAccessValidations[accordionID],
          selectedTypes: {
            ...prevDataAccessValidations[accordionID]?.selectedTypes,
            [combinationID]: combinationVal.value
          }
        }
      }));
      // Handle update of the combination for the type object
      handleUpdateCombination(accordionID, combinationID, { type: combinationVal });
      // Check if combinationVal.value is "HFMS"
      if (combinationVal.value === 'HFMS') {
        handleHFMSValueCase(
          accordionID,
          combinationID,
          userDataAccess,
          setSelectedFormula,
          setTreeDataSource,
          getCombination
        );
      }
      else if (combinationVal.value === 'market') {
        handleMarketValueCase(
          accordionID,
          combinationID,
          userDataAccess,
          setSelectedFormula,
          setTreeDataSource,
          getCombination
        );
      }
      else if (combinationVal.value === 'master_employee') {
        handleMasterEmployeeValueCase(
          accordionID,
          combinationID,
          userDataAccess,
          setSelectedFormula,
          setTreeDataSource,
          getCombination
        );
      }
      else if (combinationVal.value === 'master_departments') {
        handleMasterDepartmentValueCase(
          accordionID,
          combinationID,
          userDataAccess,
          setSelectedFormula,
          setTreeDataSource,
          getCombination
        );
      }
      else if (combinationVal.value === 'master_client') {
        handleMasterClientValueCase(
          accordionID,
          combinationID,
          userDataAccess,
          setSelectedFormula,
          setTreeDataSource,
          getCombination
        );
      }
      else {
        handleFormulaResult(
          combinationVal,
          dataAccessFormulasData,
          accordionID,
          combinationID,
          setSelectedFormula,
          setTreeDataSource,
          getCombination
        );
      }
    }
    else {
      // If combinationVal does not exist, reset selectedFormula and treeDataSource
      setSelectedFormula(null);
      setTreeDataSource(null);
    }
    if (editMode && combinationVal) {
      if (combinationVal.value !== 'HFMS' && combinationVal.value !== "market" && combinationVal.value !== "master_employee"  && combinationVal.value !== "master_departments" && combinationVal.value !== "master_client") return;
      const combination = getCombination(accordionID, combinationID);
      if (combination.type.value !== 'HFMS' && combinationVal.value !== "market" && combinationVal.value !== "master_employee"  && combinationVal.value !== "master_departments" && combinationVal.value !== "master_client") return;
      setNodeChecked(getCheckedNodes(accordionID, combinationID));
      const formulaResult = dataAccessFormulasData.find((formula) => {
        return formula.value === combination.type.value;
      });
      if (formulaResult) {
        const editModeFormula = combination?.formula;
        if (formulaResult?.combination) {
          const formulaFinalResult = formulaResult.combination.map((formula) => ({
            ...formula,
            isChecked: editModeFormula ? editModeFormula[formula.value] ?? false : false
          }));
          const combinations = getCombinationsByKeys(formulaFinalResult, userDataAccess);
          setCombinationsList(combinations);
        }
      }
    }
  }, [editMode, combinationVal]);

  useEffect(() => {
    if (selectedFormula?.length) {
      if (editMode) {
        const combination = getCombination(accordionID, combinationID);
        setNodeChecked(getCheckedNodes(accordionID, combinationID));
        const formulaResult = dataAccessFormulasData.find((formula) => {
          return formula.value === combination.type.value;
        });
        if (formulaResult) {
          const editModeFormula = combination?.formula;
          if (formulaResult?.combination) {
            const formulaFinalResult = formulaResult.combination.map((formula) => ({
              ...formula,
              isChecked: editModeFormula ? editModeFormula[formula.value] ?? false : false
            }));
            const combinations = getCombinationsByKeys(formulaFinalResult, userDataAccess);
            setCombinationsList(combinations);
            if (combinations.length) return;
          }
        }
      }
      const checkedCombinationsKeys = getCheckedCombinationsKeys(selectedFormula);
      updateCombinationAndEmptyTransactions(accordionID, combinationID, {
        formula: checkedCombinationsKeys,
        selectedFormula: selectedFormula
      });
      const combinations = getCombinationsByKeys(selectedFormula, userDataAccess);
      setCombinationsList(combinations);
    }
  }, [selectedFormula, editMode]);

  useEffect(() => {
    // handling change on selectedCombination which is the tree checkbox data changes
    if (!selectedCombination.length) {
      return;
    }

    // Depending on the value of combinationVal, we need to update the combination differently
    const combinationUpdate =
      combinationVal?.value === 'HFMS'
        ? {
            transactions: [{ hfms: selectedCombination.map(Number) }],
            formula: { checkedNodes: nodeChecked }
          }
        : combinationVal?.value === 'market'
          ? {
            transactions: [{ market: selectedCombination.map(Number) }],
            formula: { checkedNodes: nodeChecked }
          } :
          combinationVal?.value === 'master_employee'
            ? {
              transactions: [{ master_employee: selectedCombination.map(Number) }],
              formula: { checkedNodes: nodeChecked }
            } : combinationVal?.value === 'master_departments'
              ? {
                transactions: [{ master_departments: selectedCombination.map(Number) }],
                formula: { checkedNodes: nodeChecked }
              } : combinationVal?.value === 'master_client'
                ? {
                  transactions: [{ master_client: selectedCombination.map(Number) }],
                  formula: { checkedNodes: nodeChecked }
                } : { transactions: flattenData(selectedCombination) };

    const prevTrans = getCombination(accordionID, combinationID).prevTransactions;
    const selectedFormula = getCombination(accordionID, combinationID).formula;

    if (combinationVal?.value !== 'HFMS' && combinationVal?.value !== 'market' && combinationVal?.value !== "master_employee" && combinationVal?.value !== "master_departments" && combinationVal?.value !== "master_client" && prevTrans) {
      const updatedCombination = combinationUpdate.transactions.map((item) => {
        let obj = {};
        const keysOrder = [
          'masterLevelId',
          'masterCountryId',
          'masterOrgFunctionId',
          'masterOrgVerticalId',
          'masterOrgEntityId',
          'masterOrgDivisionId',
          'masterDepartmentId',
          'masterClientId',
          'masterFCDetailTypeId',
          'masterFCBudgetLineId',
        ];

        let defaultKey;

        for (const key of keysOrder) {
          if (selectedFormula[key]) {
            defaultKey = key;
            break;
          }
        }

        if (!defaultKey) {
          defaultKey = combinationVal?.value === "DepartmentClients" ? "masterDepartmentId" : 'masterOrgDivisionId';
        }
        prevTrans.forEach((prev) => {
          if (prev[defaultKey] === item[defaultKey]) {
            obj.transactionId = prev.transactionId;
            obj.dataAccessCombinationId = prev.dataAccessCombinationId;
            obj.orgDataAccessId = prev.orgDataAccessId;
          }
        });
        return { ...item, ...obj };
      });
      updateCombination(
        accordionID,
        combinationID,
        { transactions: updatedCombination },
        handleUpdateCombination
      );
    }
    else {
      updateCombination(accordionID, combinationID, combinationUpdate, handleUpdateCombination);
    }

    // If combinationVal's value is not 'HFMS', we proceed with additional operations
    if (combinationVal?.value !== 'HFMS' && combinationVal?.value !== 'market' && combinationVal?.value !== "master_employee" && combinationVal?.value !== "master_departments" && combinationVal?.value !== "master_client") {
      const consolidatedData = consolidateData(selectedCombination);
      setDataAccessValidations((prevDataAccessValidations) => ({
        ...prevDataAccessValidations,
        [accordionID]: {
          ...prevDataAccessValidations[accordionID],
          transactions: {
            ...prevDataAccessValidations[accordionID]?.transactions,
            [combinationID]: consolidatedData
          }
        }
      }));
    }
  }, [selectedCombination]);

  useEffect(() => {
    if (combinationsList?.length) {
      // Map the values from combinationsList to a new array named keys
      const keys = combinationsList.map((item) => item.value);
      // Use flatMap to flatten an array of item data from combinationsList into a new array named items
      let items = combinationsList.flatMap((item) => item.data);

      const combinations = getDataAccessValue(accordionID)?.combinations;
      let prevValues = consolidateData(items);

      // First pass to populate seenTypes
      const seenTypes = [];
      for (const [_, value] of combinations.entries()) {
        if (value && value?.type?.value) seenTypes.push(value.type.value);
      }

      // Find the index of combinationVal.value
      const index = seenTypes.indexOf(combinationVal.value);
      const precedingTypes = seenTypes.slice(0, index);

      if (combinations)
      {
        for (const [key, value] of combinations.entries())
        {
          if (key !== combinationID) {
            const applicablePrecedingTypes = precedingTypes.filter(
              (type) =>
                (combinationVal.value === 'COUNTRY' && ['ORG_STRUCTURE', 'LEVEL'].includes(type)) ||
                (combinationVal.value === 'LEVEL' && ['ORG_STRUCTURE', 'COUNTRY'].includes(type)) ||
                (combinationVal.value === 'ORG_STRUCTURE' && ['LEVEL', 'COUNTRY'].includes(type)) ||
                (combinationVal.value === 'DepartmentClients' && ['DepartmentClients'].includes(type)) ||
                (combinationVal.value === 'EntityTypeBudget' && ['EntityTypeBudget'].includes(type))
            );

            for (const precedingType of applicablePrecedingTypes) {
              if (value?.type?.value === precedingType) {
                let newValues;
                if (combinationVal.value === 'COUNTRY' && precedingType === 'ORG_STRUCTURE') {
                  newValues = validateOrgCountry(value, prevValues, userDataAccess, combinations);
                }
                if (combinationVal.value === 'COUNTRY' && precedingType === 'LEVEL') {
                  newValues = validateLevelCountry(value, prevValues, userDataAccess);
                }
                if (combinationVal.value === 'LEVEL' && precedingType === 'ORG_STRUCTURE') {
                  newValues = validateOrgLevel(value, prevValues, userDataAccess);
                }
                if (combinationVal.value === 'LEVEL' && precedingType === 'COUNTRY') {
                  newValues = validateCountryLevel(value, prevValues, userDataAccess);
                }
                if (combinationVal.value === 'ORG_STRUCTURE' && precedingType === 'COUNTRY') {
                  newValues = validateCountryOrg(value, prevValues);
                }
                if (combinationVal.value === 'ORG_STRUCTURE' && precedingType === 'LEVEL') {
                  newValues = validateLevelOrg(value, prevValues, userDataAccess);
                }
                if (combinationVal.value === 'DepartmentClients' && precedingType === 'DepartmentClients') {
                  newValues = validateDepartmentClient(value, prevValues, userDataAccess);
                }
                if (combinationVal.value === 'EntityTypeBudget' && precedingType === 'EntityTypeBudget') {
                  newValues = validateEntityTypeBudget(value, prevValues, userDataAccess);
                }

                if (newValues) {
                  prevValues = { ...prevValues, ...newValues };
                }
              }
            }
          }
        }

        if (Object.keys(prevValues)?.length) {
          items = filterItemsByKeys(cloneDeep(items), prevValues);
        }
      }

      // Group items based on keys using the helper function groupBy
      const groupedItems = groupBy(cloneDeep(items), keys);

      // Set the state of treeDataSource with the grouped items, with a root node added
      setTreeDataSource([{ value: '0', label: 'Select All', children: groupedItems }]);
    } else if (combinationVal?.value !== 'HFMS' && combinationVal?.value !== 'market' && combinationVal?.value !== 'master_employee' && combinationVal?.value !== "master_departments" && combinationVal?.value !== "master_client") {
      setTreeDataSource(null);
    }
  }, [combinationsList]);

  const handleNodesChecked = (checkNodes) => {
    const combinationsMap = dataAccesses.get(accordionID)?.combinations;

    const dependentCombinationsKeys = getKeysAfter(combinationsMap, combinationID);

    dependentCombinationsKeys.forEach((key) => {
      const combination = getCombination(accordionID, key);
      if (combination?.formula) {
        const { checkedNodes, selectedCombination, ...rest } = getCombination(
          accordionID,
          key
        )?.formula;
        updateCombinationAndEmptyTransactions(accordionID, key, {
          formula: rest,
          prevTransactions: [],
          selectedFormula: [],
          submitted: true,
          resetKey: true
        });
      }
    });

    removeCheckedNodes(accordionID, combinationID);
    setNodeChecked(checkNodes);

    if (combinationVal?.value === 'HFMS')
    {
      handleUpdateCombination(accordionID, combinationID, {
        formula: {
          ...getCombination(accordionID, combinationID)?.formula,
          checkedNodes: checkNodes,
          selectedCombination: checkNodes
        }
      });
      setSelectedCombination(checkNodes);
    }
    else if (combinationVal?.value === "market")
    {
      handleUpdateCombination(accordionID, combinationID, {
        formula: {
          ...getCombination(accordionID, combinationID)?.formula,
          checkedNodes: checkNodes,
          selectedCombination: checkNodes
        }
      });
      setSelectedCombination(checkNodes);
    }
    else if (combinationVal?.value === "master_employee")
    {
      handleUpdateCombination(accordionID, combinationID, {
        formula: {
          ...getCombination(accordionID, combinationID)?.formula,
          checkedNodes: checkNodes,
          selectedCombination: checkNodes
        }
      });
      setSelectedCombination(checkNodes);
    }
    else if (combinationVal?.value === "master_departments")
    {
      handleUpdateCombination(accordionID, combinationID, {
        formula: {
          ...getCombination(accordionID, combinationID)?.formula,
          checkedNodes: checkNodes,
          selectedCombination: checkNodes
        }
      });
      setSelectedCombination(checkNodes);
    }
    else if (combinationVal?.value === "master_client")
    {
      handleUpdateCombination(accordionID, combinationID, {
        formula: {
          ...getCombination(accordionID, combinationID)?.formula,
          checkedNodes: checkNodes,
          selectedCombination: checkNodes
        }
      });
      setSelectedCombination(checkNodes);
    }
    else
    {
      removeKeyDataValidation(accordionID, combinationID, 'transactions');
      const selectedNode = getNodesByValues(treeDataSource[0].children, checkNodes);
      updateCombinationAndEmptyTransactions(accordionID, combinationID, {
        formula: {
          ...getCombination(accordionID, combinationID)?.formula,
          checkedNodes: checkNodes,
          selectedCombination: selectedNode
        }
      });
      setSelectedCombination(selectedNode);
    }
  };

  function getKeysAfter(map, key) {
    let found = false;
    const keysAfter = [];

    for (let [otherKey, otherValue] of map) {
      if (found) {
        if (otherValue.type?.value !== 'HFMS' && otherValue.type?.value !== 'market' && otherValue.type?.value !== 'master_employee' && otherValue.type?.value !== 'master_departments' && otherValue.type?.value !== 'master_client') keysAfter.push(otherKey);
      }

      if (otherKey === key) {
        found = true;
      }
    }

    return keysAfter;
  }

  useEffect(() => {
    const combination = getCombination(accordionID, combinationID);
    if (combination) {
      if (combination.resetKey) {
        updateCombinationAndEmptyTransactions(accordionID, combinationID, { resetKey: false });
        handleSelectedFormulaChange(selectedFormula);
      }
    }
  }, [dataAccesses]);

  return (
    <MDBox py={2} key={combinationID} style={{ position: 'relative' }}>
      <CombinationTypeSelect
        combinationsData={combinationsData}
        setCombinationVal={setCombinationVal}
        getCombinationsList={getCombinationsList}
        accordionID={accordionID}
        userDataAccess={userDataAccess}
        combinationVal={combinationVal}
      />
      <FormulaCheckboxes
        combinationVal={combinationVal}
        selectedFormula={selectedFormula}
        setSelectedFormula={handleSelectedFormulaChange}
      />
      <CheckboxTreeContainer
        combinationVal={combinationVal?.value}
        treeDataSource={treeDataSource}
        nodeChecked={nodeChecked}
        handleNodesChecked={handleNodesChecked}
      />
    </MDBox>
  );
};

export default DataAccessCombination;
