export const SET_SEGMENTS = "SET_SEGMENTS";
export const SET_SEGMENTS_COLUMNS = "SET_SEGMENTS_COLUMNS";
export const SET_VALUES = "SET_VALUES";
export const SET_COLORS = "SET_COLORS";
export const SET_STRUCTURE_MANAGEMENT = "SET_STRUCTURE_MANAGEMENT";
export const SET_STRUCTURE_MANAGEMENT_TABS = "SET_STRUCTURE_MANAGEMENT_TABS";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_USER_ROLES_COLUMNS = "SET_USER_ROLES_COLUMNS";
export const SET_ROUTES = "SET_ROUTES";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_PAGES_WITH_PERMISSIONS = "SET_PAGES_WITH_PERMISSIONS";
export const SET_WORKFLOWS = "SET_WORKFLOWS";
export const SET_WORKFLOWS_COLUMNS = "SET_WORKFLOWS_COLUMNS";
export const SET_DATA_ACCESS = "SET_DATA_ACCESS";
export const SET_USERS = "SET_USERS";
export const SET_ROLES = "SET_ROLES";
export const SET_ROLES_COLUMNS = "SET_ROLES_COLUMNS";
export const SET_JD_PAGE = "SET_JD_PAGE";
export const SET_JD_PAGE_LEVELS = "SET_JD_PAGE_LEVELS";
export const SET_MANAGE_JOBS = "SET_MANAGE_JOBS";
export const SET_USER_DATA_ACCESS = "SET_USER_DATA_ACCESS"
export const SET_DATA_TO_CREATE_DATA_ACCESS = "SET_DATA_TO_CREATE_DATA_ACCESS"
export const SET_HOMEPAGE_TASKS = "SET_HOMEPAGE_TASKS"
export const SET_USER_LEVEL_ACCESS = "SET_USER_LEVEL_ACCESS"
export const SET_EDIT_DATA_ACCESS = "SET_EDIT_DATA_ACCESS"
export const SET_EDIT_DATA_OF_DATA_ACCESS = "SET_EDIT_DATA_OF_DATA_ACCESS"
export const SET_REVISIONS = "SET_REVISIONS"
export const SET_DASHBOARD_OBJECT = "SET_DASHBOARD_OBJECT"
export const SET_NAV_LISTING = "SET_NAV_LISTING"
export const SET_MODULES = "SET_MODULES"
export const SET_ROLE_PERMISSIONS = "SET_ROLE_PERMISSIONS"
export const SET_APPROVALS = "SET_APPROVALS"
export const SET_ELEMENTS = "SET_ELEMENTS"
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
export const SET_USER_FUNCTIONAL_ACCESS = "SET_USER_FUNCTIONAL_ACCESS"
export const SET_USER_WORK_FLOWS = "SET_USER_WORK_FLOWS"
export const SET_IS_LOADING = "SET_IS_LOADING"
export const SET_HC_REQUEST_TYPE = "SET_HC_REQUEST_TYPE"
export const SET_HC_REQUEST = "SET_HC_REQUEST"
export const SET_HC_REQ_DIVISION = "SET_HC_REQ_DIVISION"
export const SET_HC_REQ_ENTITY = "SET_HC_REQ_ENTITY"
export const SET_HC_REQ_COUNTRY = "SET_HC_REQ_COUNTRY"
export const SET_HC_REQ_VERTICAL = "SET_HC_REQ_VERTICAL"
export const SET_HC_REQ_FUNCTION = "SET_HC_REQ_FUNCTION"
export const SET_HC_REQ_LEVEL = "SET_HC_REQ_LEVEL"
export const SET_HC_JOB_NAME = "SET_HC_JOB_NAME"
export const SET_HC_BILLABLE_COST = "SET_HC_BILLABLE_COST"
export const SET_WORKFLOW_STEP_TABLE_DATA = "SET_WORKFLOW_STEP_TABLE_DATA"
export const SET_EDIT_WF_STEP = "SET_EDIT_WF_STEP"
export const SET_EDIT_DATA_OF_WF_STEP = "SET_EDIT_DATA_OF_WF_STEP"
export const SET_HC_WORKFLOWS = "SET_HC_WORKFLOWS"
export const SET_HC_LEAVER = "SET_HC_LEAVER"
export const SET_GROUPS = "SET_GROUPS"
export const SET_FA_VARIABLE = "SET_FA_VARIABLE"
export const SET_NEW_WF_STEP = "SET_NEW_WF_STEP"
export const SET_NEW_FA_VARIABLE = "SET_NEW_FA_VARIABLE"
export const SET_HC_JD_VIEW = "SET_HC_JD_VIEW"
export const SET_JD_VERSIONS = "SET_JD_VERSIONS"
export const SET_EMPLOYEE = "SET_EMPLOYEE"
export const SET_RECRUITERS = "SET_RECRUITERS";
export const SET_RECRUITMENT_STATUS = "SET_RECRUITMENT_STATUS";
export const SET_ATTRIBUTE_MANAGEMENT = "SET_ATTRIBUTE_MANAGEMENT";
export const SET_ATTRIBUTE_MANAGEMENT_LIST = "SET_ATTRIBUTE_MANAGEMENT_LIST";
export const SET_JOB_TYPE = "SET_JOB_TYPE";
export const SET_REQUEST_TYPE = "SET_REQUEST_TYPE";
export const SET_HEADCOUNT_TYPE = "SET_HEADCOUNT_TYPE";
export const SET_TASKS = "SET_TASKS";
export const SET_CLONED_HCS = "SET_CLONED_HCS";
export const SET_All_HEADCOUNTS = "SET_All_HEADCOUNTS";
export const SET_LIGHTS = "SET_LIGHTS";
export const SET_CV_CATEGORIES = "SET_CV_CATEGORIES";
export const SET_CLIENT_VITAL = "SET_CLIENT_VITAL";
export const SET_CLIENT_VITALS = "SET_CLIENT_VITALS";
export const SET_MANAGE_NOTIFICATIONS = "SET_MANAGE_NOTIFICATIONS";
export const SET_MANAGE_ENTITY_CLIENTS = "SET_MANAGE_ENTITY_CLIENTS";
export const SHOW_NOTIFICATIONS_MODAL = "SHOW_NOTIFICATIONS_MODAL";
export const SHOW_PROFILE_MODAL = "SHOW_PROFILE_MODAL";
export const SET_All_TRANSFERS = "SET_All_TRANSFERS";
export const SET_TRANSFERS = "SET_TRANSFERS";
export const SET_COLUMN_PREFERENCES = "SET_COLUMN_PREFERENCES";
export const SET_QUICK_LINKS = "SET_QUICK_LINKS";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_PENDING_TASK = "SET_PENDING_TASK";
export const SET_HEADER_MENU = "SET_HEADER_MENU";
export const SET_RECENT_ACTIVITY = "SET_RECENT_ACTIVITY";
export const SET_MY_ACCOUNT = "SET_MY_ACCOUNT";
export const SET_PROFILE_SETTINGS_ROUTE = "SET_PROFILE_SETTINGS_ROUTE";
export const SET_IS_PROFILE_PICTURE = "SET_IS_PROFILE_PICTURE";
