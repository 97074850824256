import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TreeView from 'devextreme-react/tree-view';
import { Context } from 'utils/context/store/Store';

const findItemByPath = (items, path) => {
  for (const item of items) {
    if (item.route === path) {
      return item;
    }
    if (item.items && item.items.length > 0) {
      const found = findItemByPath(item.items, path);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const TreeStructure = ({ title = 'Settings' }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState([]);
  const [{ settings }, dispatch] = useContext(Context);

  const selectItem = useCallback(
    (e) => {
      setSelectedItem([e.itemData.id]);
      if (e.itemData.route) {
        navigate(e.itemData.route);
      } else {
        navigate('/views/settings');
      }
    },
    [setSelectedItem]
  );

  useEffect(() => {
    const currentItem = findItemByPath(settings, location.pathname);
    setSelectedItem(currentItem ? [currentItem.id] : []);
  }, [location.pathname]);

  return (
    <div className="__tree__navigation__container__">
      <h1>{title}</h1>
      <TreeView
        className="__treeview__navigation__"
        items={settings}
        width={300}
        onItemClick={selectItem}
        itemRender={(item) => <h4 className="__mb__0">{item.name}</h4>}
        keyExpr="key"
        itemsExpr="collapse"
        displayExpr="name"
        selectionMode="single"
        selectByClick
        selectedItemKeys={selectedItem}
      />
      <div>{selectedItem && selectedItem[0]}</div>
    </div>
  );
};

export default TreeStructure;
