import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

const getSubMenu = (
  { name, collapse = null, isVisible = true, route },
  setMenu,
  activeRoute,
  handleCloseMenuForMobile
) => {
  return (
    isVisible && (
      <div className={`sub__nav__list`}>
        {collapse ? (
          <Fragment>
            {collapse && <span className="sub__nav__title">{name}</span>}
            {collapse &&
              collapse.map((menu, i) => {
                const { isVisible = true, route, name, collapse } = menu;
                const isActiveRoute = activeRoute === route || activeRoute === `/${route}`;
                return (
                  isVisible && (
                    <Fragment key={`sub__nav__items__${menu.key}__${i}`}>
                      {route && (
                        <span
                          className={`sub__nav__items ${
                            isActiveRoute ? 'sub__nav__items__active' : ''
                          }`}
                        >
                          <NavLink
                            to={route}
                            className="nav__link"
                            onClick={() => {
                              setMenu([]);
                              handleCloseMenuForMobile();
                            }}
                          >
                            {name}
                          </NavLink>
                        </span>
                      )}
                      {collapse && getSubMenu(menu, setMenu, activeRoute)}
                    </Fragment>
                  )
                );
              })}
          </Fragment>
        ) : (
          route && (
            <span
              className={`sub__nav__items ${
                activeRoute === route || activeRoute === `/${route}`
                  ? 'sub__nav__items__active'
                  : ''
              }`}
            >
              <NavLink
                to={route}
                className="nav__link"
                onClick={() => {
                  setMenu([]);
                  handleCloseMenuForMobile();
                }}
              >
                {name}
              </NavLink>
            </span>
          )
        )}
      </div>
    )
  );
};

const Menu = ({ menu, setMenu, activeRoute, handleCloseMenuForMobile = () => {} }) => {
  return (
    <div id="__main__menu__id" className="__menu__container__">
      {menu.map((e, i) => (
        <Fragment key={`sub__nav__menu__${e.key}`}>
          {getSubMenu(e, setMenu, activeRoute, handleCloseMenuForMobile)}
        </Fragment>
      ))}
    </div>
  );
};

export default Menu;
