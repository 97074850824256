export const handleActionsEnum = {
  HFM: 'hfm',
  CLIENT: 'client',
  YEAR_1: 'year_1',
  YEAR_2: 'year_2',
  CURRENCY: 'currency',
  VERSION_1: 'version_1',
  VERSION_2: 'version_2'
};

export const radioOptions = [{ value: 'single', label: 'Single View' }, { value: 'byYear', label: 'Comparison by Year' }, { value: 'byVersion', label: 'Comparison by Version' }];

export const years = ['2024'];

export const currency = [
  {
    ID: 1,
    Name: 'EUR'
  },
  {
    ID: 2,
    Name: 'USD'
  },
  {
    ID: 3,
    Name: 'AED'
  }
];

export const hfm = [
  {
    ID: 1,
    Name: 'HFM1'
  },
  {
    ID: 2,
    Name: 'HFM2'
  },
  {
    ID: 3,
    Name: 'HFM3'
  }
];

export const client = [
  {
    ID: 1,
    Name: 'Client 1'
  },
  {
    ID: 2,
    Name: 'Client 2'
  },
  {
    ID: 3,
    Name: 'Client 3'
  }
];

export const headerLevels = [
  {
    id: 1,
    label: 'Entity',
    text: '',
    key: null
  },
  {
    id: 2,
    label: 'Revenue Type',
    text: '',
    key: null
  },
  {
    id: 3,
    label: 'Budget Planning',
    text: '',
    key: null
  }
];

export const disableHeadings = ['level_1', 'level_2', 'level_3', 'total', 'total_1'];

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
  'Total FY'
];

export const __monthsKey = {
  jan: null,
  feb: null,
  march: null,
  april: null,
  may: null,
  june: null,
  july: null,
  aug: null,
  sep: null,
  oct: null,
  nov: null,
  dec: null,
  total: null
};

export const __futureMonthsKey = {
  jan_1: null,
  feb_A_1: null,
  feb_F_1: null,
  march_A_1: null,
  march_F_1: null,
  april_1: null,
  may_1: null,
  june_1: null,
  july_1: null,
  aug_1: null,
  sep_1: null,
  oct_1: null,
  nov_1: null,
  dec_1: null,
  total_1: null
};

export const __futureHeader = [
  [{ label: 2025, colspan: 16 }],
  [
    { label: 'Q1', colspan: 5 },
    { label: 'Q2', colspan: 3 },
    { label: 'Q3', colspan: 3 },
    { label: 'Q4', colspan: 3 },
    null
  ],
  [
    'Jan',
    { label: 'Feb', colspan: 2 },
    { label: 'Mar', colspan: 2 },
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Total FY'
  ],
  [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    'A',
    'A',
    'F',
    'A',
    'F',
    'F',
    'F',
    'F',
    'F',
    'F',
    'F',
    'F',
    'F',
    'F',
    'A+F'
  ]
];

export const __headers = [
  [null, null, null, { label: 2024, colspan: 13 }],
  [
    null,
    null,
    'Revenue',
    { label: 'Q1', colspan: 3 },
    { label: 'Q2', colspan: 3 },
    { label: 'Q3', colspan: 3 },
    { label: 'Q4', colspan: 3 },
    null
  ],
  ['Practice', 'Type', 'Budget line description', ...months]
];

export const __children = [
  {
    level_2: 'Net to Media',
    __children: [
      {
        level_3: 'Analogue',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D3:O3)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q3:AD3)'
      },
      {
        level_3: 'Display',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D4:O4)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q4:AD4)'
      },
      {
        level_3: 'Video',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D5:O5)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q5:AD5)'
      },
      {
        level_3: 'Mobile',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D6:O6)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q6:AD6)'
      },
      {
        level_3: 'Non-AOD programmatic',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D7:O7)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q7:AD7)'
      },
      {
        level_3: 'Total',
        jan: '=SUM(D3:D7)',
        feb: '=SUM(E3:E7)',
        march: '=SUM(F3:F7)',
        april: '=SUM(G3:G7)',
        may: '=SUM(H3:H7)',
        june: '=SUM(I3:I7)',
        july: '=SUM(J3:J7)',
        aug: '=SUM(K3:K7)',
        sep: '=SUM(L3:L7)',
        oct: '=SUM(M3:M7)',
        nov: '=SUM(N3:N7)',
        dec: '=SUM(O3:O7)',
        total: '=SUM(D8:O8)',
        jan_1: '=SUM(Q3:Q7)',
        feb_A_1: '=SUM(R3:R7)',
        feb_F_1: '=SUM(S3:S7)',
        march_A_1: '=SUM(T3:T7)',
        march_F_1: '=SUM(U3:U7)',
        april_1: '=SUM(V3:V7)',
        may_1: '=SUM(W3:W7)',
        june_1: '=SUM(X3:X7)',
        july_1: '=SUM(Y3:Y7)',
        aug_1: '=SUM(Z3:Z7)',
        sep_1: '=SUM(AA3:AA7)',
        oct_1: '=SUM(AB3:AB7)',
        nov_1: '=SUM(AC3:AC7)',
        dec_1: '=SUM(AD3:AD7)',
        total_1: '=SUM(Q8:AD8)'
      }
    ]
  },
  {
    level_2: 'Net to Media',
    __children: [
      {
        level_3: 'Analogue',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D11:O11)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q11:AD11)'
      },
      {
        level_3: 'Display',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D12:O12)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q12:AD12)'
      },
      {
        level_3: 'Video',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D13:O13)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q13:AD13)'
      },
      {
        level_3: 'Mobile',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D14:O14)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q14:AD14)'
      },
      {
        level_3: 'Non-AOD programmatic',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D15:O15)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q15:AD15)'
      },
      {
        level_3: 'Total',
        jan: '=SUM(D11:D15)',
        feb: '=SUM(E11:E15)',
        march: '=SUM(F11:F15)',
        april: '=SUM(G11:G15)',
        may: '=SUM(H11:H15)',
        june: '=SUM(I11:I15)',
        july: '=SUM(J11:J15)',
        aug: '=SUM(K11:K15)',
        sep: '=SUM(L11:L15)',
        oct: '=SUM(M11:M15)',
        nov: '=SUM(N11:N15)',
        dec: '=SUM(O11:O15)',
        total: '=SUM(D16:O16)',
        jan_1: '=SUM(Q11:Q15)',
        feb_A_1: '=SUM(R11:R15)',
        feb_F_1: '=SUM(S11:S15)',
        march_A_1: '=SUM(T11:T15)',
        march_F_1: '=SUM(U11:U15)',
        april_1: '=SUM(V11:V15)',
        may_1: '=SUM(W11:W15)',
        june_1: '=SUM(X11:X15)',
        july_1: '=SUM(Y11:Y15)',
        aug_1: '=SUM(Z11:Z15)',
        sep_1: '=SUM(AA11:AA15)',
        oct_1: '=SUM(AB11:AB15)',
        nov_1: '=SUM(AC11:AC15)',
        dec_1: '=SUM(AD11:AD15)',
        total_1: '=SUM(Q16:AD16)'
      }
    ]
  },
  {
    level_2: 'Net to Media',
    __children: [
      {
        level_3: 'Analogue',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D19:O19)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q19:AD19)'
      },
      {
        level_3: 'Display',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D20:O20)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q20:AD20)'
      },
      {
        level_3: 'Video',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D21:O21)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q21:AD21)'
      },
      {
        level_3: 'Mobile',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D22:O22)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q22:AD22)'
      },
      {
        level_3: 'Non-AOD programmatic',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D23:O23)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q23:AD23)'
      },
      {
        level_3: 'Total',
        jan: '=SUM(D19:D23)',
        feb: '=SUM(E19:E23)',
        march: '=SUM(F19:F23)',
        april: '=SUM(G19:G23)',
        may: '=SUM(H19:H23)',
        june: '=SUM(I19:I23)',
        july: '=SUM(J19:J23)',
        aug: '=SUM(K19:K23)',
        sep: '=SUM(L19:L23)',
        oct: '=SUM(M19:M23)',
        nov: '=SUM(N19:N23)',
        dec: '=SUM(O19:O23)',
        total: '=SUM(D24:O24)',
        jan_1: '=SUM(Q19:Q23)',
        feb_A_1: '=SUM(R19:R23)',
        feb_F_1: '=SUM(S19:S23)',
        march_A_1: '=SUM(T19:T23)',
        march_F_1: '=SUM(U19:U23)',
        april_1: '=SUM(V19:V23)',
        may_1: '=SUM(W19:W23)',
        june_1: '=SUM(X19:X23)',
        july_1: '=SUM(Y19:Y23)',
        aug_1: '=SUM(Z19:Z23)',
        sep_1: '=SUM(AA19:AA23)',
        oct_1: '=SUM(AB19:AB23)',
        nov_1: '=SUM(AC19:AC23)',
        dec_1: '=SUM(AD19:AD23)',
        total_1: '=SUM(Q24:AD24)'
      }
    ]
  },
  {
    level_2: 'Net to Media',
    __children: [
      {
        level_3: 'Analogue',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D27:O27)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q27:AD27)'
      },
      {
        level_3: 'Display',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D28:O28)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q28:AD28)'
      },
      {
        level_3: 'Video',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D29:O29)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q29:AD29)'
      },
      {
        level_3: 'Mobile',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D30:O30)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q30:AD30)'
      },
      {
        level_3: 'Non-AOD programmatic',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D31:O31)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q31:AD31)'
      },
      {
        level_3: 'Total',
        jan: '=SUM(D27:D31)',
        feb: '=SUM(E27:E31)',
        march: '=SUM(F27:F31)',
        april: '=SUM(G27:G31)',
        may: '=SUM(H27:H31)',
        june: '=SUM(I27:I31)',
        july: '=SUM(J27:J31)',
        aug: '=SUM(K27:K31)',
        sep: '=SUM(L27:L31)',
        oct: '=SUM(M27:M31)',
        nov: '=SUM(N27:N31)',
        dec: '=SUM(O27:O31)',
        total: '=SUM(D32:O32)',
        jan_1: '=SUM(Q27:Q31)',
        feb_A_1: '=SUM(R27:R31)',
        feb_F_1: '=SUM(S27:S31)',
        march_A_1: '=SUM(T27:T31)',
        march_F_1: '=SUM(U27:U31)',
        april_1: '=SUM(V27:V31)',
        may_1: '=SUM(W27:W31)',
        june_1: '=SUM(X27:X31)',
        july_1: '=SUM(Y27:Y31)',
        aug_1: '=SUM(Z27:Z31)',
        sep_1: '=SUM(AA27:AA31)',
        oct_1: '=SUM(AB27:AB31)',
        nov_1: '=SUM(AC27:AC31)',
        dec_1: '=SUM(AD27:AD31)',
        total_1: '=SUM(Q32:AD32)'
      }
    ]
  },
  {
    level_2: 'Net to Media',
    __children: [
      {
        level_3: 'Analogue',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D35:O35)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q35:AD35)'
      },
      {
        level_3: 'Display',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D36:O36)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q36:AD36)'
      },
      {
        level_3: 'Video',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D37:O37)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q37:AD37)'
      },
      {
        level_3: 'Mobile',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D38:O38)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q38:AD38)'
      },
      {
        level_3: 'Non-AOD programmatic',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D39:O39)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q39:AD39)'
      },
      {
        level_3: 'Total',
        jan: '=SUM(D35:D39)',
        feb: '=SUM(E35:E39)',
        march: '=SUM(F35:F39)',
        april: '=SUM(G35:G39)',
        may: '=SUM(H35:H39)',
        june: '=SUM(I35:I39)',
        july: '=SUM(J35:J39)',
        aug: '=SUM(K35:K39)',
        sep: '=SUM(L35:L39)',
        oct: '=SUM(M35:M39)',
        nov: '=SUM(N35:N39)',
        dec: '=SUM(O35:O39)',
        total: '=SUM(D40:O40)',
        jan_1: '=SUM(Q35:Q39)',
        feb_A_1: '=SUM(R35:R39)',
        feb_F_1: '=SUM(S35:S39)',
        march_A_1: '=SUM(T35:T39)',
        march_F_1: '=SUM(U35:U39)',
        april_1: '=SUM(V35:V39)',
        may_1: '=SUM(W35:W39)',
        june_1: '=SUM(X35:X39)',
        july_1: '=SUM(Y35:Y39)',
        aug_1: '=SUM(Z35:Z39)',
        sep_1: '=SUM(AA35:AA39)',
        oct_1: '=SUM(AB35:AB39)',
        nov_1: '=SUM(AC35:AC39)',
        dec_1: '=SUM(AD35:AD39)',
        total_1: '=SUM(Q40:AD40)'
      }
    ]
  },
  {
    level_2: 'Net to Media',
    __children: [
      {
        level_3: 'Analogue',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D43:O43)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q43:AD43)'
      },
      {
        level_3: 'Display',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D44:O44)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q44:AD44)'
      },
      {
        level_3: 'Video',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D45:O45)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q45:AD45)'
      },
      {
        level_3: 'Mobile',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D46:O46)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q46:AD46)'
      },
      {
        level_3: 'Non-AOD programmatic',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D47:O47)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q47:AD47)'
      },
      {
        level_3: 'Total',
        jan: '=SUM(D43:D47)',
        feb: '=SUM(E43:E47)',
        march: '=SUM(F43:F47)',
        april: '=SUM(G43:G47)',
        may: '=SUM(H43:H47)',
        june: '=SUM(I43:I47)',
        july: '=SUM(J43:J47)',
        aug: '=SUM(K43:K47)',
        sep: '=SUM(L43:L47)',
        oct: '=SUM(M43:M47)',
        nov: '=SUM(N43:N47)',
        dec: '=SUM(O43:O47)',
        total: '=SUM(D48:O48)',
        jan_1: '=SUM(Q43:Q47)',
        feb_A_1: '=SUM(R43:R47)',
        feb_F_1: '=SUM(S43:S47)',
        march_A_1: '=SUM(T43:T47)',
        march_F_1: '=SUM(U43:U47)',
        april_1: '=SUM(V43:V47)',
        may_1: '=SUM(W43:W47)',
        june_1: '=SUM(X43:X47)',
        july_1: '=SUM(Y43:Y47)',
        aug_1: '=SUM(Z43:Z47)',
        sep_1: '=SUM(AA43:AA47)',
        oct_1: '=SUM(AB43:AB47)',
        nov_1: '=SUM(AC43:AC47)',
        dec_1: '=SUM(AD43:AD47)',
        total_1: '=SUM(Q48:AD48)'
      }
    ]
  },
  {
    level_2: 'Net to Media',
    __children: [
      {
        level_3: 'Analogue',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D51:O51)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q51:AD51)'
      },
      {
        level_3: 'Display',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D52:O52)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q52:AD52)'
      },
      {
        level_3: 'Video',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D53:O53)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q53:AD53)'
      },
      {
        level_3: 'Mobile',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D54:O54)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q54:AD54)'
      },
      {
        level_3: 'Non-AOD programmatic',
        jan: null,
        feb: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
        total: '=SUM(D55:O55)',
        ...__futureMonthsKey,
        total_1: '=SUM(Q55:AD55)'
      },
      {
        level_3: 'Total',
        jan: '=SUM(D51:D55)',
        feb: '=SUM(E51:E55)',
        march: '=SUM(F51:F55)',
        april: '=SUM(G51:G55)',
        may: '=SUM(H51:H55)',
        june: '=SUM(I51:I55)',
        july: '=SUM(J51:J55)',
        aug: '=SUM(K51:K55)',
        sep: '=SUM(L51:L55)',
        oct: '=SUM(M51:M55)',
        nov: '=SUM(N51:N55)',
        dec: '=SUM(O51:O55)',
        total: '=SUM(D56:O56)',
        jan_1: '=SUM(Q51:Q55)',
        feb_A_1: '=SUM(R51:R55)',
        feb_F_1: '=SUM(S51:S55)',
        march_A_1: '=SUM(T51:T55)',
        march_F_1: '=SUM(U51:U55)',
        april_1: '=SUM(V51:V55)',
        may_1: '=SUM(W51:W55)',
        june_1: '=SUM(X51:X55)',
        july_1: '=SUM(Y51:Y55)',
        aug_1: '=SUM(Z51:Z55)',
        sep_1: '=SUM(AA51:AA55)',
        oct_1: '=SUM(AB51:AB55)',
        nov_1: '=SUM(AC51:AC55)',
        dec_1: '=SUM(AD51:AD55)',
        total_1: '=SUM(Q56:AD56)'
      }
    ]
  }
];

export const __levels = [
  {
    level_1: 'Core Planning and Buying',
    level_2: null,
    level_3: null
  },
  {
    level_1: 'Content',
    level_2: null,
    level_3: null
  },
  {
    level_1: 'Performance',
    level_2: null,
    level_3: null
  },
  {
    level_1: 'Investments',
    level_2: null,
    level_3: null
  },
  {
    level_1: 'Data and Technology',
    level_2: null,
    level_3: null
  },
  {
    level_1: 'Analytics and Insights',
    level_2: null,
    level_3: null
  },
  {
    level_1: 'E-commerce',
    level_2: null,
    level_3: null
  }
];

export const data = [
  {
    level_1: 'Core Planning and Buying',
    level_2: null,
    level_3: null,
    ...__monthsKey,
    __children: [
      {
        level_2: 'Net to Media',
        __children: [
          {
            level_3: 'Analogue',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Display',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Video',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Mobile',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Non-AOD programmatic',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Total',
            jan: 60,
            feb: 60,
            march: 60,
            april: 60,
            may: 60,
            june: 60,
            july: 60,
            aug: 60,
            sep: 60,
            oct: 60,
            nov: 60,
            dec: 60,
            total: 720
          }
        ]
      }
    ]
  },
  {
    level_1: 'Core Planning and Buying',
    level_2: null,
    level_3: null,
    ...__monthsKey,
    __children: [
      {
        level_2: 'Net to Media',
        __children: [
          {
            level_3: 'Analogue',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Display',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Video',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Mobile',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Non-AOD programmatic',
            jan: 10,
            feb: 10,
            march: 10,
            april: 10,
            may: 10,
            june: 10,
            july: 10,
            aug: 10,
            sep: 10,
            oct: 10,
            nov: 10,
            dec: 10,
            total: 120
          },
          {
            level_3: 'Total',
            jan: 60,
            feb: 60,
            march: 60,
            april: 60,
            may: 60,
            june: 60,
            july: 60,
            aug: 60,
            sep: 60,
            oct: 60,
            nov: 60,
            dec: 60,
            total: 720
          }
        ]
      }
    ]
  }
];

export const __ffData = {
  headers: {
    history: [
      [null, null, null, { label: 2024, colspan: 13 }],
      [
        null,
        null,
        null,
        { label: 'Q1', colspan: 3 },
        { label: 'Q2', colspan: 3 },
        { label: 'Q3', colspan: 3 },
        { label: 'Q4', colspan: 3 },
        null
      ],
      ['Practice', 'Type', 'Budget line description', ...months]
    ],
    forecast: [
      [null, null, null, { label: 2025, colspan: 15 }, { label: 2024, colspan: 13 }],
      [
        null,
        null,
        null,
        { label: 'Q1', colspan: 5 },
        { label: 'Q2', colspan: 3 },
        { label: 'Q3', colspan: 3 },
        { label: 'Q4', colspan: 3 },
        null,
        { label: 'Q1', colspan: 3 },
        { label: 'Q2', colspan: 3 },
        { label: 'Q3', colspan: 3 },
        { label: 'Q4', colspan: 3 },
        null
      ],
      [
        null,
        null,
        null,
        'Jan',
        { label: 'Feb', colspan: 2 },
        { label: 'Mar', colspan: 2 },
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        'Total FY',
        ...months
      ],
      [
        null,
        null,
        null,
        'A',
        'A',
        'F',
        'A',
        'F',
        'F',
        'F',
        'F',
        'F',
        'F',
        'F',
        'F',
        'F',
        'F',
        'A+F',
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ]
    ]
  },
  dataSource: {
    history: [
      {
        A: 'Core Planning and Buying',
        B: null,
        C: null,
        D: '=SUM(D2)',
        E: '=SUM(E2)',
        F: '=SUM(F2)',
        G: '=SUM(G2)',
        H: '=SUM(H2)',
        I: '=SUM(I2)',
        J: '=SUM(J2)',
        K: '=SUM(K2)',
        L: '=SUM(L2)',
        M: '=SUM(M2)',
        N: '=SUM(N2)',
        O: '=SUM(O2)',
        P: '=SUM(D1:O1)',
        __children: [
          {
            A: null,
            B: 'Net to Media',
            C: null,
            D: '=SUM(D3:D7)',
            E: '=SUM(E3:E7)',
            F: '=SUM(F3:F7)',
            G: '=SUM(G3:G7)',
            H: '=SUM(H3:H7)',
            I: '=SUM(I3:I7)',
            J: '=SUM(J3:J7)',
            K: '=SUM(K3:K7)',
            L: '=SUM(L3:L7)',
            M: '=SUM(M3:M7)',
            N: '=SUM(N3:N7)',
            O: '=SUM(O3:O7)',
            P: '=SUM(D2:O2)',
            __children: [
              {
                A: null,
                B: null,
                C: 'Analogue',
                D: 10,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: '=SUM(D3:O3)'
              },
              {
                A: null,
                B: null,
                C: 'Display',
                D: null,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: '=SUM(D4:O4)'
              },
              {
                A: null,
                B: null,
                C: 'Video',
                D: null,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: '=SUM(D5:O5)'
              },
              {
                A: null,
                B: null,
                C: 'Mobile',
                D: null,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: '=SUM(D6:O6)'
              },
              {
                A: null,
                B: null,
                C: 'Non-AOD programmatic',
                D: null,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: '=SUM(D7:O7)'
              }
            ]
          }
        ]
      }
    ],
    forecast: [
      {
        A: 'Core Planning and Buying',
        B: null,
        C: null,
        D: '=SUM(D2)',
        E: '=SUM(E2)',
        F: '=SUM(F2)',
        G: '=SUM(G2)',
        H: '=SUM(H2)',
        I: '=SUM(I2)',
        J: '=SUM(J2)',
        K: '=SUM(K2)',
        L: '=SUM(L2)',
        M: '=SUM(M2)',
        N: '=SUM(N2)',
        O: '=SUM(O2)',
        P: '=SUM(P2)',
        Q: '=SUM(Q2)',
        R: '=SUM(D1:Q1)',
        S: '=SUM(S2)',
        T: '=SUM(T2)',
        U: '=SUM(U2)',
        V: '=SUM(V2)',
        W: '=SUM(W2)',
        X: '=SUM(X2)',
        Y: '=SUM(Y2)',
        Z: '=SUM(Z2)',
        AA: '=SUM(AA2)',
        AB: '=SUM(AB2)',
        AC: '=SUM(AC2)',
        AD: '=SUM(AD2)',
        AE: '=SUM(S1:AD1)',
        __children: [
          {
            A: null,
            B: 'Net to Media',
            C: null,
            D: '=SUM(D3:D7)',
            E: '=SUM(E3:E7)',
            F: '=SUM(F3:F7)',
            G: '=SUM(G3:G7)',
            H: '=SUM(H3:H7)',
            I: '=SUM(I3:I7)',
            J: '=SUM(J3:J7)',
            K: '=SUM(K3:K7)',
            L: '=SUM(L3:L7)',
            M: '=SUM(M3:M7)',
            N: '=SUM(N3:N7)',
            O: '=SUM(O3:O7)',
            P: '=SUM(P3:P7)',
            Q: '=SUM(Q3:Q7)',
            R: '=SUM(D2:Q2)',
            S: '=SUM(S3:S7)',
            T: '=SUM(T3:T7)',
            U: '=SUM(U3:U7)',
            V: '=SUM(V3:V7)',
            W: '=SUM(W3:W7)',
            X: '=SUM(X3:X7)',
            Y: '=SUM(Y3:Y7)',
            Z: '=SUM(Z3:Z7)',
            AA: '=SUM(AA3:AA7)',
            AB: '=SUM(AB3:AB7)',
            AC: '=SUM(AC3:AC7)',
            AD: '=SUM(AD3:AD7)',
            AE: '=SUM(S2:AD2)',
            __children: [
              {
                A: null,
                B: null,
                C: 'Analogue',
                D: null,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: null,
                Q: null,
                R: '=SUM(D3:Q3)',
                S: null,
                T: null,
                U: null,
                V: null,
                W: null,
                X: null,
                Y: null,
                Z: null,
                AA: null,
                AB: null,
                AC: null,
                AD: null,
                AE: '=SUM(S3:AD3)'
              },
              {
                A: null,
                B: null,
                C: 'Display',
                D: null,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: null,
                Q: null,
                R: '=SUM(D4:Q4)',
                S: null,
                T: null,
                U: null,
                V: null,
                W: null,
                X: null,
                Y: null,
                Z: null,
                AA: null,
                AB: null,
                AC: null,
                AD: null,
                AE: '=SUM(S4:AD4)'
              },
              {
                A: null,
                B: null,
                C: 'Video',
                D: null,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: null,
                Q: null,
                R: '=SUM(D5:Q5)',
                S: null,
                T: null,
                U: null,
                V: null,
                W: null,
                X: null,
                Y: null,
                Z: null,
                AA: null,
                AB: null,
                AC: null,
                AD: null,
                AE: '=SUM(S5:AD5)'
              },
              {
                A: null,
                B: null,
                C: 'Mobile',
                D: null,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: null,
                Q: null,
                R: '=SUM(D6:Q6)',
                S: null,
                T: null,
                U: null,
                V: null,
                W: null,
                X: null,
                Y: null,
                Z: null,
                AA: null,
                AB: null,
                AC: null,
                AD: null,
                AE: '=SUM(S6:AD6)'
              },
              {
                A: null,
                B: null,
                C: 'Non-AOD programmatic',
                D: null,
                E: null,
                F: null,
                G: null,
                H: null,
                I: null,
                J: null,
                K: null,
                L: null,
                M: null,
                N: null,
                O: null,
                P: null,
                Q: null,
                R: '=SUM(D7:Q7)',
                S: null,
                T: null,
                U: null,
                V: null,
                W: null,
                X: null,
                Y: null,
                Z: null,
                AA: null,
                AB: null,
                AC: null,
                AD: null,
                AE: '=SUM(S7:AD7)'
              }
            ]
          }
        ]
      }
    ]
  },
  twoDimensionalDataSource: {
    history: [
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ]
    ],
    forecast: [
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ],
      [
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: true, type: 'text' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: false, type: 'numeric' },
        { readOnly: true, type: 'numeric' }
      ]
    ]
  }
};
// Jan,Feb,Mar,Apr,May,June,July,Aug,Sep,Oct, Nov, Dec
export const __ffConfig = {
  header: {
    rowLevels: 3,
    nestedLevels: { count: 3, names: ['Practice', 'Type', 'Budget'] },
    row_1: { count: 1 },
    row_2: { count: 4, name: ['Q1', 'Q2', 'Q3', 'Q4'] },
    row_3: {
      count: 12,
      name: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'August',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    total: true
  }
};

export const ODD_ROW_CLASS = 'odd';
