export const validateEntityTypeBudget = (value, prevValues, userDataAccess) => {
  const { masterOrgEntityId, masterFCDetailTypeId, masterFCBudgetLineId } =
    value.formula;

  if (masterOrgEntityId) strategies.entitySelected(value, prevValues, userDataAccess);
  if (masterFCDetailTypeId) strategies.detailTypeSelected(value, prevValues, userDataAccess);
  if (masterFCBudgetLineId) strategies.budgetLineSelected(value, prevValues, userDataAccess);

  return prevValues;
};

const strategies = {
  entitySelected: (value, prevValues, userDataAccess) => {
    const divisionIds = value.transactions.map((e) => e.masterOrgEntityId);
    prevValues["masterOrgEntityId"] = divisionIds;
  },

  detailTypeSelected: (value, prevValues, userDataAccess) => {
    const entityIds = value.transactions.map((e) => e.masterFCDetailTypeId);
    prevValues["masterFCDetailTypeId"] = entityIds;

    const divisionIds = filterAndMap(
      userDataAccess["masterFCDetailTypeId"],
      "masterFCDetailTypeId",
      "masterOrgEntityId",
      entityIds
    );
    prevValues["masterOrgEntityId"] = masterOrgEntityId;
  },

  budgetLineSelected: (value, prevValues, userDataAccess) => {
    const functionsIds = value.transactions.map((e) => e.masterFCBudgetLineId);

    const allFunctions = userDataAccess["masterFCBudgetLineId"].filter((e) =>
      functionsIds.includes(e.masterFCBudgetLineId)
    );
    let verticalIds = [];
    if (value.formula.masterFCDetailTypeId) {
      verticalIds = value.transactions.map((e) => e.masterFCDetailTypeId);
    } else {
      verticalIds = allFunctions.map((e) => e.masterFCDetailTypeId);
    }

    let filteredVerticals = userDataAccess["masterFCDetailTypeId"].filter((e) =>
      verticalIds.includes(e["masterFCDetailTypeId"])
    );

    if (value.formula.masterOrgEntityId) {
      const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
      filteredVerticals = filteredVerticals.filter((e) => entityIds.includes(e.masterOrgEntityId));
    }


    prevValues["masterFCDetailTypeId"] = filteredVerticals.map((e) => e.masterFCDetailTypeId);

    let filteredFunctions = userDataAccess["masterFCBudgetLineId"].filter((e) =>
      prevValues["masterFCDetailTypeId"].includes(e["masterFCDetailTypeId"])
    );

    if (value.formula.masterOrgEntityId) {
      const entityIds = value.transactions.map((e) => e.masterOrgEntityId);
      filteredFunctions = filteredFunctions.filter((e) => entityIds.includes(e.masterOrgEntityId));
    }
    prevValues["masterFCBudgetLineId"] = filteredFunctions.map((e) => e.masterFCBudgetLineId);
    prevValues["masterOrgEntityId"] = filteredFunctions.map((e) => e.masterOrgEntityId);
  },
};

const filterAndMap = (data, filterField, mapField, filterValues) =>
  data.filter((e) => filterValues.includes(e[filterField])).map((e) => e[mapField]);
