import { useContext, useEffect, useState } from 'react';
import { Context } from '../context/store/Store';
import {
  SET_GROUPS,
  SET_HC_LEAVER,
  SET_HC_REQUEST,
  SET_All_HEADCOUNTS,
  SET_IS_LOADING
} from '../context/store/Constants';
import request from '../services/Http';
import { getPageProperties, getTableColumns } from '../services/Helpers';
import { usePagination } from './usePagination';
import { useIsLoading } from './useIsLoading';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function useManageHeadCounts(routeKey) {
  const [{ groups, allHeadcounts, pagesAndPermissions }, dispatch] = useContext(Context);
  const { totalCount, setTotalCount, hasMore, setHasMore, page, setPage, pageSize, setPageSize } =
    usePagination();
  const { isLoading, setIsLoading } = useIsLoading();
  const [headcountDetails, setHeadcountDetails] = useState({});
  const [approvedHCS, setApprovedHCS] = useState({});
  const search = useLocation().search;
  const dgId = new URLSearchParams(search).get('dg');

  useEffect(() => {

    return () => {
      setTotalCount(0);
      setHasMore(true);
      setPage(1);
      setPageSize(10);
      // dispatch({ type: SET_GROUPS, payload: {} })
    };
  }, []);

  async function getAllGroups() {
    dispatch({ type: SET_HC_REQUEST, payload: {} });
    dispatch({ type: SET_HC_LEAVER, payload: {} });
    dispatch({ type: SET_All_HEADCOUNTS, payload: {} });
    localStorage.removeItem('internalEmpData'); // remove the state object from local storage
    if (!isLoading && !hasMore) {
      return;
    }

    dispatch({ type: SET_IS_LOADING, payload: true });
    setIsLoading(true);

    if (groups?.rows?.length <= 0) dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const res = await request.get('group', { params: { page, pageSize } });
      let rows = res.data.data;
      const properties = getPageProperties(pagesAndPermissions, routeKey);
      const table = getTableColumns(properties.page.table, routeKey);
      let columns = table.columns;
      setTotalCount(res.data.totalCount);
      setPage(1);
      if (res.data.totalCount <= pageSize) setHasMore(false);
      if (res.data.data.length === 0) setHasMore(false);

      // const fRows = groups && groups?.rows?.length ? [...groups.rows, ...rows] : rows
      setTimeout(() => {
        dispatch({
          type: SET_GROUPS,
          payload: { rows: [...new Map(rows.map((item) => [item['id'], item])).values()], columns }
        });
      }, 500);

      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getAllHeadCounts(transactionType, skipLoading = false) {
    if (!skipLoading) {
      dispatch({ type: SET_IS_LOADING, payload: true });
      setIsLoading(true);
    }

    // Reset the states
    dispatch({ type: SET_GROUPS, payload: {} });
    setTotalCount(0);
    setHasMore(true);
    setPage(1);

    try {
      const res = await request.get(
        `headcount/request/all-hc${transactionType ? `?transactionType=${transactionType}` : ''}`
      );
      if (res) {
        let rows = res.data.data;
        const properties = getPageProperties(pagesAndPermissions, 'manage-headcounts');
        const table = getTableColumns(properties.page.table, 'all-hcs');
        const leaversColumns = getTableColumns(properties.page.table, 'all-leavers');
        const fields = res.data.fields;
        let columns = table.columns;
        const dropdownValues = res.data.dropdownValues;
        let allHcFilters = properties?.page?.elements?.dropdowns?.allHcFilters;

        if (allHeadcounts && allHeadcounts.hasOwnProperty('apiDelete') && allHeadcounts.apiDelete) {
          rows = [...allHeadcounts.rows, ...rows];
        }

        const dataTableData = {
          columns: columns,
          rows: rows,
          dropdownValues: dropdownValues,
          leaversColumns: leaversColumns,
          fields: fields,
          apiDelete: false,
          allHcFilters
        };
        dispatch({
          type: SET_All_HEADCOUNTS,
          payload: dataTableData
        });
      }
    } catch (e) {}

    if (!skipLoading) {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getAllApprovedHeadCounts() {
    setIsLoading(true);
    try {
      const res = await request.get(`headcount/request/all-approved-hcs`);
      if (res) {
        let rows =
          res.data.data?.sort((a, b) => {
            if (a.sequence === null) return 1;
            if (b.sequence === null) return -1;
            return a.sequence - b.sequence;
          }) ?? [];
        const properties = getPageProperties(pagesAndPermissions, 'manage-headcounts');
        const table = getTableColumns(properties.page.table, 'all-hcs');
        let columns = table.columns;
        const dataTableData = { columns, rows };
        setApprovedHCS(dataTableData);
      }
    } catch (e) {}
    setIsLoading(false);
  }

  async function updateTransactionSequence(transactionType, data) {
    setIsLoading(true);
    try {
      const res = await request.post('headcount/request/update-transaction-sequence', {
        transactionType,
        data
      });
      if (res) {
        toast.success(res.data.data);
        await getAllApprovedHeadCounts();
      }
    } catch (e) {}
    setIsLoading(false);
  }

  async function getHeadCountDetails(
    transactionType,
    transactionId,
    userId = null,
    returnData = false,
    delegatedUserIdFromHc = null
  ) {
    try {
      setHeadcountDetails({});
      const res = await request.post(
        `headcount/request/detail${
          delegatedUserIdFromHc
            ? `?dgId=${delegatedUserIdFromHc}`
            : dgId !== null
            ? `?dgId=${dgId}`
            : ''
        }`,
        { transactionType: transactionType, transactionId: transactionId }
      );
      if (res) {
        let data = res.data.data;
        let hcVersions = res.data.hcVersions?.length ? [data, ...res.data.hcVersions] : [];
        const dropdownData = res.data.dropdownData;
        const fields = res.data.fields;
        const leaverReplacementFields = res.data.leaverReplacementFields;
        const dataTableData = {
          data,
          dropdownData: dropdownData,
          fields: fields,
          leaverReplacementFields: leaverReplacementFields,
          apiDelete: false,
          hcVersions
        };
        setHeadcountDetails(dataTableData);
        if (returnData) return dataTableData;
      }
    } catch (e) {}
  }

  return {
    getAllGroups,
    getAllHeadCounts,
    getHeadCountDetails,
    getAllApprovedHeadCounts,
    updateTransactionSequence,
    approvedHCS,
    headcountDetails,
    isLoading,
    hasMore,
    pagesAndPermissions
  };
}
