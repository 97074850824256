import React, { useState, useEffect, useRef } from 'react';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { List } from 'devextreme-react/list';
import { dataSourceForHeaderVersion } from './utils';

const Versions = ({
  handleLoadSelectVersionHeader,
  selectedTypeHeaderVersions,
  isResetDropdownVersionValue,
  setIsResetDropdownVersionValue,
  removeVersion
}) => {
  const dropDownRef = useRef(null);
  const [dropDownValue, setDropDownValue] = useState('');

  const closeDropdown = (id) => {
    setDropDownValue(id);
    dropDownRef.current.instance.close();
  };

  const resetDropdownValue = () => {
    if (dropDownRef.current && dropDownRef.current.instance) {
      setDropDownValue('');
      dropDownRef.current.instance.reset();
    }
  };

  useEffect(() => {
    if (isResetDropdownVersionValue) {
      resetDropdownValue();
      setIsResetDropdownVersionValue(false);
    }
  }, [isResetDropdownVersionValue]);

  return (
    <DropDownBox
      value={dropDownValue}
      ref={dropDownRef}
      key={`header-versions`}
      width={200}
      className="__ff__header__dropdown display-inline-block"
      placeholder={'Version'}
      acceptCustomValue={true}
      openOnFieldClick={false}
      valueExpr="id"
      displayExpr="name"
      dataSource={dataSourceForHeaderVersion(selectedTypeHeaderVersions)}
    >
      <List
        selectionMode="single"
        valueExpr="id"
        displayExpr="name"
        dataSource={dataSourceForHeaderVersion(selectedTypeHeaderVersions)}
        allowItemDeleting={true}
        onItemDeleting={(e) => {
          removeVersion(e.itemData.id);
        }}
        onItemClick={(e) => {
          handleLoadSelectVersionHeader(e.itemData.id);
          closeDropdown(e.itemData.id);
        }}
      />
    </DropDownBox>
  );
};

export default Versions;
